import React from "react";
import { TableRow, TableHead, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";

const MaterialTableHead: React.FC = () => {
  const { t } = useTranslation();
  const headStyle = {
    height: "50px",
    fontSize: "18px",
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell sx={headStyle}>{t("Material Number")}</TableCell>
          <TableCell sx={headStyle}>{t("Material Description")}</TableCell>
          <TableCell sx={headStyle} colSpan={2}>
            {t("Quantity")}
          </TableCell>
          <TableCell sx={headStyle}>{t("SortBegr")}</TableCell>
          <TableCell sx={headStyle}>{t("Distribution")}</TableCell>
          <TableCell sx={headStyle} align="center"></TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export default MaterialTableHead;
