import React, { useState } from "react";
import { Box } from "@mui/material";
import { Subtitle } from "../../../../components";
import AddWarehouseForm from "./AddWarehouseForm";
import WarehouseTable from "./WarehouseTable";

const WarehouseDetails: React.FC = () => {
  const [materialAvailableAt, setMaterialAvailableAt] =
    useState<string>("location");
  return (
    <>
      <Box width={"95%"} margin="auto" marginTop={5}>
        <Subtitle value="Add Warehouse Details" />
        <AddWarehouseForm
          setMaterialAvailableAt={setMaterialAvailableAt}
          materialAvailableAt={materialAvailableAt}
        />
      </Box>
      <Box width={"95%"} margin="auto" marginTop={"30px"} marginBottom={"20px"}>
        <WarehouseTable setMaterialAvailableAt={setMaterialAvailableAt} />
      </Box>
    </>
  );
};

export default WarehouseDetails;
