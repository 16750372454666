import React, {useState}from 'react'
import { useTranslation } from 'react-i18next';
import {useAppDispatch, useAppSelector } from '../../../../store';
import { Form, Formik, FormikState } from 'formik';
import * as Yup from "yup";
import { PopOver, Subtitle } from '../../../../components';
import WorkCenterTypeInput from './WorkCenterTypeInput';
import WorkCenterTypeControl from './WorkCenterTypeControl';
import {Box,Typography} from "@mui/material"
import { Type, setDefaultFormState, setUpdateWarehouseId, setValidationError } from 
'../../../../store/workCenterTypeSlice';
import workCenterTypeLogo from 
"../../../../assets/image/WorkCenterType/Screenshot 2023-09-01 171637.svg";
import {
    addWorkCenterTypeAction,
    getWorkCenterTypeAction,
    updateWorkCenterTypeAction,
  } from "../../../../api/workCentertype.service";

const WorkCenterTypeForm:React.FC = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const {validationError, initialValue, workCenterTypeIdForUpdate } = useAppSelector(
        (state) => state.workCenterType
      );
    const dispatch = useAppDispatch();

    const WorkCenterTypeFormSchema = Yup.object({
        name: Yup.string()
          .required("Work Center Type is required")
          .matches(/^[a-zA-Z0-9\s]*$/, "Only letters and numbers are allowed")
          .max(250, "The maximum character limit is 250"),
      });

      const addOrUpdateWorkCenterType = (
        centerType: Type,
        resetForm: (nextState?: Partial<FormikState<Type>> | undefined) => void
      ): void => {
        const afterAction = (): void => {
          setOpen(true);
          dispatch(setDefaultFormState({ name: "" }));
          dispatch(getWorkCenterTypeAction());
          resetForm()
        };
        if (workCenterTypeIdForUpdate.id <= 0) {
          dispatch(addWorkCenterTypeAction({ name: centerType.name })).then(
            (res) => {
              if (res.payload) {
                dispatch(
                  setUpdateWarehouseId({
                    id: 0,
                    message: "Work Center Added Successfully",
                  })
                );
                afterAction();
              }
            }
          );
        } else {
          dispatch(
            updateWorkCenterTypeAction({
              ...centerType,
              id: workCenterTypeIdForUpdate.id,
            })
          ).then((res) => {
            if (res.payload) {
              dispatch(
                setUpdateWarehouseId({
                  id: 0,
                  message: "Work Center Updated Successfully",
                })
              );
              afterAction();
            }
          });
        }
      };

      const closePopOver = (): void => {
        dispatch(setValidationError({ ErrorCode: "", Message: "" }));
        setOpen(false);
      } 

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={WorkCenterTypeFormSchema}
      onSubmit={(values, { resetForm }) => {
        addOrUpdateWorkCenterType(values,resetForm);
      }}
      enableReinitialize
    >
      {() => (
        <Box width={"100%"} margin={"auto"} mt={"29px"}>
          <Subtitle value={"Add Work Center Type"} />
          <Form>
            <Box display={"flex"} width={"100%"}>
              <WorkCenterTypeInput />
              <WorkCenterTypeControl initialId={workCenterTypeIdForUpdate.id} />
            </Box>
            {validationError.ErrorCode && (
              <Typography
                ml={"10px"}
                mt={"2px"}
                variant="body2"
                style={{ color: "red" }}
              >
                {t(validationError.Message)}
              </Typography>
            )}
            <PopOver
              title={workCenterTypeIdForUpdate.message}
              logo={workCenterTypeLogo}
              closePopOver={closePopOver}
              open={open}
              position={true}
            />
          </Form>
        </Box>
      )}
    </Formik>
  )
}

export default WorkCenterTypeForm