import React from "react";
import { Stack, Typography } from "@mui/material";
import { DateLogo, TimeLogo } from "../assets/image/Admin";

interface DateTimeDisplayer {
  date: string;
  time?: string;
}

const DateTimeDisplayer: React.FC<DateTimeDisplayer> = ({ date, time }) => {
  return (
    <Stack direction={"row"} gap={"24px"}>
      <Stack direction={"row"} gap={1}>
        <img src={DateLogo} alt="" />
        <Typography
          variant="h4"
          lineHeight={"normal"}
          fontWeight={"400"}
          fontSize={"18px"}
        >
          {date}
        </Typography>
      </Stack>
      {time && (
        <Stack direction={"row"} gap={1}>
          <img src={TimeLogo} alt="" />
          <Typography
            variant="h4"
            lineHeight={"normal"}
            fontWeight={"400"}
            fontSize={"18px"}
          >
            {time}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default DateTimeDisplayer;
