import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { LabelTag } from "../../components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import KitTrolleyIdInput from "./KitTrolleyIdInput";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import {
  getProductionOrder,
  updateOrderStatus,
} from "../../api/kitPickerPicklist.service";
import { useTranslation } from "react-i18next";
import { setTrolleyBarcode } from "../../store/KitPickerPicklist/kitPickerPicklistSlice";
import ReadOnlyInput from "./ReadOnlyInput";

export type ProductionOrderFormProps = {
  isFetched: boolean;
  setIsFetched: (value: boolean) => void;
  inactiveTime: string;
  startTimer: () => void;
  setIsProcessable: (value: boolean) => void;
};

export type OrderFromState = {
  trolleyBarcode: string;
};

const ProductionOrderForm: React.FC<ProductionOrderFormProps> = ({
  isFetched,
  setIsFetched,
  inactiveTime,
  startTimer,
  setIsProcessable,
}) => {
  const { order, selectedWarehouse, trolleyBarcode, isTrolleyAvailable } =
    useSelector((store: RootState) => store.productionOrder);
  const dispatch = useAppDispatch();
  const initialOrder: OrderFromState = {
    trolleyBarcode: trolleyBarcode,
  };
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    trolleyBarcode: Yup.string()
      .required("Trolley selection is required")
      .matches(/^\d{4}$/, "The Trolley ID should be 4 digits"),
  });

  const handleSubmit = (value: OrderFromState): void => {
    dispatch(setTrolleyBarcode(value.trolleyBarcode));

    dispatch(updateOrderStatus({ orderId: order.productionOrder })).then(
      (res) => {
        if (res.payload) {
          startTimer();
          setIsFetched(true);
        } else {
          setIsProcessable(false);
        }
      }
    );
  };

  const handleSkipOrder = (): void => {
    dispatch(getProductionOrder({ warehouseId: selectedWarehouse.id }));
  };

  return (
    <>
      <Formik
        initialValues={initialOrder}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {() => (
          <Form>
            <Box width="100%">
              <Box
                width="96%"
                margin={"auto"}
                height={"100px"}
                display={"grid"}
                gridTemplateColumns={"repeat(3,1fr)"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap="100px"
              >
                <ReadOnlyInput
                  lable={t("Production Order")}
                  value={order.productionOrder}
                />
                <ReadOnlyInput lable={t("Material")} value={order.material} />
                <ReadOnlyInput
                  lable={t("Material Description")}
                  value={order.materialShortText}
                />
              </Box>
            </Box>
            <Box
              width="96%"
              margin={"auto"}
              marginTop={"10px"}
              display="flex"
              justifyContent={"space-between"}
            >
              <Box display={"flex"} gap={"10px"} alignItems={"end"}>
                <Box>
                  <LabelTag label={t("Trolley ID")} required={true} />
                  <KitTrolleyIdInput
                    name="trolleyBarcode"
                    width="545px"
                    isFetched={isFetched}
                    setIsFetched={setIsFetched}
                  />
                </Box>
                <Box height={"20px"}>
                  <Typography variant="h6" color={"red"}>
                    {`${t("Inactive Time")} : ${inactiveTime}`}
                  </Typography>
                </Box>
              </Box>
              <Box
                width="326px"
                marginTop="30px"
                display="flex"
                justifyContent={"flex-end"}
                gap="44px"
              >
                <Button
                  variant="outlined"
                  onClick={handleSkipOrder}
                  disabled={true}
                >
                  {t("Skip")}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    (!isTrolleyAvailable && !isFetched) ||
                    (isTrolleyAvailable && isFetched)
                  }
                >
                  {t("Fetch")}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProductionOrderForm;
