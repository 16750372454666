import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography, Button } from "@mui/material";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import {
  deliveredTrolleysAtWarehouse,
  getEmptyKitsGroupByKitType,
  pikedByMilkRunTrolley,
  undoPikedByMilkRunEmptyKitTrolley,
} from "../../api/kitStatus.service";
import KitLogo from "../../assets/image/KitStatus/Group 9002.svg";
import { TrolleysType } from "../../store/kitStatusSlice";
import { useTranslation } from "react-i18next";
import KitCardList from "./KitCardList";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import { speakText } from "../../utils/speakText";
import i18n from "i18next";
import { enqueueSnackbar } from "notistack";
import { Loader, PopOver } from "../../components";

const EmptyKit: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enterKey, barcode, handleResetEnterKey } = useBarcodeScanner();
  const currentLanguage = i18n.language;
  const [initialLoading, setInitialLoading] = useState(true);

  const checkTrolleyByClick = (
    barCode: string,
    rework: boolean,
    status: string
  ): void => {
    if (status === "emptied") {
      dispatch(pikedByMilkRunTrolley({ barCode, rework })).then(
        (res) => res.payload && dispatch(getEmptyKitsGroupByKitType())
      );
    } else if (status === "picked") {
      dispatch(undoPikedByMilkRunEmptyKitTrolley(barCode)).then(
        (res) => res.payload && dispatch(getEmptyKitsGroupByKitType())
      );
    }
  };

  const { emptyKits } = useSelector((state: RootState) => state.kitStatus);

  const groupedData = emptyKits.reduce((map, item) => {
    const warehouse = item.warehouse;
    if (!map.has(warehouse)) {
      map.set(warehouse, []);
    }
    map.get(warehouse)?.push(item);
    return map;
  }, new Map<string, TrolleysType[]>());

  const groupedDataObject: Record<string, TrolleysType[]> =
    Object.fromEntries(groupedData);

  const groupedDataArray = Object.entries(groupedDataObject)
    .map(([key, val]) => ({
      warehouse: key,
      trolleyList: val,
    }))
    .sort((a, b) => a.warehouse.localeCompare(b.warehouse));

  const scanBarcodeAssemblyUser = useCallback(() => {
    if (enterKey) {
      handleResetEnterKey();
      let notFoundMessage = true;

      groupedDataArray?.map((order) =>
        order?.trolleyList?.map(({ trolleyBarcode, needRework }) => {
          {
            if (trolleyBarcode === barcode) {
              const section = document.getElementById(trolleyBarcode);
              if (section) {
                section.scrollIntoView({ behavior: "smooth", block: "center" });
              }
              dispatch(
                pikedByMilkRunTrolley({
                  barCode: trolleyBarcode,
                  rework: needRework,
                })
              ).then(
                (res) => res.payload && dispatch(getEmptyKitsGroupByKitType())
              );

              notFoundMessage = false;
            }
          }
        })
      );

      if (notFoundMessage) {
        speakText(
          `${t("Unable to find the trolley")} ${barcode}`,
          currentLanguage
        );
        enqueueSnackbar(`${t("Unable to find the trolley")} ${barcode}`);
      }
    }
  }, [
    enterKey,
    barcode,
    groupedDataArray,
    currentLanguage,
    t,
    dispatch,
    handleResetEnterKey,
  ]);

  useEffect(() => {
    scanBarcodeAssemblyUser();
  }, [scanBarcodeAssemblyUser]);

  useEffect(() => {
    dispatch(getEmptyKitsGroupByKitType()).then(() => {
      setInitialLoading(false);
    });

    const refreshInterval = setInterval(() => {
      dispatch(getEmptyKitsGroupByKitType());
    }, 60 * 1000);
    return () => clearInterval(refreshInterval);
  }, [dispatch]);

  const confirmDelivery = (warehouse: string): void => {
    const filteredData = emptyKits.filter(
      (item) => item.trolleyStatus === "picked" && item.warehouse === warehouse
    );
    const deliverTrolleys = filteredData.map((item) => item.trolleyBarcode);

    dispatch(deliveredTrolleysAtWarehouse(deliverTrolleys)).then((res) => {
      if (res.payload) {
        setOpen(true);
        dispatch(getEmptyKitsGroupByKitType());
      }
    });
  };

  return (
    <>
      <Box mt={"32px"} sx={{ p: 3 }}>
        {initialLoading && <Loader />}
        {!initialLoading && groupedDataArray.length !== 0 && (
          <Typography variant="h5">{t("Delivery Location Name")}</Typography>
        )}

        <Box mt={"24px"}>
          {!initialLoading && groupedDataArray.length === 0 && (
            <Typography variant="h5" textAlign="center">
              {t("There are no trolleys available for delivery.")}
            </Typography>
          )}{" "}
          {!initialLoading &&
            groupedDataArray.length !== 0 &&
            groupedDataArray.map(({ warehouse, trolleyList }) => {
              const allItemsPicked: boolean = trolleyList.every(
                (item) => item.trolleyStatus !== "picked"
              );
              return (
                <Box key={warehouse}>
                  <Box>
                    <Typography>{warehouse}</Typography>
                    <Box display={"flex"}>
                      <KitCardList
                        items={trolleyList}
                        assemblyArea={warehouse}
                        checkTrolleyByClick={checkTrolleyByClick}
                      />
                      <Box
                        width={"15%"}
                        m={"auto"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        height={"48px"}
                        textAlign={"center"}
                        p={"0px 10px 0px 10px"}
                      >
                        <Box width={"70%"} m={"auto"}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => confirmDelivery(warehouse)}
                            disabled={allItemsPicked}
                          >
                            {t("Confirm Delivery")}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>

      <PopOver
        title={"Kit has been delivered successfully!"}
        logo={KitLogo}
        closePopOver={() => setOpen(false)}
        open={open}
        position={false}
      />
    </>
  );
};

export default EmptyKit;
