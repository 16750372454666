import { AssignRole, KittingProcess, UserFormAdmin } from "../../containers";
import { RouteType } from ".";

export const AdminRoutes: RouteType[] = [
  {
    path: "/",
    name: "Kitting Process",
    activeIndex: 0,
    Component: KittingProcess,
  },
  {
    path: "/assign-role",
    name: "Assign Role",
    activeIndex: 1,
    Component: AssignRole,
  },
  {
    path: "/create-user",
    hide: true,
    name: "Create Admin",
    activeIndex: 1,
    Component: UserFormAdmin,
  },
];
