import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getError,
  getLoading,
  successOrderPool,
} from "../store/orderPoolSlice";
import axiosInstance from "./base.service";

interface OrderPoolGetRequestTypes {
  filterAssemblyArea: string;
  searchOrder: string;
  filterStatus: string;
}

export const orderPoolGetRequest = createAsyncThunk(
  "orderPool/getOrderList",
  async (props: OrderPoolGetRequestTypes, { dispatch }) => {
    const { filterAssemblyArea, searchOrder, filterStatus } = props;
    dispatch(getLoading());
    try {
      const res = await axiosInstance.get(
        `api/Orders/GetAll?productionOrder=${searchOrder}&assemblyId=${filterAssemblyArea}&status=${filterStatus}` //eslint-disable-line
      );
      const data = res.data;
      dispatch(successOrderPool(data));
    } catch (error) {
      dispatch(getError());
    }
  }
);
