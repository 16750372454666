import React, { useEffect, useState } from "react";
import { Box, Table, Typography } from "@mui/material";
import { Confirmation, Loader } from "../../../../components";
import { useTranslation } from "react-i18next";
import {
  deleteCenterType,
  getWorkCenterTypeAction,
} from "../../../../api/workCentertype.service";
import { useAppDispatch, useAppSelector } from "../../../../store";
import WorkCenterTypeTableRow from "./WorkCenterTypeTableRow";
import WorkCenterTypeTableHead from "./WorkCenterTypesTableHead";
import { useConfirmDelete } from "../../../../hooks";
import {
  WorkCenterInitialType,
  setDefaultFormState,
  setUpdateWarehouseId,
  setValidationError,
} from "../../../../store/workCenterTypeSlice";
import WorkCenterTypeForm from "./WorkCenterTypeForm";

const WorkCenterType: React.FC = () => {
  const { t } = useTranslation();
  const [initialLoading, setInitialLoading] = useState(true);
  const [centerTypeName, setCenterTypeName] = useState("");

  const { centerTypes, workCenterTypeIdForUpdate } = useAppSelector(
    (state) => state.workCenterType
  );
  const { isModalOpen, selectedUser, openModal, closeModal } =
    useConfirmDelete();

  const dispatch = useAppDispatch();

  const deleteType = (id: number, centerType: WorkCenterInitialType): void => {
    dispatch(
      setDefaultFormState({
        name: "",
      })
    );
    dispatch(setValidationError({ ErrorCode: "", Message: "" }));
    setCenterTypeName(centerType.name);
    dispatch(setUpdateWarehouseId({ id: id, message: "" }));
    openModal(id);
  };

  const confirmDeleteUser = (): void => {
    if (selectedUser) {
      dispatch(deleteCenterType(selectedUser)).then((res) => {
        const result = res.payload;
        result
          ? dispatch(getWorkCenterTypeAction())
          : window.scrollTo({ top: 0, behavior: "smooth" });
      });
    }
    closeModal();
    dispatch(setUpdateWarehouseId({ id: 0, message: "" }));
  };

  const updateType = (centerType: WorkCenterInitialType): void => {
    dispatch(
      setDefaultFormState({
        name: centerType.name,
      })
    );
    dispatch(setValidationError({ ErrorCode: "", Message: "" }));
    dispatch(setUpdateWarehouseId({ id: centerType.id, message: "" }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    dispatch(setValidationError({ ErrorCode: "", Message: "" }));
    dispatch(getWorkCenterTypeAction()).then(() => {
      setInitialLoading(false);
    });
    setTimeout(() => {
      dispatch(setValidationError({ ErrorCode: "", Message: "" }));
    }, 15 * 1000);
  }, [dispatch]);

  return (
    <Box m={5}>
      <WorkCenterTypeForm />
      {initialLoading && <Loader />}
      <Box mt={"50px"}>
        {!initialLoading && centerTypes?.length === 0 && (
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            mt="30px"
          >
            <Typography variant="h5">{t("No Data Available")}</Typography>
          </Box>
        )}{" "}
        {!initialLoading && centerTypes?.length !== 0 && (
          <Table>
            <WorkCenterTypeTableHead />
            {centerTypes.map((center) => {
              return (
                <WorkCenterTypeTableRow
                  key={center.id}
                  id={center.id}
                  initialId={workCenterTypeIdForUpdate.id}
                  centerType={center}
                  deleteType={deleteType}
                  updateType={updateType}
                />
              );
            })}
          </Table>
        )}
      </Box>
      <Confirmation
        title={t("Confirm Delete")}
        message={`${t(
          "Are you sure you want to delete the work center"
        )} '${centerTypeName}'?`}
        open={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmDeleteUser}
      />
    </Box>
  );
};

export default WorkCenterType;
