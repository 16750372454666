import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box, Button, Typography } from "@mui/material";
import { EmailInput, InputTag, LabelTag } from "../../../components";
import { useTranslation } from "react-i18next";
import { UserFormInitialStateAdmin } from "../../../containers/Admin/UserFormAdmin";
import RoleSelectTag from "./RoleSelectTag";
import { UserFormInitialStateSuperAdmin } from "../../../containers/SuperAdmin/UserFormSuperAdmin";
import { ValidationError } from "../../../store/warehouseSlice";

export type UserManagementFormProps = {
  initialState: {
    name: string;
    email: string;
    userRole?: string;
    language?: string;
  };
  onSubmit: (values: {
    name: string;
    email: string;
    userRole?: string;
    language?: string;
  }) => void;
  onCancel: () => void;
  showSelectField: boolean;
  validationSchema: Yup.ObjectSchema<
    UserFormInitialStateAdmin | UserFormInitialStateSuperAdmin
  >;
  userValidations: ValidationError;
};

const userRoleOptions = [
  { display: "Super Admin", value: "Super Admin" },
  { display: "Admin", value: "Admin" },
  { display: "Kit Picker", value: "Kit Picker" },
  { display: "Milk Run", value: "Milk Run" },
  { display: "Assembly User", value: "Assembly User" },
];

// const languageOptions = [
//   { display: "English", value: "en-US" },
//   { display: "German", value: "de-DE" },
// ];
const UserManagementForm: React.FC<UserManagementFormProps> = ({
  initialState,
  onSubmit,
  onCancel,
  showSelectField,
  validationSchema,
  userValidations,
}) => {
  const { t } = useTranslation();
  const { ErrorCode, Message } = userValidations;

  return (
    <>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {() => (
          <Form>
            <Box
              display={"flex"}
              columnGap={"87px"}
              rowGap={"20px"}
              flexWrap={"wrap"}
            >
              <Box>
                <LabelTag label="Name" required={true} />
                <InputTag name="name" type="text" width="516px" />
              </Box>
              <Box>
                <LabelTag label="Email Id" required={true} />
                <EmailInput name="email" type="email" width="516px" />
              </Box>
              {showSelectField && (
                <Box width="516px">
                  <LabelTag label="Role" required={true} />
                  <RoleSelectTag name="userRole" options={userRoleOptions} />
                </Box>
              )}
              {/* {showSelectField && (
                <Box width="516px">
                  <LabelTag label="Language" required={true} />
                  <RoleSelectTag name="language" options={languageOptions} />
                </Box>
              )} */}
            </Box>
            <Box
              mt="73px"
              display={"flex"}
              justifyContent={ErrorCode ? "space-between" : "flex-end"}
            >
              {ErrorCode && (
                <Box display="flex" alignItems={"center"}>
                  <Typography color="red">{t(Message)}</Typography>
                </Box>
              )}
              <Box display={"flex"} gap={"24px"} justifyContent={"flex-end"}>
                <Button type="button" variant="outlined" onClick={onCancel}>
                  {t("Cancel")}
                </Button>
                <Button type="submit" variant="contained">
                  {t("Save")}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserManagementForm;
