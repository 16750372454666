import React, { memo } from "react";
import { Box } from "@mui/material";
import { TrolleysType } from "../../store/kitStatusSlice";
import KitCard from "./KitCard";
interface KitCardProps {
  items: TrolleysType[];
  assemblyArea: string;
  checkTrolleyByClick: (
    barCode: string,
    rework: boolean,
    status: string
  ) => void;
}

const KitCardList: React.FC<KitCardProps> = ({
  items,
  assemblyArea,
  checkTrolleyByClick,
}) => {
  return (
    <Box
      width={"85%"}
      mt="12px"
      display={"grid"}
      gap={"17px"}
      gridTemplateColumns={"repeat(10,1fr)"}
    >
      {items.map((item) => (
        <KitCard
          key={item.trolleyBarcode}
          assemblyArea={assemblyArea}
          trolleyBarcode={item.trolleyBarcode}
          trolleyStatus={item.trolleyStatus}
          rework={item.needRework}
          checkTrolleyByClick={checkTrolleyByClick}
        />
      ))}
    </Box>
  );
};

export default memo(KitCardList);
