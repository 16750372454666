import {
  Field,
  FormikErrors,
  FormikTouched,
  useField,
  useFormikContext,
} from "formik";
import React from "react";
import { MenuItem, Select, Typography } from "@mui/material";
import { useAppSelector } from "../../../../store";
import { AssemblyAreaType } from "../../../../store/AssemblyAreaSlice";
import { useTranslation } from "react-i18next";
interface AssemblyAreaSelectTagProps {
  touched: FormikTouched<AssemblyAreaType>;
  errors: FormikErrors<AssemblyAreaType>;
}
const AssemblyAreaSelectTag: React.FC<AssemblyAreaSelectTagProps> = ({
  touched,
  errors,
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField("workCenterType");
  const { value } = meta;
  const { centerTypes } = useAppSelector((state) => state.workCenterType);
  const memoizedCenterTypes = React.useMemo(() => centerTypes, [centerTypes]);
  const handleWarehouseSelect = async (
    selectedValue: string
  ): Promise<void> => {
    const selectedWarehouse = memoizedCenterTypes.find(
      (type) => type.name === selectedValue
    );
    if (selectedWarehouse) {
      await setFieldValue("workCenterTypeId", selectedWarehouse.id);
    }
  };
  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    await handleWarehouseSelect(e.target.value);
    await setFieldValue("workCenterType", e.target.value);
  };

  const isError =
    touched["workCenterType" as keyof typeof touched] &&
    !!errors["workCenterType" as keyof typeof errors];
    
  return (
    <>
      <Field
        {...field}
        as={Select}
        sx={{
          width: window.innerWidth <= 1500 ? 330 : 446,
        }}
        onChange={handleChange}
        name="workCenterType"
        value={value}
        error={isError}
      >
        <MenuItem value="">
          {memoizedCenterTypes.length === 0 ? (
            <Typography>{t("Work center types are not available.")}</Typography>
          ) : (
            <></>
          )}
        </MenuItem>
        {memoizedCenterTypes.map((type) => (
          <MenuItem key={type.id} value={type.name}>
            {t(type.name)}
          </MenuItem>
        ))}
      </Field>
      {touched.workCenterType && errors.workCenterType && (
        <Typography color="error" variant="body2">
          {t(errors.workCenterType)}
        </Typography>
      )}
    </>
  );
};

export default AssemblyAreaSelectTag;
