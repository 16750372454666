import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import plantDetailsReducer from "./plantDetailsSlice";
import userListReducer from "./userListSlice";
import kitStatusReducer from "./kitStatusSlice";
import reportTrolley from "./reportTrolley";
import kitPickerPicklistReducer from "./KitPickerPicklist/kitPickerPicklistSlice";
import orderPoolReducer from "./orderPoolSlice";
import workCenterTypeReducer from "./workCenterTypeSlice";
import trolleyStatusReducer from "./trolleyStatusSlice";
import warehouseReducer from "./warehouseSlice";
import assemblyAreaReducer from "./AssemblyAreaSlice"
import inputDirectoryReducer from "./inputDirectorySlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    plantDetails: plantDetailsReducer,
    userList: userListReducer,
    kitStatus: kitStatusReducer,
    reportTrolley: reportTrolley,
    productionOrder: kitPickerPicklistReducer,
    orderPool: orderPoolReducer,
    workCenterType: workCenterTypeReducer,
    trolleyStatus: trolleyStatusReducer,
    warehouseDetails: warehouseReducer,
    assemblyAreas:assemblyAreaReducer,
    inputDirectory:inputDirectoryReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
