import React from "react";
import { useAppSelector } from "../store";
import { Route, Routes, useLocation } from "react-router";

import {
  AdminRoutes,
  AssemblyUserRoute,
  KitPickerRoutes,
  MilkRunRoutes,
  RouteType,
  SuperAdminRoutes,
} from "./RouteList";
import { NotFoundPage, NotAccessUser } from "../containers";

const AccessWiseRoutes: React.FC = () => {
  const { userDetails } = useAppSelector((store) => store.user);
  const access = userDetails.userRole;
  const location = useLocation();

  const routes: RouteType[] =
    access === "Super Admin"
      ? SuperAdminRoutes
      : access === "Admin"
      ? AdminRoutes
      : access === "Kit Picker"
      ? KitPickerRoutes
      : access === "Milk Run"
      ? MilkRunRoutes
      : access === "Assembly User"
      ? AssemblyUserRoute
      : [];

  if (routes.length === 0) {
    return <NotAccessUser />;
  }

  return (
    <>
      <Routes location={location}>
        {routes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default AccessWiseRoutes;
