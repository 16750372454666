import {
  AdminList,
  Login,
  PlantDetails,
  UserFormSuperAdmin,
} from "../../containers";
import { RouteType } from ".";

export const SuperAdminRoutes: RouteType[] = [
  // {
  //   path: "/",
  //   name: "Plant Details",
  //   activeIndex: 0,
  //   Component: PlantDetails,
  // },
  // {
  //   path: "/user-list",
  //   name: "Assign Admin",
  //   activeIndex: 1,
  //   Component: AdminList,
  // },
  // {
  //   path: "/create-user",
  //   hide: true,
  //   name: "Create Admin",
  //   activeIndex: 1,
  //   Component: UserFormSuperAdmin,
  // },
  {
    path: "/",
    hide: true,
    name: "option screen",
    activeIndex: 1,
    Component: Login,
  },
];
