import React from "react";
import { TextField, Box } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

type InputTagProps = {
  name: string;
  width?: string;
  type?: string;
  placeholder?: string;
};

const InputTag: React.FC<InputTagProps> = (props) => {
  const { name, width, type, placeholder } = props;
  const { errors, setFieldValue, touched } = useFormikContext();
  const [field, meta] = useField(name);
  const { value } = meta;
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(name, e.target.value);
  };

  const isError =
    touched[name as keyof typeof touched] &&
    !!errors[name as keyof typeof errors];

  return (
    <Box width={width}>
      <TextField
        {...field}
        value={value}
        placeholder={placeholder}
        type={type || "text"}
        onChange={handleChange}
        variant="outlined"
        size="small"
        fullWidth
        error={isError}
        helperText={isError ? t(errors[name as keyof typeof errors]) : ""}
      />
    </Box>
  );
};

export default InputTag;
