import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store";
import { User } from "../../../store/userListSlice";
import { useConfirmDelete } from "../../../hooks";
import { deleteUser, getUserList, updateUser } from "../../../api/user.service";
import {
  Box,
  Table,
  CircularProgress,
  TableBody,
  Typography,
  TableContainer,
} from "@mui/material";
import Confirmation from "../../Confirmation";
import UserTableHead from "./UserTableHead";
import UserTableRow from "./UserTableRow";
import { ValidationError } from "../../../store/warehouseSlice";

interface UserTableProps {
  userList: User[];
  initialLoading: boolean;
  showSrNumbers: boolean;
  showRoles: boolean;
  userValidations: ValidationError;
}
const UserTable: React.FC<UserTableProps> = ({
  userList,
  initialLoading,
  showSrNumbers,
  showRoles,
  userValidations,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [userName, setUserName] = useState("");
  const { isModalOpen, selectedUser, openModal, closeModal } =
    useConfirmDelete();
  const { ErrorCode, Message } = userValidations;
  ErrorCode &&
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  const toggleUserStatus = (
    userId: number,
    isActive: boolean,
    name: string,
    email: string,
    userRole: string,
    language: string
  ): void => {
    dispatch(
      updateUser({ id: userId, isActive, name, email, userRole, language })
    ).then((res) => {
      res.payload && dispatch(getUserList());
    });
  };

  const handleDeleteUser = (id: number, name: string): void => {
    setUserName(name);
    openModal(id);
  };

  const confirmDeleteUser = (): void => {
    if (selectedUser) {
      dispatch(deleteUser(selectedUser)).then((res) => {
        res.payload && dispatch(getUserList());
      });
    }
    closeModal();
  };

  return (
    <>
      {initialLoading && (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          mt="30px"
        >
          <CircularProgress />
        </Box>
      )}
      {ErrorCode && (
        <Box display="flex" alignItems={"center"} mb="20px">
          <Typography color="red">{t(Message)}</Typography>
        </Box>
      )}
      {!initialLoading && userList?.length !== 0 && (
        <TableContainer>
          <Table stickyHeader>
            <UserTableHead
              showSrNumbers={showSrNumbers}
              showRoles={showRoles}
            />
            <TableBody>
              {userList?.map((user, index) => {
                return (
                  <UserTableRow
                    key={user.id}
                    id={user.id}
                    name={user.name}
                    email={user.email}
                    userRole={user.userRole}
                    isActive={user.isActive}
                    language={user.language}
                    showSrNumbers={showSrNumbers}
                    showRoles={showRoles}
                    index={index}
                    toggleUserStatus={toggleUserStatus}
                    handleDeleteUser={handleDeleteUser}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!initialLoading && userList?.length === 0 && (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          mt="30px"
        >
          <Typography variant="h5">{t("No Data Available")}</Typography>
        </Box>
      )}
      <Confirmation
        title={t("Confirm Delete")}
        message={`${t(
          "Are you sure you want to delete this user"
        )} '${userName}' ?`}
        open={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmDeleteUser}
      />
    </>
  );
};

export default UserTable;
