import React from "react";
import { Box, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store";
import { setLoginDetails } from "../store/userSlice";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector((store) => store.user);

  const loginUser = (role: string): void => {
    dispatch(setLoginDetails({ ...userDetails, userRole: role }));
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"30%"}
      m={"auto"}
      gap={3}
      justifyContent={"center"}
      height={"100vh"}
    >
      <Button variant="contained" onClick={() => loginUser("Admin")}>
        Admin
      </Button>
      <Button variant="contained" onClick={() => loginUser("Kit Picker")}>
        Kit Picker PickList
      </Button>
      <Button variant="contained" onClick={() => loginUser("Milk Run")}>
        Milk Run
      </Button>
      <Button variant="contained" onClick={() => loginUser("Assembly User")}>
        Assembly User
      </Button>
    </Box>
  );
};

export default Login;
