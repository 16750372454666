import React from "react";
import { Stack, Typography } from "@mui/material";
import warningLogo from "../../assets/image/AssemblyUser/KitCardLogo.svg";
import { BsCheckCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

interface EmptyTrolleyCardPropsType {
  trolleyBarcode: string;
  status: string;
  productionOrder: string;
  onUpdateTrolley: (
    trolleyBarcode: string,
    status: string,
    productionOrder: string
  ) => void;
}

const EmptyTrolleyCard: React.FC<EmptyTrolleyCardPropsType> = ({
  trolleyBarcode,
  status,
  onUpdateTrolley,
  productionOrder,
}) => {
  const { t } = useTranslation();

  const isEmptied = status === "emptied";

  return (
    <>
      <Stack
        bgcolor="#EFF9FD"
        height="107px"
        padding="9px"
        borderRadius="4px"
        flexShrink={0}
        border={isEmptied ? "3px solid #0078A1" : ""}
        onClick={() => onUpdateTrolley(trolleyBarcode, status, productionOrder)}
        onTouchStart={() =>
          onUpdateTrolley(trolleyBarcode, status, productionOrder)
        }
        className={"custom-cursor-element"}
        id={trolleyBarcode}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          height="100%"
          gap={"10px"}
        >
          <Stack direction="column">
            <Typography variant="h5" className={"custom-cursor-element"}>
              {t("Trolley ID")}
            </Typography>
            <Typography variant="caption" fontSize="18px" mt={"2px"}>
              {trolleyBarcode}
            </Typography>
          </Stack>
          <Stack
            justifyContent="space-between"
            alignItems="flex-end"
            height="100%"
            mt="4px"
          >
            <img src={warningLogo} alt="" width="21px" height="18px" />
            {isEmptied && <BsCheckCircleFill color="#43B02A" size="30px" />}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default EmptyTrolleyCard;
