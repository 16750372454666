import React, { useState, useEffect } from "react";
import { Box, Table } from "@mui/material";
import TrolleyStatusTableHead from "./TrolleyStatusTableHead";
import { useAppDispatch } from "../../../../store";
import { trolleyListGetRequest } from "../../../../api/trolleyStatus.service";
import TrolleyStatusTableBody from "./TrolleyStatusTableBody";
import { Loader } from "../../../../components";

export const firstColwidth = "15%";
export const secondColWidth = "26%";
export const thirdColwidth = "22%";
export const fourthColWidth = "22%";
export const fifthColWidth = "15%";

const TrolleyStatus: React.FC = () => {
  const [filterStatus, setFilterStatus] = useState("all");
  const [searchOrder, setSearchOrder] = useState("");
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);

  const handleOrderSearch = (searchValue: string): void => {
    setSearchOrder(searchValue);
  };

  useEffect(() => {
    dispatch(
      trolleyListGetRequest({
        filterStatus,
        searchOrder,
      })
    ).then(() => {
      setInitialLoading(false);
    });
  }, [dispatch, filterStatus, searchOrder]);

  return (
    <>
      {initialLoading && <Loader />}
      {!initialLoading && (
        <Box m={5}>
          <Table>
            <TrolleyStatusTableHead
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
              onOrderSearch={handleOrderSearch}
            />
            <TrolleyStatusTableBody />
          </Table>
        </Box>
      )}
    </>
  );
};

export default TrolleyStatus;
