import { ReportTrolley } from "../../containers";
import { RouteType } from ".";

export const AssemblyUserRoute: RouteType[] = [
  {
    path: "/",
    name: "Report Trolley",
    activeIndex: 0,
    Component: ReportTrolley,
  },
];
