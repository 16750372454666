import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import { BsCheckCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
interface KitCardProps {
  assemblyArea: string;
  trolleyBarcode: string;
  trolleyStatus: string;
  rework: boolean;
  checkTrolleyByClick: (
    barCode: string,
    rework: boolean,
    status: string
  ) => void;
}

const KitCard: React.FC<KitCardProps> = ({
  trolleyBarcode,
  trolleyStatus,
  rework,
  checkTrolleyByClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      bgcolor={"#EFF9FD"}
      p={"9px 0px 0px 15px"}
      mb={"12px"}
      onClick={() => checkTrolleyByClick(trolleyBarcode, rework, trolleyStatus)}
      id={trolleyBarcode}
    >
      <Typography variant="h5">{t("Trolley ID")}</Typography>
      <Typography fontSize={"18px"} color={"#0D0D0D"} variant="h1">
        {trolleyBarcode}
      </Typography>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        p={"6.06px 6.06px 6.06px 0px"}
      >
        <BsCheckCircleFill
          color={
            trolleyStatus === "filled" ||
            trolleyStatus === "emptied" ||
            trolleyStatus === "rework"
              ? "#BBBDC0"
              : "#43B02A"
          }
          size={"33px"}
        />
      </Box>
    </Box>
  );
};

export default memo(KitCard);
