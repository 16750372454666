import React from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
interface AssemblyAreaControlsProps {
  clearCredentials: () => void;
  editId:number;
  innerWidth:number
}
const AssemblyAreaControls: React.FC<AssemblyAreaControlsProps> = ({
  clearCredentials,
  editId,
}) => {
  const { t } = useTranslation();
  return (
    <Box width={"50%"} display={"flex"} justifyContent={"flex-end"}>
      <Box
        display="flex" justifyContent={"space-between"} gap="44px"
      >
        <Button variant="outlined" type={"reset"} onClick={clearCredentials}>
          {t("Cancel")}
        </Button>
        <Button variant="contained" type={"submit"}>
          {editId <= 0 ? t("Add") : t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default AssemblyAreaControls;
