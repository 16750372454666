import { createSlice } from "@reduxjs/toolkit";

export interface OrderListType {
  id: number;
  assemblyLine: {
    id: number;
    assemblyLineName: string;
  };
  productionOrder: string;
  cornerStart: string;
  startUzt: string;
  status: string;
}

interface InitialStateType {
  loading: boolean;
  error: boolean;
  orderList: OrderListType[];
}

const initialState: InitialStateType = {
  loading: false,
  error: false,
  orderList: [],
};

export const orderPoolSlice = createSlice({
  name: "orderPool",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getError: (state) => {
      state.loading = false;
      state.error = true;
    },
    successOrderPool: (state, action) => {
      state.loading = false;
      state.orderList = action.payload;
    },
  },
});

export const { getLoading, getError, successOrderPool } =
  orderPoolSlice.actions;

export default orderPoolSlice.reducer;
