import * as Yup from "yup";

type YourObjectLocationType = {
  name: string;
  partPickLocationMin: string;
  partPickLocationMax: string;
};

type YourObjectArbPlatzType = {
  name: string;
  artPlatz: string;
};

const customAlphanumericCompare = (min: string, max: string): boolean => {
  return min.toLowerCase() < max.toLowerCase();
};

export const validationSchemaForLocation = (  
): Yup.ObjectSchema<YourObjectLocationType> => {
  return Yup.object().shape({
    name: Yup.string()
      .required("Warehouse Name is required")
      .matches(
        /^\s*([a-zA-Z0-9]+\s*)+\s*$/,
        "Only alphanumeric characters and spaces are allowed"
      ),
    partPickLocationMin: Yup.string()
      .required("Min Location is required")
      .matches(
        /^[a-zA-Z0-9-]+$/,
        "Only alphanumeric characters and '-' are allowed"
      )
      .test(
        "minNotGreaterThanMax",
        "Min value cannot be greater than Max value",
        function (value) {
          const { partPickLocationMax } = this.parent;
          if (value && partPickLocationMax) {
            return customAlphanumericCompare(value, partPickLocationMax);
          }
          return true;
        }
      ),
    partPickLocationMax: Yup.string()
      .required("Max Location is required")
      .matches(
        /^[a-zA-Z0-9-]+$/,
        "Only alphanumeric characters and '-' are allowed"
      ),
  });
};

export const validationSchemaForArtPlatz = (  
): Yup.ObjectSchema<YourObjectArbPlatzType> => {
  return Yup.object().shape({
    name: Yup.string()
      .required("Warehouse Name is required")
      .matches(
        /^\s*([a-zA-Z0-9]+\s*)+\s*$/,
        "Only alphanumeric characters and spaces are allowed"
      ),
    artPlatz: Yup.string()
      .required("ArbPlatz is required")
      .matches(/^[a-zA-Z0-9]+$/, "Only alphanumeric characters are allowed"),
  });
};
