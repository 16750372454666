import { createSlice } from "@reduxjs/toolkit";

export type userDetails = {
  name: string;
  userRole: string;
  email: string;
};

export type UserState = Readonly<{
  isLoading: boolean;
  isError: string;
  user: boolean;
  login: boolean;
  accessToken: string;
  accessTokenExpiration: number | null;
  userDetails: userDetails;
}>;

const initialState: UserState = {
  isLoading: false,
  isError: "",
  login: false,
  user: false,
  accessToken: "",
  accessTokenExpiration: null,
  userDetails: {
    name: "",
    userRole: "",
    email: "",
  },
};

export const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    userLoading: (state) => ({ ...state, isLoading: true }),
    userError: (state, { payload }) => ({
      ...state,
      isLoading: false,
      isError: payload,
    }),
    setUserAccessToken: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    setLoginDetails: (state, { payload }) => ({
      ...state,
      login: true,
      userDetails: payload,
    }),
    setUser: (state, { payload }) => ({
      ...state,
      user: payload,
    }),
    userLogout: () => ({
      isLoading: false,
      isError: "",
      login: false,
      user: false,
      accessToken: "",
      accessTokenExpiration: null,
      userDetails: { name: "", userRole: "", email: "" },
    }),
  },
});

export const {
  userLoading,
  userLogout,
  userError,
  setLoginDetails,
  setUserAccessToken,
  setUser,
} = userSlice.actions;

export default userSlice.reducer;
