import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setComponents,
  setError,
  setIsAllOrderCompleted,
  setIsTrolleyAvailable,
  setLoading,
  setNeedRefresh,
  setOrder,
  setWarehouses,
} from "../store/KitPickerPicklist/kitPickerPicklistSlice";
import axiosInstance from "./base.service";
import { Component } from "../store/KitPickerPicklist/kitPickerPicklistModel";

const order = {
  id: 0,
  productionOrder: "",
  material: "",
  materialShortText: "",
  assemblyLineId: 0,
};

export const getAllWarehouses = createAsyncThunk(
  "kitPickerPicklist/getAllWarehouses",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axiosInstance.get("api/warehouses");
      const data = res.data;
      dispatch(setLoading(false));
      if (res.status === 200) {
        dispatch(setWarehouses(data));
        return true;
      }
      return false;
    } catch (error) {
      dispatch(setError(true));
      return false;
    }
  }
);

export const getProductionOrder = createAsyncThunk(
  "kitPickerPicklist/getProductionOrder",
  async ({ warehouseId }: { warehouseId: string | number }, { dispatch }) => {
    try {
      const res = await axiosInstance.get(
        `api/orders/getNextPendingOrder?warehouseid=${warehouseId}`
      );
      const data = res.data;
      dispatch(setIsTrolleyAvailable(false));
      if (res.status === 200 && res.data.head !== null) {
        res.data.ruleViolation?.code === 422
          ? dispatch(setNeedRefresh(true))
          : dispatch(setNeedRefresh(false));
        dispatch(setOrder(data.head));
        dispatch(setComponents(data.components));
        dispatch(
          setIsAllOrderCompleted({
            status: false,
            message: null,
          })
        );
        return true;
      } else if (res.status === 200 && res.data.head === null) {
        dispatch(
          setIsAllOrderCompleted({
            status: true,
            message: data.ruleViolation ? data.ruleViolation.message : null,
          })
        );
        dispatch(setOrder(order));
        dispatch(setNeedRefresh(false));
        dispatch(setComponents([]));
        return true;
      }
      dispatch(setOrder(order));
      dispatch(setComponents([]));
      return false;
    } catch (error) {
      dispatch(setError(true));
      return false;
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  "kitPickerPicklist/updateOrderStatus",
  async ({ orderId }: { orderId: string }, { dispatch }) => {
    try {
      const res = await axiosInstance.put(`api/orders/${orderId}/WIP`);
      return res.status === 200;
    } catch (error) {
      dispatch(setError(true));
      return false;
    }
  }
);

export const sendToMilkrun = createAsyncThunk(
  "kitPickerPicklist/sendToMilkrun",
  async (
    {
      trolleyBarcode,
      assemblyLineId,
      pickedComponents,
    }: {
      trolleyBarcode: string;
      assemblyLineId: number;
      pickedComponents: Component[];
    },
    { dispatch }
  ) => {
    try {
      const res = await axiosInstance.put(
        `api/trollies/${trolleyBarcode}/${assemblyLineId}/sendToMilkRun`,
        pickedComponents
      );
      return res.status === 200;
    } catch (error) {
      dispatch(setError(true));
      return error;
    }
  }
);

export const checkIsTrolleyAvailable = createAsyncThunk(
  "kitPickerPicklist/checkIsTrolleyAvailable",
  async ({ trolleyBarcode }: { trolleyBarcode: string }, { dispatch }) => {
    try {
      const res = await axiosInstance.get(
        `api/Trollies/GetAll?trolleyBarcode=${trolleyBarcode}`
      );
      return res.status === 200 && res.data.length === 0;
    } catch (error) {
      dispatch(setIsTrolleyAvailable(false));
      dispatch(setError(true));
      return false;
    }
  }
);
