import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import {
  AdminRoutes,
  AssemblyUserRoute,
  KitPickerRoutes,
  MilkRunRoutes,
  RouteType,
  SuperAdminRoutes,
} from "../config/RouteList";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../store";

const NavigationTabs: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { userDetails } = useAppSelector((store) => store.user);
  const access = userDetails.userRole;

  const routes: RouteType[] =
    access === "Super Admin"
      ? SuperAdminRoutes
      : access === "Admin"
      ? AdminRoutes
      : access === "Kit Picker Picklist"
      ? KitPickerRoutes
      : access === "Milk Run"
      ? MilkRunRoutes
      : AssemblyUserRoute;

  const activeTab = routes.find(
    (route) => route.path === location.pathname
  )?.activeIndex;

  return (
    <Box
      borderBottom={1}
      borderColor="#BBBDC0"
      boxShadow="7px 4px 9px 3px rgba(0, 0, 0, 0.03)"
    >
      <Tabs value={activeTab} indicatorColor="primary">
        {routes
          .filter((tab) => !tab.hide)
          .map((tab, index) => (
            <Tab
              key={index}
              label={t(tab.name)}
              component={Link}
              to={tab.path}
              disableRipple
            />
          ))}
      </Tabs>
    </Box>
  );
};

export default NavigationTabs;
