import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toTitleCase } from "../utils/toTitleCase";

interface SelectTagPropsType {
  optionList: string[];
  onSetValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
}

const SelectTag: React.FC<SelectTagPropsType> = ({
  optionList,
  onSetValue,
  value,
}) => {
  const { t } = useTranslation();

  const handleSelectChange = (event: SelectChangeEvent): void => {
    onSetValue(event.target.value as string);
  };

  return (
    <Select
      fullWidth
      sx={{ height: "40px", mt: "7px", bgcolor: "white" }}
      onChange={handleSelectChange}
      value={value}
    >
      {optionList?.map((option, index) => (
        <MenuItem key={index} value={option} sx={{ fontWeight: 400 }}>
          {t(toTitleCase(option))}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectTag;
