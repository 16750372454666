import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getError,
  getLoading,
  successTrolleyList,
} from "../store/trolleyStatusSlice";
import axiosInstance from "./base.service";

interface TrolleyListGetRequestProps {
  filterStatus: string | null;
  searchOrder: string;
}

export const trolleyListGetRequest = createAsyncThunk(
  "trolleyStatus/getRequest",
  async (props: TrolleyListGetRequestProps, { dispatch }) => {
    const { filterStatus, searchOrder } = props;
    dispatch(getLoading());
    try {
      const req = await axiosInstance.get(
        `/api/Trollies/GetAll?productionOrder=${searchOrder}&statusList=${filterStatus}`
      );
      dispatch(successTrolleyList(req.data));
    } catch (error) {
      dispatch(getError());
    }
  }
);
