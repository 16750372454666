import React from "react";

interface ReworkIcon {
  color?: string;
  height?: string;
  width?: string;
}

const ReworkIcon: React.FC<ReworkIcon> = ({
  color = "#000000",
  width = "50px",
  height = "50px",
}) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={height}
    height={width}
    viewBox="0 0 256.000000 256.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path
        d="M1465 2548 c-180 -99 -287 -317 -255 -520 20 -137 105 -272 218 -350
        23 -16 42 -33 42 -38 0 -6 -52 -10 -123 -10 -80 0 -141 -5 -174 -15 -64 -19
        -128 -76 -194 -171 l-51 -74 -74 0 -74 0 0 34 c0 60 -19 66 -222 66 -97 0
        -188 -4 -202 -10 l-26 -10 0 -498 c0 -374 3 -501 12 -510 17 -17 409 -17 426
        0 7 7 12 29 12 50 l0 38 73 0 72 0 80 -72 c115 -103 139 -112 316 -116 l146
        -4 5 -71 c3 -40 12 -88 22 -108 41 -86 128 -141 225 -141 143 0 241 103 241
        255 0 61 2 67 21 67 36 0 90 32 111 64 15 25 18 47 16 118 -2 76 1 90 19 110
        34 38 43 64 43 130 0 43 -6 72 -18 93 -18 29 -18 31 0 60 12 21 18 50 18 93 0
        66 -9 92 -43 130 -18 20 -21 34 -19 110 2 71 -1 93 -16 118 -21 32 -75 64
        -111 64 -20 0 -21 5 -21 108 l0 107 67 50 c200 150 259 416 142 647 -55 109
        -197 231 -241 207 -16 -9 -18 -30 -20 -216 l-3 -208 -94 -51 -93 -51 -94 51
        -94 50 0 202 c0 182 -2 202 -18 217 -22 20 -21 20 -47 5z m114 -540 c68 -38
        130 -68 137 -68 18 0 240 117 259 137 12 11 15 48 15 184 0 98 4 169 9 169 15
        0 77 -80 105 -135 98 -197 32 -438 -153 -557 l-45 -28 -190 0 -189 0 -48 31
        c-139 89 -217 265 -189 426 15 87 50 159 108 224 l47 53 5 -185 5 -184 124
        -67z m301 -478 l0 -100 -165 0 -165 0 0 100 0 100 165 0 165 0 0 -100z m-410
        -40 l0 -59 -82 -3 -83 -3 -3 -37 -3 -38 347 0 c403 0 384 4 384 -90 0 -31 -5
        -61 -12 -68 -8 -8 -57 -12 -155 -12 l-143 0 0 -40 0 -40 165 0 c152 0 167 -2
        185 -20 14 -14 20 -33 20 -66 0 -85 3 -84 -195 -84 l-175 0 0 -39 0 -40 171
        -3 c155 -3 172 -5 185 -22 22 -30 18 -112 -6 -136 -18 -18 -33 -20 -185 -20
        l-165 0 0 -40 0 -40 143 0 c98 0 147 -4 155 -12 7 -7 12 -37 12 -67 0 -96 25
        -91 -419 -91 -237 0 -400 4 -424 11 -20 5 -65 33 -98 62 -144 123 -132 117
        -225 117 l-84 0 0 340 0 340 94 0 95 0 64 93 c110 157 127 166 310 167 l127 0
        0 -60z m-772 -412 l2 -308 -145 0 -145 0 0 310 0 311 143 -3 142 -3 3 -307z
        m0 -480 l3 -88 -146 0 -145 0 0 90 0 91 143 -3 142 -3 3 -87z m922 -294 c0
        -51 53 -104 104 -104 54 0 100 45 100 99 1 38 3 41 30 41 l28 0 -3 -73 c-5
        -84 -27 -124 -85 -152 -75 -36 -160 -16 -210 50 -21 28 -28 50 -32 105 l-5 70
        37 0 c35 0 36 -1 36 -36z m120 11 c13 -16 6 -35 -15 -35 -18 0 -28 17 -21 35
        7 19 20 19 36 0z"
      />
      <path
        d="M522 628 c-17 -17 -15 -53 3 -68 20 -17 62 -5 70 20 14 44 -41 80
        -73 48z"
      />
    </g>
  </svg>
);

export default ReworkIcon;
