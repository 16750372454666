import React from "react";
import { TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { ErrorMessage, Field, FieldArray } from "formik";
import { AssemblyAreaType } from "../../../../store/AssemblyAreaSlice";
import { useTranslation } from "react-i18next";
interface TrolleyQuantityInputTableBodyProps {
  values: AssemblyAreaType;
}
const TrolleyQuantityInputTableBody: React.FC<
  TrolleyQuantityInputTableBodyProps
> = ({ values }) => {
  const { t } = useTranslation();
  return (
    <TableBody>
      <>
        <FieldArray name="trolleyQuantities">
          {() => (
            <TableRow>
              {values.trolleyQuantities.map((_, index: number) => {
                return (
                  <TableCell
                    key={index}
                    sx={{
                      padding: "0px 0px 0px 10px",
                      height: innerWidth <= 1500 ? "30px" : "47px",
                    }}
                  >
                    <Field
                      type="number"
                      placeholder={t("Enter Quantity")}
                      name={`trolleyQuantities[${index}].quantity`}
                      style={{
                        width: "100%",
                        height: innerWidth <= 1500 ? "30px" : "46px",
                        borderColor: "transparent",
                        outline: "none",
                      }}
                    />
                    <ErrorMessage
                      name={`trolleyQuantities[${index}].quantity`}
                      render={(msg) => (
                        <Typography color="error" variant="body2">
                          {t(msg)}
                        </Typography>
                      )}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          )}
        </FieldArray>
      </>
    </TableBody>
  );
};

export default TrolleyQuantityInputTableBody;
