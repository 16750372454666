import React from "react";
import { useAppSelector } from "../../../../store";
import { useTranslation } from "react-i18next";
import { TableBody, TableCell, TableRow, Box, Typography } from "@mui/material";
import DateTimeDisplayer from "../../../../components/DateTimeDisplayer";
import { toTitleCase } from "../../../../utils/toTitleCase";
import { Loader } from "../../../../components";
import moment from "moment";

const TrolleyStatusTableBody: React.FC = () => {
  const { trolleyList, loading } = useAppSelector(
    (store) => store.trolleyStatus
  );
  const { t } = useTranslation();

  return (
    <>
      <TableBody>
        {trolleyList?.map((trolley) => {
          const convertedDate = new Date(trolley?.updatedOn + "z");

          return (
            <TableRow key={trolley?.trolleyBarcode}>
              <TableCell>{trolley?.trolleyBarcode}</TableCell>
              <TableCell>
                <DateTimeDisplayer
                  date={moment(convertedDate).format("DD/MM/YYYY")}
                  time={moment(convertedDate, "HH:mm:ss").format("hh:mm A")}
                />
              </TableCell>
              <TableCell>{trolley?.assemblyArea}</TableCell>
              <TableCell>{trolley?.productionOrder}</TableCell>
              <TableCell>
                <Box
                  bgcolor={
                    trolley?.trolleyStatus === "filled"
                      ? "#F1E9F2"
                      : trolley?.trolleyStatus === "assembly"
                      ? "#FEFBE6"
                      : trolley?.trolleyStatus === "production"
                      ? "#EDF9EB"
                      : trolley?.trolleyStatus === "picked"
                      ? "#E6F0F8"
                      : trolley?.trolleyStatus === "emptied"
                      ? "#ebedef"
                      : "#e9d5ee"
                  }
                  borderRadius={"3px"}
                  width={"118px"}
                  height={"44px"}
                  textAlign={"center"}
                  margin={"auto"}
                >
                  <Typography
                    variant="button"
                    fontWeight={"600"}
                    lineHeight={"42px"}
                    color={
                      trolley?.trolleyStatus === "filled"
                        ? "#6D2077"
                        : trolley?.trolleyStatus === "assembly"
                        ? "#CFA700"
                        : trolley?.trolleyStatus === "production"
                        ? "#43B02A"
                        : trolley?.trolleyStatus === "picked"
                        ? "#0066B3"
                        : trolley?.trolleyStatus === "emptied"
                        ? "#928E85"
                        : "#bd10e0"
                    }
                    textTransform={"none"}
                  >
                    {t(toTitleCase(trolley?.trolleyStatus))}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      {trolleyList?.length === 0 && (
        <TableBody>
          <TableRow>
            <TableCell colSpan={5}>
              <Box
                height={"calc(100vh - 500px)"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {loading && <Loader />}
                {trolleyList.length === 0 && !loading && (
                  <Typography variant="h5">
                    {t("No matching results found")}
                  </Typography>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </>
  );
};

export default TrolleyStatusTableBody;
