import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  Box,
  Typography,
} from "@mui/material";
import WarehouseTableHead from "./WarehouseTableHead";
import { RootState, useAppDispatch } from "../../../../store";
import { useSelector } from "react-redux";
import { getAllWarehouses } from "../../../../api/kitPickerPicklist.service";
import WarehouseTableRow from "./WarehouseTableRow";
import { deleteWarehouse } from "../../../../api/warehouse.service";
import {
  setActiveRowIndex,
  setAddWarehouseAction,
  setDefaultFormState,
  setFromStateForUpdate,
  setUpdateWarehouseId,
  setValidationError,
} from "../../../../store/warehouseSlice";
import { Warehouse } from "../../../../store/KitPickerPicklist/kitPickerPicklistModel";
import { useConfirmDelete } from "../../../../hooks";
import { Confirmation, Loader } from "../../../../components";
import { useTranslation } from "react-i18next";

type WarehouseTableProp = {
  setMaterialAvailableAt: (value: string) => void;
};

const WarehouseTable: React.FC<WarehouseTableProp> = ({
  setMaterialAvailableAt,
}) => {
  const { warehouses } = useSelector(
    (store: RootState) => store.productionOrder
  );
  const { artPlatz, partPickLocationMin } = useSelector(
    (store: RootState) => store.warehouseDetails.initialStateForLocation
  );
  const { isModalOpen, selectedUser, openModal, closeModal } =
    useConfirmDelete();
  const [initialLoading, setInitialLoading] = useState(true);
  const [warehouseName, setWarehousename] = useState<string>("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleDeleteWarehouse = (
    id: number,
    index: number,
    warehouse: string
  ): void => {
    setWarehousename(warehouse);
    openModal(id);
    dispatch(setActiveRowIndex(index));
  };

  const handleDeleteCancel = (): void => {
    dispatch(setActiveRowIndex(-1));
    closeModal();
  };
  const confirmDeleteWarehouse = (): void => {
    if (selectedUser) {
      dispatch(deleteWarehouse({ id: selectedUser })).then((res) => {
        if (res.payload) {
          setWarehousename("");
          dispatch(setDefaultFormState());
          dispatch(setActiveRowIndex(-1));
          dispatch(getAllWarehouses());
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      });
    }
    closeModal();
  };

  const handleUpdateWarehouse = (details: Warehouse, index: number): void => {
    dispatch(setActiveRowIndex(index));
    dispatch(setAddWarehouseAction(false));
    dispatch(setUpdateWarehouseId(details.id));
    if (details.artPlatz) {
      setMaterialAvailableAt("artPlatz");
      dispatch(
        setFromStateForUpdate({
          name: details.name,
          artPlatz: details.artPlatz,
        })
      );
    }
    if (details.partPickLocationMin && details.partPickLocationMax) {
      setMaterialAvailableAt("location");
      dispatch(
        setFromStateForUpdate({
          name: details.name,
          partPickLocationMax: details.partPickLocationMax,
          partPickLocationMin: details.partPickLocationMin,
        })
      );
    }
  };

  useEffect(() => {
    artPlatz && setMaterialAvailableAt("artPlatz");
    partPickLocationMin && setMaterialAvailableAt("location");
    dispatch(setValidationError({ ErrorCode: "", Message: "" }));
    dispatch(getAllWarehouses()).then(() => {
      setInitialLoading(false);
    });
  }, [dispatch]);
  return (
    <>
      {initialLoading && <Loader />}
      {!initialLoading && warehouses.length === 0 && (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          marginTop={"37px"}
        >
          <Typography variant="h5">{t("No Data Available")}</Typography>
        </Box>
      )}
      {!initialLoading && warehouses.length !== 0 && (
        <TableContainer>
          <Table stickyHeader>
            <WarehouseTableHead />
            <TableBody>
              {warehouses.map((warehouse, index) => {
                return (
                  <WarehouseTableRow
                    key={warehouse.id}
                    id={warehouse.id}
                    name={warehouse.name}
                    partPickLocationMin={warehouse.partPickLocationMin}
                    partPickLocationMax={warehouse.partPickLocationMax}
                    artPlatz={warehouse.artPlatz}
                    handleUpdateWarehouse={handleUpdateWarehouse}
                    handleDeleteWarehouse={handleDeleteWarehouse}
                    index={index}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Confirmation
        title={t("Confirm Delete")}
        message={`${t(
          "Are you sure you want to delete this warehouse"
        )} '${warehouseName}'?`}
        open={isModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={confirmDeleteWarehouse}
      />
    </>
  );
};

export default WarehouseTable;
