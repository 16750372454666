import React, { useState, useEffect } from "react";
import { Confirmation } from "../../../../components";
import { Box, CircularProgress, Table, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { getWorkCenterTypeAction } from "../../../../api/workCentertype.service";
import { getAllWarehouses } from "../../../../api/kitPickerPicklist.service";
import {
  AssemblyAreaType,
  AssemblyAreasType,
  setSelectedId,
  setTags,
  setValidationError,
} from "../../../../store/AssemblyAreaSlice";
import {
  deleteAssemblyAreaAction,
  getAssemblyAreaAction,
} from "../../../../api/assemblyArea.service";
import AssemblyAreaTableHead from "./AssemblyAreaTableHead";
import AssemblyAreaTableRow from "./AssemblyAreaTableRow";
import { useConfirmDelete } from "../../../../hooks";
import AssemblyAreaForm from "./AssemblyAreaForm";

const AssemblyArea: React.FC = () => {
  const { t } = useTranslation();
  const [assemblyAreaLineName, setAssemblyAreaLineName] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [storeTags, setStoreTags] = useState<string[]>([]);
  const [deletedTags, setDeletedTags] = useState<string[]>([]);
  const { isModalOpen, selectedUser, openModal, closeModal } =
    useConfirmDelete();
  const dispatch = useAppDispatch();

  const { warehouses } = useAppSelector((store) => store.productionOrder);

  const { assemblyAreas, selectedId } = useAppSelector(
    (state) => state.assemblyAreas
  );
  const memoizedWarehouses = React.useMemo(() => warehouses, [warehouses]);

  const initialTrolleyQuantities = warehouses.map((item) => ({
    warehouseId: item.id,
    quantity: "",
  }));

  const AssemblyAreaInitials: AssemblyAreaType = {
    assemblyLineName: "",
    keywords: "",
    trolleyQuantities: initialTrolleyQuantities,
    workCenterType: "",
    workCenterTypeId: null,
  };

  const [InitialData, setInitialData] =
    React.useState<AssemblyAreaType>(AssemblyAreaInitials);

  const assemblyAreaDelete = (): void => {
    if (selectedUser) {
      dispatch(deleteAssemblyAreaAction(selectedUser)).then((res) => {
        const result = res.payload;
        result
          ? dispatch(getAssemblyAreaAction())
          : window.scrollTo({ top: 0, behavior: "smooth" });
      });
    }
    closeModal();
    dispatch(setSelectedId(0));
  };

  const updateAssemblyArea = (
    id: number | null,
    item: AssemblyAreasType
  ): void => {
    if (id) {
      dispatch(setValidationError({ ErrorCode: "", Message: "" }));
      dispatch(setSelectedId(id));
      const numberOfNewObjectsNeeded =
        warehouses.length - item.trolleyQuantities.length;
      const newTrolleyQuantities = Array.from(
        { length: numberOfNewObjectsNeeded },
        (_, index) => ({
          assemblyLineId: item.assemblyLineId,
          warehouseId: warehouses[item.trolleyQuantities.length + index].id,
          quantity: 0,
          assemblyLine: null,
          warehouse: null,
        })
      );
      const updatedTrolleyQuantities = [
        ...item.trolleyQuantities,
        ...newTrolleyQuantities,
      ];
      const updatedItem = {
        ...item,
        trolleyQuantities: updatedTrolleyQuantities,
      };
      setInitialData(updatedItem);
      const tagsArray = item.keywords.split(", ");
      setStoreTags(tagsArray);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const openConformDelete = (
    id: number | null,
    AssemblyLineName: string
  ): void => {
    if (id !== null) {
      setInitialData(AssemblyAreaInitials);
      setStoreTags([]);
      setDeletedTags([]);
      setAssemblyAreaLineName(AssemblyLineName);
      dispatch(setSelectedId(id));
      openModal(id);
    }
  };

  useEffect(() => {
    const fetchData = (): void => {
      dispatch(getAssemblyAreaAction()).then(() => {
        setInitialLoading(false);
      });
      dispatch(getWorkCenterTypeAction());
      dispatch(getAllWarehouses());
      dispatch(setSelectedId(0));
      dispatch(setTags(""));
    };
    fetchData();
    // setTimeout(() => {
    //   dispatch(setValidationError({ ErrorCode: "", Message: "" }));
    // }, 15 * 1000);
    // const refreshInterval = setInterval(fetchData, 60 * 1000);
    // return () => clearInterval(refreshInterval);
  }, [dispatch]);

  return (
    <Box m={5}>
      <AssemblyAreaForm
        setDeletedTags={setDeletedTags}
        deletedTags={deletedTags}
        setStoreTags={setStoreTags}
        storeTags={storeTags}
        initialData={InitialData}
        setInitialData={setInitialData}
        AssemblyAreaInitials={AssemblyAreaInitials}
      />
      {initialLoading && (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          mt="30px"
        >
          <CircularProgress />
        </Box>
      )}
      <Box mt={window.innerWidth <= 1500 ? "30px" : "44px"}>
        {!initialLoading && assemblyAreas?.length === 0 && (
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            mt="30px"
          >
            <Typography variant="h5">{t("No Data Available")}</Typography>
          </Box>
        )}
        {!initialLoading && assemblyAreas?.length !== 0 && (
          <Table sx={{ mb: 5 }}>
            <AssemblyAreaTableHead memoizedWarehouses={memoizedWarehouses} />
            <AssemblyAreaTableRow
              assemblyAreas={assemblyAreas}
              memoizedWarehousesLength={memoizedWarehouses.length}
              id={selectedId}
              openConformDelete={openConformDelete}
              updateAssemblyArea={updateAssemblyArea}
            />
          </Table>
        )}

        <Confirmation
          title={t("Confirm Delete")}
          message={`${t(
            "Are you sure you want to delete the"
          )} '${assemblyAreaLineName}' ${t("assembly area")}?`}
          open={isModalOpen}
          onClose={closeModal}
          onConfirm={assemblyAreaDelete}
        />
      </Box>
    </Box>
  );
};

export default AssemblyArea;
