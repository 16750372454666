import React, { useState, useEffect } from "react";
import { TextField, Box, InputAdornment } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { BsCheckCircleFill } from "react-icons/bs";
import { checkIsTrolleyAvailable } from "../../api/kitPickerPicklist.service";
import { RootState, useAppDispatch } from "../../store";
import { setIsTrolleyAvailable } from "../../store/KitPickerPicklist/kitPickerPicklistSlice";
import { useSelector } from "react-redux";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { speakText } from "../../utils/speakText";

type KitTrolleyIdInputProps = {
  name: string;
  width?: string;
  type?: string;
  isFetched: boolean;
  setIsFetched: (value: boolean) => void;
};

const KitTrolleyIdInput: React.FC<KitTrolleyIdInputProps> = (props) => {
  const { name, width, type, isFetched } = props;
  const { errors, setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const { value } = meta;
  const [trolleyError, setTrolleyError] = useState<string>("");
  const { needRefresh, isAllOrderCompleted } = useSelector(
    (store: RootState) => store.productionOrder
  );
  const dispatch = useAppDispatch();
  const { barcode, enterKey, handleResetEnterKey } = useBarcodeScanner();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(name, e.target.value);
    const inputValue = e.target.value;
    const regex = /^\d{4}$/;
    if (regex.test(inputValue)) {
      dispatch(
        checkIsTrolleyAvailable({ trolleyBarcode: e.target.value })
      ).then((res) => {
        if (res.payload) {
          setTrolleyError("");
          dispatch(setIsTrolleyAvailable(true));
        } else {
          dispatch(setIsTrolleyAvailable(false));
          setTrolleyError(t("Trolley is not available"));
        }
      });
    } else {
      dispatch(setIsTrolleyAvailable(false));
    }
  };
  const isError = !!errors[name as keyof typeof errors] || !!trolleyError;
  useEffect(() => {
    if (enterKey && !isFetched && !needRefresh && !isAllOrderCompleted) {
      handleResetEnterKey();
      setFieldValue(name, barcode);
      dispatch(checkIsTrolleyAvailable({ trolleyBarcode: barcode })).then(
        (res) => {
          if (res.payload) {
            setTrolleyError("");
            dispatch(setIsTrolleyAvailable(true));
          } else {
            speakText(t("Trolley is not available"), currentLanguage);
            setTrolleyError(t("Trolley is not available"));
          }
        }
      );
    }
  }, [
    barcode,
    name,
    setFieldValue,
    enterKey,
    dispatch,
    isFetched,
    t,
    currentLanguage,
    handleResetEnterKey,
    isAllOrderCompleted,
    needRefresh,
  ]);

  return (
    <Box width={width}>
      <TextField
        {...field}
        value={value}
        type={type || "text"}
        variant="outlined"
        size="small"
        onChange={handleChange}
        fullWidth
        disabled={isAllOrderCompleted || needRefresh}
        error={isError}
        helperText={
          isError ? t(errors[name as keyof typeof errors]) || trolleyError : ""
        }
        autoComplete="off"
        InputProps={{
          endAdornment:
            !isError && value ? (
              <InputAdornment position="end">
                <BsCheckCircleFill color="#43B02A" size="27px" />
              </InputAdornment>
            ) : undefined,
        }}
      />
    </Box>
  );
};

export default KitTrolleyIdInput;
