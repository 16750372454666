import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { SelectTagPopOver, PageTitle, Navbar } from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { fetchAssemblyLineList } from "../../api/reportTrolley.service";
import ConfirmFilledTrolley from "./ConfirmFilledTrolley";
import ReportEmptyTrolley from "./ReportEmptyTrolley";
import { OptionTypes } from "../../components/SelectTagPopOver";

const FilledTrolleyCard: React.FC = () => {
  const { assemblyLineList } = useAppSelector((store) => store.reportTrolley);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const [showData, setShowData] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [assemblyLine, setAssemblyLine] = useState<OptionTypes>({
    id: "",
    optionName: "",
    workCenterType: "",
  });

  useEffect(() => {
    dispatch(fetchAssemblyLineList());
  }, [dispatch]);

  const optionList = assemblyLineList.map((line) => ({
    id: line.assemblyLineId,
    optionName: line.assemblyLineName,
    workCenterType: line.workCenterType || "",
  }));

  const handleSelectChange = (event: SelectChangeEvent): void => {
    const optionId = event.target.value as string;
    const selectedOption = optionList.find(
      (option) => option.id === Number(optionId)
    );
    setAssemblyLine(
      selectedOption || { id: "", optionName: "", workCenterType: "" }
    );
  };

  const handleProceedClick = (): void => {
    setOpenModal(false);
    setShowData(true);
  };

  return (
    <Box>
      <Navbar />
      <SelectTagPopOver
        open={openModal}
        currentValue={assemblyLine.id}
        heading={"Select Line Name"}
        inputLable="Line Name"
        onSelectChange={handleSelectChange}
        onProceedButton={handleProceedClick}
        optionList={optionList}
      />
      <PageTitle title={assemblyLine.optionName} />
      {showData && (
        <>
          <Box
            borderBottom={1}
            borderColor="#BBBDC0"
            boxShadow="7px 4px 9px 3px rgba(0, 0, 0, 0.03)"
          >
            <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
              <Tab label={t("Confirm Filled Trolley")} disableRipple />
              <Tab label={t("Report Empty Trolley")} disableRipple />
            </Tabs>
          </Box>

          {[ConfirmFilledTrolley, ReportEmptyTrolley].map(
            (Component, index) => (
              <Box key={index}>
                {value === index && <Component assemblyLine={assemblyLine} />}
              </Box>
            )
          )}
        </>
      )}
    </Box>
  );
};

export default FilledTrolleyCard;
