import { TableCell, Typography } from "@mui/material";
import React from "react";
import { AssemblyAreasType } from "../../../../store/AssemblyAreaSlice";
import { useTranslation } from "react-i18next";
interface TrolleyQuantityColumnsProps {
  item: AssemblyAreasType;
  checkId: boolean;
  memoizedWarehousesLength: number;
}
const TrolleyQuantityColumns: React.FC<TrolleyQuantityColumnsProps> = ({
  item,
  checkId,
  memoizedWarehousesLength,
}) => {
  const numColumns = memoizedWarehousesLength;
  const highlightStyle = {
    fontWeight: "bold",
    backgroundColor: "#E5FFCC",
  };
  const { t } = useTranslation();
  return (
    <>
      {Array.from({ length: numColumns }).map((_, index) => {
        return (
          <TableCell
            sx={{
              textAlign: "center",
              ...(checkId ? highlightStyle : {}),
            }}
            key={index}
          >
            {item.trolleyQuantities[index].quantity !== null ? (
              item.trolleyQuantities[index].quantity
            ) : (
              <Typography color={"red"}>{t("Not Defined")}</Typography>
            )}
          </TableCell>
        );
      })}
    </>
  );
};

export default TrolleyQuantityColumns;
