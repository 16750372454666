import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Type,
  addWorkCenterType,
  getWorkCenterTypes,
  setError,
  setLoading,
  setValidationError,
} from "../store/workCenterTypeSlice";
import axiosInstance from "./base.service";

export const addWorkCenterTypeAction = createAsyncThunk(
    "workCenterType/addWorkCenterType",
    async (workCenterType: Type, { dispatch }) => {
      try {
        dispatch(setLoading());
        const response = await axiosInstance.post(`api/WorkCenterType`, workCenterType);
        dispatch(addWorkCenterType(response.data));
        return true;
      } catch (error:any) {
        dispatch(setValidationError(error));
        return false;
      }
    }
  );

  export const updateWorkCenterTypeAction = createAsyncThunk(
    "workCenterType/updateWorkCenterType",
    async (updatedType:{id:number,name:string}, { dispatch }) => {
      try {
        await axiosInstance.put(`api/WorkCenterType`,updatedType);
        return true;
      } catch (error:any) {
        dispatch(setValidationError(error));
        return false;
      }
    }
  );
  
export const getWorkCenterTypeAction = createAsyncThunk(
  "workCenterType/getWorkCenterTypes",
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await axiosInstance.get("api/WorkCenterType");
      const data = res.data;
      dispatch(getWorkCenterTypes(data));
      return data
    } catch (error) {
      dispatch(setError());
    }
  }
);

export const deleteCenterType = createAsyncThunk(
  "workCenterType/deleteWorkCenterTypes",
  async (id: number, { dispatch }) => {
    dispatch(setLoading());
    try {
      await axiosInstance.delete(`api/WorkCenterType/${id}`);
      return true;
    } catch (error:any) {
      dispatch(setValidationError(error));
      return false;
    }
  }
);