import React from "react";
import { BiSolidEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Box, Switch, Typography } from "@mui/material";
import { RootState, useAppDispatch } from "../../../store";
import {
  setStateForUpdateUser,
  setUserValidations,
} from "../../../store/userListSlice";
import { useSelector } from "react-redux";

type UserTableRowProps = {
  id: number;
  name: string;
  email: string;
  userRole: string;
  isActive: boolean;
  language: string;
  showSrNumbers: boolean;
  showRoles: boolean;
  index: number;
  toggleUserStatus: (
    id: number,
    status: boolean,
    name: string,
    email: string,
    role: string,
    language: string
  ) => void;
  handleDeleteUser: (id: number, name: string) => void;
};

const UserTableRow: React.FC<UserTableRowProps> = (props) => {
  const {
    id,
    name,
    email,
    userRole,
    isActive,
    language,
    index,
    showSrNumbers,
    showRoles,
    toggleUserStatus,
    handleDeleteUser,
  } = props;
  const { userDetails } = useSelector((store: RootState) => store.user);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    toggleUserStatus(id, event.target.checked, name, email, userRole, language);
  };

  const updateUserAction = (): void => {
    dispatch(
      setStateForUpdateUser({
        id,
        name,
        email,
        userRole,
        createAction: false,
        isActive,
        language,
      })
    );
    dispatch(setUserValidations({ ErrorCode: "", Message: "" }));
    navigate("/create-user");
  };
  const onDeleteClick = (): void => {
    if (userDetails.email === email) return;
    handleDeleteUser(id, name);
  };
  return (
    <>
      <TableRow key={id}>
        {showSrNumbers && (
          <TableCell sx={{ width: "210px", textAlign: "center" }}>
            {index + 1}
          </TableCell>
        )}
        <TableCell>{name}</TableCell>
        <TableCell>{email}</TableCell>
        {showRoles && <TableCell>{userRole}</TableCell>}
        <TableCell sx={{ minWidth: "200px" }}>
          <Box display={"flex"} alignItems={"center"} gap={"16px"}>
            <Switch
              size="medium"
              checked={isActive}
              color="primary"
              inputProps={{ "aria-label": "user status" }}
              onChange={handleStatusChange}
              disabled={userDetails.email === email}
            />
            <Typography fontWeight={600} fontSize={"18px"}>
              {isActive ? `${t("Active")}` : `${t("Inactive")}`}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box display={"flex"} gap={"30px"} justifyContent={"center"}>
            <BiSolidEdit
              size={"18px"}
              color="#0078a1"
              cursor={"pointer"}
              onClick={updateUserAction}
            />

            <RiDeleteBin6Line
              size={"18px"}
              color={userDetails.email === email ? "#e0e0e0" : "red"}
              cursor={userDetails.email === email ? "default" : "pointer"}
              onClick={onDeleteClick}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserTableRow;
