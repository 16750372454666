import { Instance } from "./config";

/* eslint-disable */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_AZURE_CLIENT_ID || "",
    authority: `${Instance}${process.env.REACT_APP_MICROSOFT_AZURE_TENTANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};
