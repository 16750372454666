import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error";

type CustomizedSnackBarProp = {
  errorMessage: string;
  setErrorMessage: (val: string) => void;
  duration?: number;
};

const CustomizedSnackbar: React.FC<CustomizedSnackBarProp> = ({
  errorMessage,
  setErrorMessage,
  duration = 3000,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Snackbar
        open={!!errorMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={duration}
        onClose={() => setErrorMessage("")}
      >
        <Alert
          onClose={() => setErrorMessage("")}
          severity="error"
          iconMapping={{
            error: <ErrorIcon style={{ fontSize: "30px" }} />,
          }}
          sx={{ fontSize: "20px" }}
        >
          {t(errorMessage)}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomizedSnackbar;
