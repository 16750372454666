import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setError,
  setLoading,
  setUserList,
  setUserValidations,
} from "../store/userListSlice";
import axiosInstance from "./base.service";
import { setLoginDetails, setUser } from "../store/userSlice";
import i18n from "../i18n";

export const getUserDetails = createAsyncThunk(
  "applicationUser/getUserDetails",
  async (_, { dispatch }) => {
    try {
      const { data } = await axiosInstance.get(
        "api/ApplicationUser/GetUserDetails"
      );
      if (data.isActive) dispatch(setLoginDetails(data));
      dispatch(setUser(true));
      i18n.changeLanguage(data.language || "en-US");
    } catch (error) {
      dispatch(setError());
    }
  }
);

interface UpdateUserLanguageTypes {
  email: string;
  language: string;
}

export const updateUserLanguage = createAsyncThunk(
  "applicationUser/updateLanguage",
  async (data: UpdateUserLanguageTypes, { dispatch }) => {
    try {
      await axiosInstance.put(`api/ApplicationUser/UpdateLanguage`, data);
    } catch (error) {
      dispatch(setError());
    }
  }
);

export const getUserList = createAsyncThunk(
  "userList/getUserList",
  async (_, { dispatch }) => {
    try {
      const res = await axiosInstance.get("api/ApplicationUser");
      const data = res.data;
      dispatch(setUserList(data));
    } catch (error) {
      dispatch(setError());
    }
  }
);

export const addUser = createAsyncThunk(
  "userList/addUser",
  async (
    data: {
      name: string;
      email: string;
      userRole?: string;
    },
    { dispatch }
  ): Promise<boolean> => {
    const user = {
      ...data,
      isActive: false,
    };
    dispatch(setLoading(true));
    try {
      const res = await axiosInstance.post("api/ApplicationUser", user);
      if (res.status === 200) {
        dispatch(setUserValidations({ ErrorCode: "", Message: "" }));
        return true;
      }
      dispatch(setLoading(false));
      return false;
    } catch (error: any) {
      dispatch(setUserValidations(error));
      return false;
    }
  }
);

export const updateUser = createAsyncThunk(
  "userList/updateUserStatus",
  async (
    data: {
      id: number;
      isActive: boolean;
      name: string;
      email: string;
      userRole?: string;
      language?: string;
    },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    try {
      const res = await axiosInstance.put(`api/ApplicationUser`, data);
      if (res.status === 200) {
        dispatch(setUserValidations({ ErrorCode: "", Message: "" }));
        return true;
      }
      dispatch(setLoading(false));
      return false;
    } catch (error: any) {
      dispatch(setUserValidations(error));
      return false;
    }
  }
);

export const deleteUser = createAsyncThunk(
  "userList/deleteUser",
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await axiosInstance.delete(`api/ApplicationUser/${id}`);
      if (res.status === 200) {
        dispatch(setUserValidations({ ErrorCode: "", Message: "" }));
        return true;
      }
      dispatch(setLoading(false));
      return true;
    } catch (error: any) {
      dispatch(setUserValidations(error));
      return false;
    }
  }
);
