import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Plant,
  getPlants,
  savePlant,
  setError,
  setLoading,
} from "../store/plantDetailsSlice";
import axiosInstance from "./base.service";

export const getPlantDetails = createAsyncThunk(
  "plantDetails/fetchPlantDetails",
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading());
      const response = await axiosInstance.get("/plantDetails");
      dispatch(getPlants(response.data));
      return response.data;
    } catch (err) {
      dispatch(setError());
    }
  }
);

interface DataInitials {
  id: number;
  name: string;
}
export const savePlantDetails = createAsyncThunk(
  "plantDetails/savePlant",
  async (plant: Plant, { dispatch }) => {
    const data: DataInitials = { ...plant, id: +plant.id };
    try {
      dispatch(setLoading());
      const response = await axiosInstance.patch(`/plantDetails`, data);
      dispatch(savePlant(response.data));
      return true;
    } catch (err) {
      dispatch(setError());
      return false;
    }
  }
);
