import React from 'react'
import {TableHead,TableRow,TableCell} from "@mui/material"
import { useAppSelector } from '../../../../store';

const TrolleyQuantityInputTableHead:React.FC = () => {
    const { warehouses } = useAppSelector((store) => store.productionOrder);
  return (
    <TableHead>
            <TableRow>
              {warehouses.map((warehouse) => (
                <TableCell
                  key={warehouse.id}
                  sx={{
                    height: window.innerWidth <= 1500 ? "30px" : "47px",
                    padding: window.innerWidth <= 1500 ? "2px 33px" : "16px 33px",
                  }}
                >
                  {warehouse.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
  )
}

export default TrolleyQuantityInputTableHead