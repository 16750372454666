import React, { useEffect, useState, useCallback } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store";
import { useTranslation } from "react-i18next";
import {
  fetchReportTrolleyList,
  sendTrolleyToRework,
  undoUpdateReportTrolleyInProduction,
  updateReportTrolleyInProduction,
} from "../../api/reportTrolley.service";
import {
  TrolleyType,
  updateActiveProdutionOrder,
} from "../../store/reportTrolley";
import FilledTrolleyCard from "./FilledTrolleyCard";
import { CustomizedSnackbar, Loader, PopOver } from "../../components";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import Kitlogo from "../../assets/image/KitStatus/Group 9002.svg";
import i18n from "i18next";
import { speakText } from "../../utils/speakText";
import { enqueueSnackbar } from "notistack";
import { OptionTypes } from "../../components/SelectTagPopOver";
import { BsCheckCircleFill } from "react-icons/bs";

interface TabPanelProps {
  assemblyLine: OptionTypes;
}

const ConfirmFilledTrolley: React.FC<TabPanelProps> = ({ assemblyLine }) => {
  const currentLanguage = i18n.language;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enterKey, barcode, handleResetEnterKey } = useBarcodeScanner();
  const [initialLoading, setInitialLoading] = useState(true);
  const {
    trolleyList,
    updateTrolleyInProduction,
    activeProductionOrderConfirmFilledTab,
  } = useAppSelector((store) => store.reportTrolley);
  const [scanErrorMessage, setScanErrorMessage] = useState("");
  const [openPopover, setOpenPopover] = useState(false);
  const [popOverText, setPopOverText] = useState("");
  const [reworkAction, setReworkAction] = useState({
    rework: false,
    trolleyBarcode: "",
    productionOrder: "",
  });

  const groupedData = trolleyList.reduce((map, item) => {
    const assemblyArea = item.productionOrder;
    if (!map.has(assemblyArea)) {
      map.set(assemblyArea, []);
    }
    map.get(assemblyArea)?.push(item);
    return map;
  }, new Map<string, TrolleyType[]>());

  const groupedDataObject: Record<string, TrolleyType[]> =
    Object.fromEntries(groupedData);

  const groupedDataArray = Object.entries(groupedDataObject).map(
    ([key, value]) => ({
      productionOrder: key,
      trolleyList: value,
    })
  );

  const handleSendToRework = (): void => {
    dispatch(
      sendTrolleyToRework({
        trolleyBarcode: reworkAction.trolleyBarcode,
        productionOrderId: reworkAction.productionOrder,
      })
    ).then((res) => {
      if (res.payload) {
        setOpenPopover(false);
        setReworkAction({
          rework: false,
          trolleyBarcode: "",
          productionOrder: "",
        });
        setPopOverText("");
      }
    });
  };

  const closePopover = (): void => {
    setOpenPopover(false);
    setReworkAction({ rework: false, trolleyBarcode: "", productionOrder: "" });
    setPopOverText("");
  };

  const handleUpdateTrolleyStatus = (
    trolleyBarcode: string,
    status: string,
    productionOrder: string
  ): void => {
    if (productionOrder === activeProductionOrderConfirmFilledTab) {
      if (status === "assembly") {
        setPopOverText("Trolley is in Production");
        dispatch(updateReportTrolleyInProduction(trolleyBarcode)).then(
          (res) => {
            if (res.payload === true) {
              setOpenPopover(true);
            }
          }
        );
      } else if (status === "production") {
        dispatch(undoUpdateReportTrolleyInProduction(trolleyBarcode));
      }
    } else {
      enqueueSnackbar(t("Please scan the production order first"));
    }
  };

  const handleSendTrolleyRework = (
    trolleyBarcode: string,
    status: string,
    productionOrder: string
  ): void => {
    if (productionOrder === activeProductionOrderConfirmFilledTab) {
      if (status === "production" && assemblyLine.workCenterType !== "Rework") {
        setReworkAction({
          rework: true,
          trolleyBarcode: trolleyBarcode,
          productionOrder: productionOrder,
        });
        setPopOverText("Add trolley to Rework area?");
        setOpenPopover(true);
      }
    } else {
      enqueueSnackbar(t("Please scan the production order first"));
    }
  };

  const handleActiveOrder = (productionOrder: string): void => {
    dispatch(
      updateActiveProdutionOrder({
        tab: "Confirm Filled",
        productionOrder,
      })
    );
  };

  const scanBarcodeAssemblyUser = useCallback(() => {
    if (enterKey) {
      handleResetEnterKey();
      let notFoundMessage = "Production order & trolley not found";

      trolleyList?.map((trolley) => {
        {
          if (trolley.productionOrder === barcode) {
            const section = document.getElementById(trolley.productionOrder);
            if (section) {
              section.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
            dispatch(
              updateActiveProdutionOrder({
                tab: "Confirm Filled",
                productionOrder: trolley.productionOrder,
              })
            );
            notFoundMessage = "Production order found";
          } else {
            if (
              trolley.productionOrder === activeProductionOrderConfirmFilledTab
            ) {
              if (trolley.trolleyBarcode === barcode) {
                const section = document.getElementById(trolley.trolleyBarcode);
                if (section) {
                  section.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }
                if (trolley.trolleyStatus === "assembly") {
                  setPopOverText("Trolley is in Production");
                  dispatch(
                    updateReportTrolleyInProduction(trolley.trolleyBarcode)
                  ).then((res) => {
                    if (res.payload) {
                      setOpenPopover(true);
                    }
                  });
                }

                if (
                  trolley.trolleyStatus === "production" &&
                  assemblyLine.workCenterType !== "Rework"
                ) {
                  setReworkAction({
                    rework: true,
                    trolleyBarcode: trolley.trolleyBarcode,
                    productionOrder: trolley.productionOrder,
                  });
                  setPopOverText("Add trolley to Rework area?");
                  setOpenPopover(true);
                }

                notFoundMessage = "Trolley found";
              }
            } else {
              if (trolley.trolleyBarcode === barcode) {
                const section = document.getElementById(trolley.trolleyBarcode);
                if (section) {
                  section.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }

                notFoundMessage = "Production order not active";
              }
            }
          }
        }
      });

      if (notFoundMessage === "Production order not active") {
        speakText(t("Please scan the production order first"));
        enqueueSnackbar(t("Please scan the production order first"));
      } else if (notFoundMessage === "Production order & trolley not found") {
        speakText(`${t("Unable to find trolley")} ${barcode}`, currentLanguage);
        enqueueSnackbar(`${t("Unable to find trolley")} ${barcode}`);
      }
    }
  }, [
    enterKey,
    activeProductionOrderConfirmFilledTab,
    barcode,
    assemblyLine,
    trolleyList,
    currentLanguage,
    handleResetEnterKey,
    dispatch,
    t,
  ]);

  useEffect(() => {
    scanBarcodeAssemblyUser();
  }, [scanBarcodeAssemblyUser]);

  useEffect(() => {
    dispatch(
      fetchReportTrolleyList({
        assemblyId: Number(assemblyLine.id),
        statusList: ["assembly", "production", "rework"],
      })
    ).then(() => {
      setInitialLoading(false);
    });

    const refreshInterval = setInterval(() => {
      dispatch(
        fetchReportTrolleyList({
          assemblyId: Number(assemblyLine.id),
          statusList: ["assembly", "production", "rework"],
        })
      );
    }, 60 * 1000);
    return () => clearInterval(refreshInterval);
  }, [dispatch, assemblyLine, updateTrolleyInProduction]);

  return (
    <>
      <Box p={"27px 53px"} className="scroll-bar" height="calc(100vh - 235px)">
        {initialLoading && <Loader />}
        {!initialLoading && groupedDataArray?.length === 0 && (
          <Box mt={10}>
            <Typography variant="h5" textAlign={"center"}>
              {t("There are no trolleys available on Assembly line")}
            </Typography>
          </Box>
        )}
        <Stack gap={"26px"}>
          {!initialLoading &&
            groupedDataArray?.map((order) => (
              <Stack
                key={order.productionOrder}
                gap={1}
                id={order.productionOrder}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  minHeight={"30px"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleActiveOrder(order.productionOrder)}
                >
                  <Typography variant="h4" sx={{ cursor: "pointer" }}>
                    {t("Production Order")} - {order.productionOrder}
                  </Typography>
                  {activeProductionOrderConfirmFilledTab ===
                    order.productionOrder && (
                    <BsCheckCircleFill color="#43B02A" size="30px" />
                  )}
                </Box>
                <Stack direction={"row"} justifyContent={"flex-start"}>
                  <Stack
                    border={"1px solid #5A5D60"}
                    bgcolor={
                      activeProductionOrderConfirmFilledTab ===
                      order.productionOrder
                        ? "#B4D516"
                        : ""
                    }
                    borderRadius={"3px"}
                    padding={"13px 9px"}
                    direction={"row"}
                    gap={"17px"}
                    flexWrap={"wrap"}
                  >
                    {order?.trolleyList?.map((trolley) => (
                      <FilledTrolleyCard
                        key={trolley.trolleyBarcode}
                        trolleyBarcode={trolley.trolleyBarcode}
                        status={trolley.trolleyStatus}
                        productionOrder={order.productionOrder}
                        onUpdateTrolley={handleUpdateTrolleyStatus}
                        onSendTrolleyRework={handleSendTrolleyRework}
                        assemblyLine={assemblyLine}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            ))}
        </Stack>
      </Box>
      <CustomizedSnackbar
        errorMessage={scanErrorMessage}
        setErrorMessage={setScanErrorMessage}
        duration={15000}
      />
      <PopOver
        title={popOverText}
        logo={Kitlogo}
        enableSecondButton={reworkAction.rework}
        onSecondButtonAction={handleSendToRework}
        closePopOver={closePopover}
        open={openPopover}
      />
    </>
  );
};

export default ConfirmFilledTrolley;
