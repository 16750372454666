import { Box } from '@mui/material'
import React from 'react'
import { InputTag, LabelTag } from '../../../../components'

const WorkCenterTypeInput:React.FC = () => {
  return (
    <Box width={"50%"} >
      <LabelTag label="Work Center Type" required={true} />
      <InputTag name={"name"} width={"446px"} type={"text"} />
    </Box>
  )
}

export default WorkCenterTypeInput