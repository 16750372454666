import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Backdrop,
  CircularProgress,
  ThemeProvider,
  Typography,
  Box,
} from "@mui/material";
import { theme } from "./themes/theme";
import { useAppDispatch, useAppSelector } from "./store";
import { userLoading, setUserAccessToken } from "./store/userSlice";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./i18n";
import { theme as themes } from "./themes/theme";
import AccessWiseRoutes from "./config/AccessWiseRoutes";
import { enqueueSnackbar } from "notistack";
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { getUserDetails } from "./api/user.service";
import { AuthenticationTokenScope } from "./config/config";

const App: React.FC = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((store) => store.user);

  useEffect(() => {
    const handleLogin = async (): Promise<void> => {
      dispatch(userLoading());

      const tokenRequest = {
        scopes: AuthenticationTokenScope,
      };

      try {
        await instance.handleRedirectPromise();
        const availableAccounts = await instance.getAllAccounts();

        if (availableAccounts.length === 0) {
          await instance.loginRedirect();
        } else {
          instance.setActiveAccount(availableAccounts[0]);
          const response = await instance.acquireTokenSilent(tokenRequest);

          if (response.expiresOn) {
            dispatch(
              setUserAccessToken({
                accessToken: response?.accessToken,
                accessTokenExpiration: new Date(response.expiresOn).getTime(),
              })
            );
            dispatch(getUserDetails());
          }
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect(tokenRequest);
        } else if (error instanceof BrowserAuthError) {
          enqueueSnackbar(error.errorMessage);
        } else {
          enqueueSnackbar(String(error));
        }
      }
    };

    handleLogin();
  }, [accounts, instance, dispatch]);

  return user ? (
    <ThemeProvider theme={themes}>
      <Box bgcolor={"white"}>
        <AccessWiseRoutes />
      </Box>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <Typography variant="h5">Please wait, It still loading...</Typography>
      <Backdrop open={!user} sx={{ backgroundColor: "white" }}>
        <CircularProgress color="primary" size={100} />
      </Backdrop>
    </ThemeProvider>
  );
};
export default App;
