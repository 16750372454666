import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import {
  Component,
  InitialState,
  Order,
  Warehouse,
} from "./kitPickerPicklistModel";
import { OptionTypes } from "../../components/SelectTagPopOver";

const initialState: InitialState = {
  loading: false,
  error: false,
  warehouses: [],
  selectedWarehouse: { id: "", optionName: "" },
  order: {
    id: 0,
    productionOrder: "",
    material: "",
    materialShortText: "",
    assemblyLineId: 0,
  },
  trolleyBarcode: "",
  components: [],
  pickedComponents: [],
  isTrolleyAvailable: false,
  isAllOrderCompleted: false,
  orderErrorMessage: "",
  needRefresh: false,
};

export const kitPickerPicklistSlice = createSlice({
  name: "kitPickerPicklist",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setIsTrolleyAvailable: (state, action: PayloadAction<boolean>) => {
      state.isTrolleyAvailable = action.payload;
    },
    setIsAllOrderCompleted: (state, action) => {
      state.isAllOrderCompleted = action.payload.status;
      state.orderErrorMessage = action.payload.message;
    },
    setNeedRefresh: (state, action: PayloadAction<boolean>) => {
      state.needRefresh = action.payload;
    },
    setWarehouses: (state, action: PayloadAction<Warehouse[]>) => {
      state.warehouses = action.payload;
      state.loading = false;
      state.error = false;
    },
    selectWareHouse: (state, action: PayloadAction<OptionTypes>) => {
      state.selectedWarehouse = action.payload;
    },
    setOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
      state.trolleyBarcode = "";
      state.pickedComponents = [];
      state.loading = false;
      state.error = false;
    },
    setComponents: (state, action: PayloadAction<Component[]>) => {
      state.components = action.payload;
    },
    addMaterialInTrolley: (state, action: PayloadAction<Component>) => {
      state.pickedComponents.push(action.payload);
    },
    removeFromTrolley: (state, action: PayloadAction<number>) => {
      state.pickedComponents = state.pickedComponents.filter(
        (material) => material.id !== action.payload
      );
    },
    setTrolleyBarcode: (state, action: PayloadAction<string>) => {
      state.trolleyBarcode = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setOrder,
  setComponents,
  addMaterialInTrolley,
  removeFromTrolley,
  setWarehouses,
  selectWareHouse,
  setTrolleyBarcode,
  setIsTrolleyAvailable,
  setIsAllOrderCompleted,
  setNeedRefresh,
} = kitPickerPicklistSlice.actions;

export default kitPickerPicklistSlice.reducer;
