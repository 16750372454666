import React from "react";
import { useField, useFormikContext } from "formik";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MenuItem, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface optionType {
  display: string;
  value: string;
}

type RoleSelectTagProps = {
  name: string;
  options: optionType[];
};
const RoleSelectTag: React.FC<RoleSelectTagProps> = ({ name, options }) => {
  const { errors, setFieldValue, touched } = useFormikContext();
  const [field, meta] = useField(name);
  const { value } = meta;
  const handleChange = (e: SelectChangeEvent): void => {
    setFieldValue(name, e.target.value);
  };
  const { t } = useTranslation();

  const isError =
    touched[name as keyof typeof touched] &&
    !!errors[name as keyof typeof errors];
  return (
    <>
      <Select
        {...field}
        value={value}
        onChange={handleChange}
        fullWidth
        error={isError}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {t(option.display)}
            </MenuItem>
          );
        })}
      </Select>
      {isError && (
        <Box width={"100%"}>
          <Typography color={"#d32f2f"} marginTop={"5px"} marginLeft={"15px"}>
            {t(errors[name as keyof typeof errors])}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default RoleSelectTag;
