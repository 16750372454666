import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import React, { Fragment } from "react";
import "./assets/style/scss/site.scss";
import { store } from "./store";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const msalInstance = new PublicClientApplication(msalConfig);

const AppRenderer = (): JSX.Element => {
  return (
    <Fragment>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            style={{ fontSize: "25px" }}
            variant="error"
            autoHideDuration={15000}
            disableWindowBlurListener={true}
            preventDuplicate
          >
            <Provider store={store}>
              <App />
            </Provider>
          </SnackbarProvider>
        </BrowserRouter>
      </MsalProvider>
    </Fragment>
  );
};

root.render(<AppRenderer />);
reportWebVitals();
