import { KitStatus } from "../../containers";
import { RouteType } from ".";

export const MilkRunRoutes: RouteType[] = [
  {
    path: "/",
    name: "Kit Status",
    activeIndex: 0,
    Component: KitStatus,
  },
];
