import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { LabelTag } from "../../../../components";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setTags } from "../../../../store/AssemblyAreaSlice";
interface KeyWordsInputProps {
  deletedTags:string[]
  setDeletedTags:React.Dispatch<React.SetStateAction<string[]>>;
  storeTags: string[];
  setStoreTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const KeyWordsInput: React.FC<KeyWordsInputProps> = ({
  deletedTags,
  setDeletedTags,
  storeTags,
  setStoreTags,
}) => {
  const { t } = useTranslation();
  const dispatch=useAppDispatch()
  const { errors, setFieldValue, touched } = useFormikContext();
  const [field] = useField("keywords");


  const { tags } = useAppSelector((state) => state.assemblyAreas);
 
  const addTag = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === " " || e.key==="Enter") {
      e.preventDefault();
      const trimmedTag = tags.trim();
      if (trimmedTag) {
        const updatedTags = [...storeTags, trimmedTag];
        setStoreTags(updatedTags);
        dispatch(setTags(""))
        setFieldValue("keywords", updatedTags.join(", "));
      }
    }
  };

  const tagDeleteAction = (text: string): void => {
    const remainTags =storeTags.filter((word) => word !== text);
    setStoreTags(remainTags);
    setDeletedTags([...deletedTags, text]);
    if(storeTags.length===1){
      setFieldValue("keywords","")
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    const clipboardData = e.clipboardData.getData("Text");
    const newTags = clipboardData.split(/,\s*/).map((Tag) => Tag.trim());
    if (newTags.length > 0) {
      const updatedTags = [...storeTags, ...newTags];
      setStoreTags(updatedTags);
      setFieldValue("keywords", updatedTags.join(", "));
    }
    e.preventDefault();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue("keywords",e.target.value);
    dispatch(setTags(e.target.value))
  };

  const isError =
    touched["keywords" as keyof typeof touched] &&
    !!errors["keywords" as keyof typeof errors];

  return (
    <Box>
      <Box height={"89%"}>
        <LabelTag label="Keywords from pump description" required={true} />
        <Box
          border={"1px solid #BBBDC0"}
          height={"100%"}
          padding={"12px 23px 12px 23px"}
          style={{ overflowY: "scroll" }}
        >
          <Box width={"100%"} display={"flex"} gap={"12px"} flexWrap={"wrap"}>
            {storeTags.map((text, index) => (
              <Box
                padding={"1px 1px 1px 18px"}
                alignItems={"center"}
                border={"1px solid #0078A1"}
                color={"#0078A1"}
                bgcolor={"#EFF9FD"}
                height={storeTags[0].length > 12 ? "40px" : "28px"}
                key={index}
                display={"flex"}
                justifyContent={"center"}
                borderRadius={"3px"}
              >
                <Typography variant="h6" fontSize={" 16px"}>
                  {text}
                </Typography>
                <Button
                  sx={{
                    padding: "0px",
                    width: "16px",
                    height: "16px",
                    fontWeight: "600",
                  }}
                  onClick={() => tagDeleteAction(text)}
                >
                  X
                </Button>
              </Box>
            ))}
            <TextField
              {...field}
              sx={{
                borderRadius: "3px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    border: "none",
                  },
                },
              }}
              type={"text"}
              fullWidth
              value={tags}
              error={isError}
              placeholder={t("Enter Keys")}
              helperText={
                isError ? t(errors["keywords" as keyof typeof errors]) : ""
              }
              onChange={handleChange}
              onKeyDown={addTag}
              onPaste={handlePaste}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default KeyWordsInput;
