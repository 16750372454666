import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { InputTag, LabelTag, PopOver, Subtitle } from "../../../../components";
import { Form, Formik, FormikState } from "formik";
import * as Yup from "yup";
import Logo from "../../../../assets/image/AssemblyArea/Group 9002.svg";
import TrolleyQuantityInput from "./TrolleyQuantityInput";
import KeyWordsInput from "./KeyWordsInput";
import { useTranslation } from "react-i18next";
import {
  AssemblyAreaType,
  setSelectedId,
  setTags,
  setValidationError,
} from "../../../../store/AssemblyAreaSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  addAssemblyAreaAction,
  getAssemblyAreaAction,
  updateAssemblyAreaAction,
} from "../../../../api/assemblyArea.service";
import AssemblyAreaControls from "./AssemblyAreaControls";
import AssemblyAreaSelectTag from "./AssemblyAreaSelectTag";

interface AssemblyAreaFormProps {
  setDeletedTags: React.Dispatch<React.SetStateAction<string[]>>;
  deletedTags: string[];
  setStoreTags: React.Dispatch<React.SetStateAction<string[]>>;
  storeTags: string[];
  initialData: AssemblyAreaType;
  setInitialData: React.Dispatch<React.SetStateAction<AssemblyAreaType>>;
  AssemblyAreaInitials: AssemblyAreaType;
}
const AssemblyAreaForm: React.FC<AssemblyAreaFormProps> = ({
  setDeletedTags,
  deletedTags,
  setStoreTags,
  storeTags,
  initialData,
  setInitialData,
  AssemblyAreaInitials,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState({ id: 0, message: "" });

  const { warehouses } = useAppSelector((store) => store.productionOrder);
  const { selectedId } = useAppSelector((state) => state.assemblyAreas);
  const { validationError } = useAppSelector((state) => state.assemblyAreas);

  const memoizedWarehouses = React.useMemo(() => warehouses, [warehouses]);

  const validationSchema = Yup.object().shape({
    assemblyLineName: Yup.string()
      .required("Assembly Line Name is required")
      .matches(
        /^[a-zA-Z0-9 _-]+$/,
        "Only alphanumeric characters, spaces, - and _ are allowed"
      ),
    trolleyQuantities: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.number()
          .required("Quantity is required")
          .min(0, "Quantity cannot be negative")
          .max(99, "Quantity cannot be greater than 99"),
      })
    ),
    workCenterType: Yup.string().required("Work Center Type is required"),
    keywords: Yup.string().when(storeTags, (Tags: string[], schema) => {
      if (storeTags && storeTags.length > 0) {
        return schema;
      } else {
        return schema
          .required("Keywords from pump description are required")
          .matches(
            /^[a-zA-Z0-9\s]*$/,
            "Keywords may only contain letters and numbers"
          );
      }
    }),
  });

  const addOrUpdateAssemblyArea = (
    assemblyAreaData: AssemblyAreaType,
    resetForm: (
      nextState?: Partial<FormikState<AssemblyAreaType>> | undefined
    ) => void
  ): void => {
    const afterAction = async (): Promise<void> => {
      setOpen(true);
      await dispatch(getAssemblyAreaAction());
      setStoreTags([]);
      setDeletedTags([]);
      dispatch(setValidationError({ ErrorCode: "", Message: "" }));
      resetForm();
    };
    const action =
      selectedId <= 0 ? addAssemblyAreaAction : updateAssemblyAreaAction;
    dispatch(action(assemblyAreaData)).then((res) => {
      if (res.payload) {
        if (selectedId > 0) {
          setPopUpMessage({
            id: 0,
            message: "Assembly Area Updated Successfully",
          });
          dispatch(setSelectedId(0));
          setInitialData(AssemblyAreaInitials);
        } else {
          setPopUpMessage({
            id: 0,
            message: "Assembly Area Added Successfully",
          });
          setInitialData(AssemblyAreaInitials);
        }
        afterAction();
      }
    });
    dispatch(setTags(""));
  };

  const clearCredentials = (): void => {
    dispatch(setValidationError({ ErrorCode: "", Message: "" }));
    dispatch(setSelectedId(0));
    setInitialData(AssemblyAreaInitials);
    setStoreTags([]);
    setDeletedTags([]);
    dispatch(setTags(""));
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        const inputKeywords = values.keywords;
        const individualKeywords = inputKeywords
          .split(",")
          .map((keyword) => keyword.trim());
        individualKeywords.forEach((keyword) => {
          if (!storeTags.includes(keyword) && !deletedTags.includes(keyword)) {
            storeTags.push(keyword);
          }
        });
        const assemblyAreaData: AssemblyAreaType = {
          ...values,
          keywords: storeTags.join(", "),
        };
        addOrUpdateAssemblyArea(assemblyAreaData, resetForm);
      }}
      enableReinitialize
    >
      {({ values, touched, errors }) => (
        <Box width={"100%"} m={"auto"} mt={"29px"}>
          <Subtitle value={"Assembly Area Details"} />
          <Form>
            <Box
              display={"grid"}
              gridTemplateColumns={"60% 35%"}
              width={"100%"}
              gap={"5%"}
            >
              <Box>
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexWrap={"wrap"}
                  flexShrink={0}
                  justifyContent={"space-between"}
                >
                  <Box>
                    <LabelTag label="Assembly Line Name" required={true} />
                    <InputTag
                      name={"assemblyLineName"}
                      width={window.innerWidth <= 1500 ? "400px" : "516px"}
                      type={"text"}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <LabelTag label="Work Center Type" required={true} />
                    <AssemblyAreaSelectTag touched={touched} errors={errors} />
                  </Box>
                </Box>
                {memoizedWarehouses.length !== 0 ? (
                  <TrolleyQuantityInput values={values} />
                ) : (
                  <Typography mt={"3%"}>
                    {t("No Warehouse defined.")}
                  </Typography>
                )}
              </Box>
              <KeyWordsInput
                deletedTags={deletedTags}
                setDeletedTags={setDeletedTags}
                setStoreTags={setStoreTags}
                storeTags={storeTags}
              />
            </Box>
            <Box mt={"37px"} width={"100%"} display={"flex"}>
              <Box m={"auto"} width={"50%"}>
                {validationError.ErrorCode && (
                  <Typography
                    ml={"10px"}
                    mt={"2px"}
                    variant="body1"
                    style={{ color: "red" }}
                  >
                    {t(validationError.Message)}
                  </Typography>
                )}
              </Box>
              <AssemblyAreaControls
                innerWidth={window.innerWidth}
                editId={selectedId}
                clearCredentials={clearCredentials}
              />
            </Box>
            <PopOver
              title={popUpMessage.message}
              logo={Logo}
              closePopOver={() => setOpen(false)}
              open={open}
              position={true}
            />
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default AssemblyAreaForm;
