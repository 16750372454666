import { useEffect, useState, useCallback } from "react";

interface BarcodeHookType {
  barcode: string;
  enterKey: boolean;
  handleResetEnterKey: () => void;
}

const useBarcodeScanner = (): BarcodeHookType => {
  const [barcode, setBarcode] = useState<string>("");
  const [scanValue, setScanValue] = useState<string>("");
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [enterKey, setEnterKey] = useState(false);

  const handleResetEnterKey = useCallback((): void => {
    setEnterKey(false);
  }, []);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (event.key === "Enter" || event.key === "Return") {
        const trimmedValue = scanValue.trim();
        setEnterKey(true);
        if (trimmedValue !== barcode) {
          setBarcode(trimmedValue);
        }
      } else if (/^[a-zA-Z0-9]$/.test(event.key)) {
        setScanValue((prevBarcode) => prevBarcode + String(event.key));
      }
      const newTimeoutId = setTimeout(() => {
        setScanValue("");
      }, 500);
      setTimeoutId(newTimeoutId);
    },
    [scanValue, timeoutId, barcode]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return { barcode, enterKey, handleResetEnterKey };
};

export default useBarcodeScanner;
