import { Typography, Button, Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeft } from "react-icons/bs";
import { GoPlusCircle } from "react-icons/go";
import { useNavigate } from "react-router";

interface PageTitleProps {
  title: string;
  showRedirectBackIcon?: boolean;
  redirectBackUrl?: string;
  showNavigationButton?: boolean;
  navigationUrl?: string;
  navigationButtonTitle?: string;
  action?: () => void;
}
const PageTitle: React.FC<PageTitleProps> = ({
  title,
  showRedirectBackIcon,
  redirectBackUrl,
  showNavigationButton,
  navigationUrl,
  navigationButtonTitle,
  action,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = (url: string): void => {
    action?.();
    navigate(url);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      height={"60px"}
      borderBottom="1px solid #BBBDC0"
      paddingLeft={5}
      boxShadow="7px 4px 9px 3px rgba(0, 0, 0, 0.03)"
      paddingRight={"47px"}
    >
      <Box flex={1} display="flex" gap={"21px"} alignItems="center">
        {showRedirectBackIcon && redirectBackUrl && (
          <BsArrowLeft
            color="black"
            size={"29px"}
            cursor={"pointer"}
            onClick={() => handleNavigation(redirectBackUrl)}
          />
        )}
        <Typography variant="h2">{t(title)}</Typography>
      </Box>
      {showNavigationButton && navigationUrl && navigationButtonTitle && (
        <Box height={"48px"}>
          <Button
            variant="outlined"
            startIcon={<GoPlusCircle size={"32px"} />}
            onClick={() => handleNavigation(navigationUrl)}
            sx={{
              padding: "0 30px",
            }}
          >
            {t(navigationButtonTitle)}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PageTitle;
