import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface Plant {
  id: string;
  name: string;
}

export interface PlantDetailsState {
  loading: boolean;
  error: boolean;
  plantDetails: Plant;
  plant: Plant;
}

const initialState: PlantDetailsState = {
  loading: false,
  error: false,
  plantDetails: { id: "", name: "" },
  plant: { id: "", name: "" },
};

export const plantDetailsSlice = createSlice({
  name: "plantDetails",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    getPlants: (state, action: PayloadAction<Plant>) => {
      state.plantDetails.id = action.payload.id;
      state.plantDetails.name = action.payload.name;
    },
    savePlant: (state, action: PayloadAction<Plant>) => {
      state.plant.id = action.payload.id;
      state.plant.name = action.payload.name;
    },
  },
});

export const { setLoading, setError, getPlants, savePlant } =
  plantDetailsSlice.actions;



export default plantDetailsSlice.reducer;
