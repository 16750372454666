import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      sx={{
        backgroundImage:
          "linear-gradient(to bottom right, #EADDD8, #D8E0E8, #D8E3D7, #E0E3DA)",
      }}
    >
      <Box
        width={"500px"}
        borderRadius={1}
        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
        bgcolor={"#FFF"}
        padding={5}
      >
        <Typography
          variant="h5"
          component={"h5"}
          fontWeight={"500"}
          fontSize={"17px"}
          mb={5}
          textAlign={"center"}
        >
          Sorry, this page not found.
        </Typography>
        <Button variant="contained" fullWidth onClick={() => navigate("/")}>
          Back to homepage
        </Button>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
