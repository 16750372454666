import React from "react";
import { BiSolidEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TableRow, TableCell, Box } from "@mui/material";
import { Warehouse } from "../../../../store/KitPickerPicklist/kitPickerPicklistModel";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

type WarehouseTableRowProps = {
  handleUpdateWarehouse: (details: Warehouse, index: number) => void;
  handleDeleteWarehouse: (id: number, index: number, warehouse: string) => void;
  index: number;
};
const WarehouseTableRow: React.FC<Warehouse & WarehouseTableRowProps> = ({
  id,
  name,
  partPickLocationMax,
  partPickLocationMin,
  artPlatz,
  handleUpdateWarehouse,
  handleDeleteWarehouse,
  index,
}) => {
  const { activeRowIndex } = useSelector(
    (store: RootState) => store.warehouseDetails
  );
  const isRowSelected = index === activeRowIndex;
  const highlightStyle = {
    fontWeight: isRowSelected ? "bold" : "400",
    backgroundColor: isRowSelected ? "#E5FFCC" : "#fff",
  };
  const onUpdateClick = (): void => {
    handleUpdateWarehouse(
      {
        id,
        name,
        partPickLocationMax,
        partPickLocationMin,
        artPlatz,
      },
      index
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const onDeleteClick = (): void => {
    handleDeleteWarehouse(id, index, name);
  };
  return (
    <>
      <TableRow>
        <TableCell sx={highlightStyle}>{name}</TableCell>
        <TableCell sx={highlightStyle}>
          {partPickLocationMin ?? "---"}
        </TableCell>
        <TableCell sx={highlightStyle}>
          {partPickLocationMax ?? "---"}
        </TableCell>
        <TableCell sx={highlightStyle}>{artPlatz ?? "---"}</TableCell>
        <TableCell sx={highlightStyle}>
          <Box display={"flex"} gap={"30px"} justifyContent={"center"}>
            <BiSolidEdit
              size={"18px"}
              color="#0078a1"
              cursor={"pointer"}
              onClick={onUpdateClick}
            />
            <RiDeleteBin6Line
              size={"18px"}
              color="red"
              cursor={"pointer"}
              onClick={onDeleteClick}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default WarehouseTableRow;
