import { KitPickerPicklist } from "../../containers";
import { RouteType } from ".";

export const KitPickerRoutes: RouteType[] = [
  {
    path: "/",
    name: "Kit Picker PickList",
    activeIndex: 0,
    Component: KitPickerPicklist,
  },
];
