import { PayloadAction, createSlice } from "@reduxjs/toolkit";


export interface  TrolleysType{
  trolleyBarcode: string,
  trolleyStatus: string,
  needRework:boolean
  productionOrder:string,
  orderDate: string,
  orderTime: string,
  assemblyArea: string,
  warehouse: string
}
export interface AllAssemblyLineType{
  assemblyLineId: number,
  assemblyLineName: string,
  workCenterTypeId: number,
  workCenterType: string,
  keywords: string,
  trolleyQuantities: []
}

export interface KitStatusType {
  loading: boolean;
  error: boolean;
  readyKits: TrolleysType[];
  emptyKits:TrolleysType[];
  allAssemblyLines:AllAssemblyLineType[]
}

const initialState: KitStatusType = {
  loading: false,
  error: false,
  readyKits:[],
  emptyKits:[],
  allAssemblyLines:[]
};

export const kitStatusSlice = createSlice({
  name: "kitStatus",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    getReadyKits: (state, action: PayloadAction<TrolleysType[]>) => {
        state.loading = false;
        state.readyKits = action.payload;
    },
    getEmptyKits: (state, action: PayloadAction<TrolleysType[]>) => {
        state.loading = false;
        state.emptyKits = action.payload;
    },
    getAllAssemblyLines: (state, action: PayloadAction<AllAssemblyLineType[]>) => {
        state.loading = false;
        state.allAssemblyLines = action.payload;
    }
  },
});

export const { setLoading, setError, getReadyKits, getEmptyKits, getAllAssemblyLines} =
  kitStatusSlice.actions;



export default kitStatusSlice.reducer;
