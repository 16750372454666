import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchLogo } from "../assets/image/Admin";

interface SearchInputTagPropsTypes {
  onSearch: (searchValue: string) => void;
}

const SearchInputTag: React.FC<SearchInputTagPropsTypes> = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSearchValue(value);
  };

  useEffect(() => {
    let debouncer: NodeJS.Timeout;
    if (searchValue === "") {
      onSearch("");
    } else {
      debouncer = setTimeout(() => {
        onSearch(searchValue);
      }, 1000);
    }

    return () => clearTimeout(debouncer);
  }, [searchValue, onSearch]);

  return (
    <TextField
      placeholder={t("Search")}
      fullWidth
      value={searchValue}
      sx={{ height: "40px" }}
      InputProps={{
        style: { height: "40px" },
        startAdornment: (
          <InputAdornment position="start">
            <img src={SearchLogo} alt="" width={"15px"} height={"15px"} />
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
    />
  );
};

export default SearchInputTag;
