import React from "react";
import { TableRow, TableHead, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Warehouse } from "../../../../store/KitPickerPicklist/kitPickerPicklistModel";

interface AssemblyAreaTableHeadProps {
  memoizedWarehouses: Warehouse[];
}
const AssemblyAreaTableHead: React.FC<AssemblyAreaTableHeadProps> = ({
  memoizedWarehouses,
}) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ padding: "5px", height: "50px" }} rowSpan={2}>
          {t("Assembly Line Name")}
        </TableCell>
        <TableCell sx={{ padding: "5px", height: "50px" }} rowSpan={2}>
          {t("Work Center Type")}
        </TableCell>
        <TableCell
          sx={{ textAlign: "center", height: "50px", padding: "5px" }}
          colSpan={memoizedWarehouses.length}
        >
          {t("Trolley Quantity")}
        </TableCell>
        <TableCell sx={{ padding: "5px", height: "50px" }} rowSpan={2}>
          {t("Keywords from pump description")}
        </TableCell>
        <TableCell
          rowSpan={2}
          sx={{ textAlign: "center", height: "50px", padding: "5px" }}
        >
          {t("Action")}
        </TableCell>
      </TableRow>
      <TableRow>
        {memoizedWarehouses.map((warehouse) => (
          <TableCell
            sx={{
              padding: "0px 8px 0px 8px",
              textAlign: "center",
              height: "50px",
            }}
            key={warehouse.id}
          >
            {warehouse.name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default AssemblyAreaTableHead;
