import React from "react";
import { Modal, Box, Stack, Typography, MenuItem, Button } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { LabelTag } from ".";
import { useTranslation } from "react-i18next";

export interface OptionTypes {
  id: number | string;
  optionName: string;
  workCenterType?: string;
}

interface SelectTagPopOverPropsTypes {
  currentValue: number | string;
  optionList: OptionTypes[];
  heading: string;
  inputLable: string;
  open: boolean;
  onSelectChange: (event: SelectChangeEvent) => void;
  onProceedButton: () => void;
}

const SelectTagPopOver: React.FC<SelectTagPopOverPropsTypes> = (props) => {
  const {
    currentValue,
    optionList,
    inputLable,
    heading,
    open,
    onSelectChange,
    onProceedButton,
  } = props;
  const { t } = useTranslation();

  return (
    <Modal open={open} disableAutoFocus disableEnforceFocus disableRestoreFocus>
      <Stack
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        p={"16px 26px"}
        boxShadow="0px 3px 17px 0px rgba(0, 0, 0, 0.13)"
        bgcolor={"#FFF"}
        borderRadius={"3px"}
        width={"473px"}
        height={"391px"}
        className="custom-transform-element "
      >
        <Box display={"flex"} mb={"19px"}>
          <Typography variant="h5" lineHeight={"30px"} color={"#0D0D0D"}>
            {t(heading)}
          </Typography>
        </Box>
        <LabelTag label={t(inputLable)} required={true} />
        <Select
          value={String(currentValue)}
          onChange={onSelectChange}
          fullWidth
          color="primary"
        >
          {optionList?.map((option: OptionTypes) => (
            <MenuItem value={String(option.id)} key={option.id}>
              {option.optionName}
            </MenuItem>
          ))}
        </Select>
        <Box display={"flex"} justifyContent={"center"} mt={"auto"} mb="49px">
          <Button
            variant="contained"
            onClick={onProceedButton}
            disabled={String(currentValue) === ""}
          >
            {t("Proceed")}
          </Button>
        </Box>
      </Stack>
    </Modal>
  );
};

export default SelectTagPopOver;
