import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { ValidationError } from "./warehouseSlice";

export type User = {
  id: number;
  name: string;
  email: string;
  isActive: boolean;
  userRole: string;
  language: string;
  createdBy?: number;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
  inputDirectories?: [];
};

export type FormState = {
  id: number;
  name: string;
  email: string;
  userRole: string;
  isActive: boolean;
  createAction: boolean;
  language: string;
};

export type UserState = {
  loading: boolean;
  error: boolean;
  userList: User[];
  formState: FormState;
  userValidations: ValidationError;
};
const initialState: UserState = {
  loading: false,
  error: false,
  userList: [],
  formState: {
    id: 0,
    name: "",
    email: "",
    createAction: true,
    userRole: "",
    isActive: false,
    language: "",
  },
  userValidations: { ErrorCode: "", Message: "" },
};

export const userListSlice = createSlice({
  name: "userList",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setUserList: (state, action: PayloadAction<User[]>) => {
      state.loading = false;
      state.userList = action.payload;
    },
    setStateForCreateUser: (state) => {
      state.formState = {
        id: 0,
        name: "",
        email: "",
        userRole: "",
        createAction: true,
        isActive: false,
        language: "",
      };
    },
    setStateForUpdateUser: (state, action: PayloadAction<FormState>) => {
      state.formState = {
        ...action.payload,
        createAction: false,
      };
    },
    setUserValidations: (state, action: PayloadAction<ValidationError>) => {
      state.userValidations = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setUserList,
  setStateForCreateUser,
  setStateForUpdateUser,
  setUserValidations,
} = userListSlice.actions;

export default userListSlice.reducer;
