import React from "react";
import { Stack, Typography, Tooltip, IconButton } from "@mui/material";
import warningLogo from "../../assets/image/AssemblyUser/KitCardLogo.svg";
import warningLogoBlack from "../../assets/image/AssemblyUser/WarningLogoBlack.svg";
import { BsCheckCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { OptionTypes } from "../../components/SelectTagPopOver";
import ReworkIcon from "./ReworkIcon";

interface FilledTrolleyCardPropsType {
  trolleyBarcode: string;
  status: string;
  productionOrder: string;
  assemblyLine: OptionTypes;
  onUpdateTrolley: (
    trolleyBarcode: string,
    status: string,
    roductionOrder: string
  ) => void;
  onSendTrolleyRework: (
    trolleyBarcode: string,
    status: string,
    prodctionOrder: string
  ) => void;
}

const KitCard: React.FC<FilledTrolleyCardPropsType> = ({
  trolleyBarcode,
  status,
  productionOrder,
  assemblyLine,
  onUpdateTrolley,
  onSendTrolleyRework,
}) => {
  const { t } = useTranslation();
  const isRework = status === "rework";
  const isProduction = status === "production";

  return (
    <>
      <Stack
        bgcolor={isRework ? "#E6E6E6" : "#EFF9FD"}
        height="110px"
        padding={isRework ? "4px 9px 9px 9px" : "9px"}
        borderRadius="4px"
        flexShrink={0}
        gap={1}
        border={isRework || isProduction ? "3px solid #0078A1" : ""}
        onClick={() => onUpdateTrolley(trolleyBarcode, status, productionOrder)}
        onTouchStart={() =>
          onUpdateTrolley(trolleyBarcode, status, productionOrder)
        }
        className={!isRework ? "custom-cursor-element" : ""}
        id={trolleyBarcode}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          height="100%"
          gap={"10px"}
        >
          <Stack direction="column">
            <Typography
              variant="h5"
              color={isRework ? "#9F9F9F" : ""}
              className={!isRework ? "custom-cursor-element" : ""}
            >
              {t("Trolley ID")}
            </Typography>
            <Typography
              variant="caption"
              fontSize="18px"
              color={isRework ? "#9F9F9F" : ""}
              mt={"2px"}
            >
              {trolleyBarcode}
            </Typography>
          </Stack>
          <Stack
            justifyContent="space-between"
            alignItems="flex-end"
            height="100%"
          >
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <img
                src={isRework ? warningLogoBlack : warningLogo}
                alt=""
                width="21px"
                height="18px"
              />
              {assemblyLine.workCenterType !== "Rework" && (
                <Tooltip title="Rework" placement="right">
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSendTrolleyRework(
                        trolleyBarcode,
                        status,
                        productionOrder
                      );
                    }}
                    onTouchStart={(e) => {
                      e.stopPropagation();
                      onSendTrolleyRework(
                        trolleyBarcode,
                        status,
                        productionOrder
                      );
                    }}
                  >
                    <ReworkIcon
                      color={isProduction ? "#0078A1" : "#C9C9C9"}
                      width="40px"
                      height="40px"
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            {isProduction && <BsCheckCircleFill color="#43B02A" size="30px" />}
          </Stack>
        </Stack>
        {isRework && (
          <Typography
            variant="h5"
            fontSize="22px"
            lineHeight="22px"
            mt="-10px"
            textAlign="center"
          >
            {t("Rework Area")}
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default KitCard;
