import React from "react";
import { Box, TableContainer, Table } from "@mui/material";
import { LabelTag } from "../../../../components";
import { AssemblyAreaType } from "../../../../store/AssemblyAreaSlice";
import TrolleyQuantityInputTableHead from "./TrolleyQuantityInputTableHead";
import TrolleyQuantityInputTableBody from "./TrolleyQuantityInputTableBody";

interface TrolleyQuantityInputProps {
  values: AssemblyAreaType;
}

const TrolleyQuantityInput: React.FC<TrolleyQuantityInputProps> = ({
  values,
}) => {
  return (
    <Box mt="1%" width="100%">
      <LabelTag label="Trolley Quantity" required={true} />
      <TableContainer
        sx={{
          maxWidth:
            innerWidth <= 1500 ? "calc(100vw - 502px)" : "calc(100vw - 735px)",
        }}
      >
        <Table>
          <TrolleyQuantityInputTableHead />
          <TrolleyQuantityInputTableBody values={values} />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TrolleyQuantityInput;
