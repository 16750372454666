import { MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import { updateUserLanguage } from "../../api/user.service";
import { useAppDispatch, useAppSelector } from "../../store";

const LocaleSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector((store) => store.user);

  const selectTagStyle = {
    color: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  };

  const handleLocaleChange = (event: SelectChangeEvent): void => {
    const language = event.target.value as string;
    i18n.changeLanguage(language);
    dispatch(updateUserLanguage({ email: userDetails.email, language }));
  };

  return (
    <Select
      value={i18n.language}
      autoWidth
      onChange={handleLocaleChange}
      sx={selectTagStyle}
    >
      <MenuItem value={"en-US"}>{t("English")}</MenuItem>
      <MenuItem value={"de-DE"}>{t("German")}</MenuItem>
    </Select>
  );
};

export default LocaleSwitcher;
