import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FormInitialState {
  name: string;
  partPickLocationMin?: string;
  partPickLocationMax?: string;
  artPlatz?: string;
}

export interface ValidationError {
  ErrorCode: string;
  Message: string;
}
interface InitialState {
  loading: boolean;
  error: boolean;
  initialStateForLocation: FormInitialState;
  isAddWarehouseAction: boolean;
  warehouseIdForUpdate: number;
  activeRowIndex: number;
  validationError: ValidationError;
}

const initialState: InitialState = {
  loading: false,
  error: false,
  initialStateForLocation: {
    name: "",
    partPickLocationMin: "",
    partPickLocationMax: "",
    artPlatz: "",
  },
  isAddWarehouseAction: true,
  warehouseIdForUpdate: 0,
  activeRowIndex: -1,
  validationError: {
    ErrorCode: "",
    Message: "",
  },
};

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setFromStateForUpdate: (state, action: PayloadAction<FormInitialState>) => {
      const { name, partPickLocationMin, partPickLocationMax, artPlatz } =
        action.payload;
      state.initialStateForLocation.name = name;
      if (artPlatz) {
        state.initialStateForLocation.artPlatz = artPlatz;
        state.initialStateForLocation.partPickLocationMin = "";
        state.initialStateForLocation.partPickLocationMax = "";
      }
      if (partPickLocationMin && partPickLocationMax) {
        state.initialStateForLocation.partPickLocationMin = partPickLocationMin;
        state.initialStateForLocation.partPickLocationMax = partPickLocationMax;
        state.initialStateForLocation.artPlatz = "";
      }
    },
    setDefaultFormState: (state) => {
      state.initialStateForLocation = initialState.initialStateForLocation;
    },
    setWarehouseName: (state, action: PayloadAction<string>) => {
      state.initialStateForLocation.name = action.payload;
    },
    setAddWarehouseAction: (state, action: PayloadAction<boolean>) => {
      state.isAddWarehouseAction = action.payload;
    },
    setUpdateWarehouseId: (state, action: PayloadAction<number>) => {
      state.warehouseIdForUpdate = action.payload;
    },
    setActiveRowIndex: (state, action: PayloadAction<number>) => {
      state.activeRowIndex = action.payload;
    },
    setValidationError: (state, action: PayloadAction<ValidationError>) => {
      state.validationError = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setFromStateForUpdate,
  setDefaultFormState,
  setWarehouseName,
  setAddWarehouseAction,
  setUpdateWarehouseId,
  setActiveRowIndex,
  setValidationError,
} = warehouseSlice.actions;

export default warehouseSlice.reducer;
