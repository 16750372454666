import React from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RiDeleteBinLine } from "react-icons/ri";

interface ConfirmationProps {
  title: string;
  message: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const Confirmation: React.FC<ConfirmationProps> = ({
  title,
  message,
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal open={open}>
        <Box
          width={"auto"}
          borderRadius={"15px"}
          bgcolor={"#FFF"}
          boxShadow={"0px 4px 22px 0px rgba(0, 0, 0, 0.15)"}
          position={"absolute"}
          top={"50%"}
          left={"50%"}
          padding={"35px"}
          className="custom-transform-element"
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" flexDirection="column">
            <Box
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              gap={"20px"}
              mb={3}
            >
              <RiDeleteBinLine fontSize={"70px"} color="red" />
              <Typography
                fontFamily={"Segoe UI"}
                textAlign={"center"}
                variant="h3"
                fontSize={"30px"}
              >
                {t(title)}
              </Typography>
              <Typography
                fontFamily={"Segoe UI"}
                textAlign={"center"}
                variant="h6"
                width={"400px"}
              >
                {t(message)}
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"} gap={"27px"} justifyContent={"center"}>
            <Button size="small" variant="outlined" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={onConfirm}
              sx={{
                bgcolor: "#FF313A",
                "&:hover": {
                  bgcolor: "#FF0000",
                },
              }}
            >
              {t("Delete")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Confirmation;
