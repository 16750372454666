import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import OrderPool from "./OrderPool";
import WorkCenterType from "./WorkCenterType";
import TrolleyStatus from "./TrolleyStatus";
import { Navbar, NavigationTabs } from "../../../components";
import WarehouseDetails from "./WarehouseDetails";
import AssemblyArea from "./AssemblyArea";
//import InputDirectory from "./InputDirectory";

const tabs = [
  { label: "Order Pool", component: <OrderPool /> },
  { label: "Trolley Status", component: <TrolleyStatus /> },
  //{ label: "Input Directory", component: <InputDirectory/> },
  { label: "Warehouse Details", component: <WarehouseDetails /> },
  { label: "Work Center Type", component: <WorkCenterType /> },
  { label: "Assembly Area Details", component: <AssemblyArea /> },
];

const KittingProcess: React.FC = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setValue(newValue);
  };

  return (
    <Box>
      <Navbar />
      <NavigationTabs />
      <Box
        borderBottom={1}
        borderColor="#BBBDC0"
        boxShadow="7px 4px 9px 3px rgba(0, 0, 0, 0.03)"
        display="flex"
        justifyContent="center"
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          sx={{
            "& .MuiTabs-indicator": {
              display: "none",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              sx={{
                backgroundColor: value === index ? "white" : "#F3F3F3",
                borderRight:
                  index == tabs.length - 1 ? "1px solid #BBBDC0" : "none",
                borderLeft: "1px solid #BBBDC0",
              }}
              label={t(tab.label)}
              disableRipple
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <Box key={index}>{value === index && tab.component}</Box>
      ))}
    </Box>
  );
};

export default KittingProcess;
