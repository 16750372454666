import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ValidationError } from "./warehouseSlice";

export interface AddTrolleyQuantitiesType{
  quantity:number|string|null;
  warehouseId:number|null
}
export interface AssemblyAreaType{
  assemblyLineName:string;
  keywords:string;
  trolleyQuantities:AddTrolleyQuantitiesType[];
  workCenterType:string;
  workCenterTypeId:number|null;
}
export interface GetTrolleyQuantitiesTypes{
  assemblyLineId: number,
  warehouseId: number,
  quantity: number|string|null,
  assemblyLine: null,
  warehouse: null
}
export interface AssemblyAreasType{
  assemblyLineId:number|null
  assemblyLineName:string;
  keywords:string;
  trolleyQuantities:GetTrolleyQuantitiesTypes[];
  workCenterType:string;
  workCenterTypeId:number|null;
}

  export interface InitialStateTypes { 
    loading: boolean;
    error: boolean;
    assemblyArea:AssemblyAreaType;
    assemblyAreas:AssemblyAreasType[]
    validationError: ValidationError;
    selectedId:number,
    tags :string,
  }


  const initialState:InitialStateTypes = {
    loading: false,
    error: false,
    assemblyArea:{
      assemblyLineName: "",
      keywords: "",
      trolleyQuantities:[],
      workCenterType: "",
      workCenterTypeId:null
    },
    assemblyAreas:[],
    validationError: {
      ErrorCode: "",
      Message: "",
    },
    selectedId:0,
    tags :""
  };
export const assemblyAreaSlice = createSlice({
    name: "assemblyArea",
    initialState,
    reducers: {
      setLoading: (state) => {
        state.loading = true;
      },
      setError: (state) => {
        state.error = true;
        state.loading = false;
      },
      addAssemblyArea: (state, action: PayloadAction<AssemblyAreaType>) => {
          state.error=false;
          state.loading=false;
          state.assemblyArea=action.payload
        },
      getAssemblyAreas:(state, action: PayloadAction<AssemblyAreasType[]>) => {
        state.loading=false;
        state.assemblyAreas=action.payload
      },
      setSelectedId: (state, action: PayloadAction<number>) => {
        state.selectedId = action.payload;
      },
      setTags: (state, action: PayloadAction<string>) => {
        state.tags = action.payload;
      },
      setValidationError: (state, action: PayloadAction<ValidationError>) => {
        state.validationError = action.payload;
      },
    },
  });
  
  export const {
    setLoading,
    setError,
    addAssemblyArea,
    getAssemblyAreas,
    setValidationError,
    setSelectedId,
    setTags
  } = assemblyAreaSlice.actions;
  
  
  
  export default assemblyAreaSlice.reducer;