import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface AssemblyLineType {
  assemblyLineId: number;
  assemblyLineName: string;
  workCenterTypeId: number;
  keywords: string;
  createdBy: number;
  createdOn: string;
  updatedBy: number;
  updatedOn: string;
  orderComponents: [];
  trolleys: [];
  workCenterType: null;
}

export interface TrolleyType {
  trolleyBarcode: string;
  trolleyStatus: string;
  productionOrder: string;
  orderDate: string;
  orderTime: string;
  assemblyArea: string;
  warehouse: string;
}

interface ReportTrolleyState {
  loading: boolean;
  error: string;
  assemblyLineList: AssemblyLineType[];
  trolleyList: TrolleyType[];
  activeProductionOrderConfirmFilledTab: string;
  activeProductionOrderEmptyTab: string;
  updateTrolleyInProduction: boolean;
  updateTrolleyEmptied: boolean;
}

const initialState: ReportTrolleyState = {
  loading: false,
  error: "",
  assemblyLineList: [],
  trolleyList: [],
  activeProductionOrderConfirmFilledTab: "",
  activeProductionOrderEmptyTab: "",
  updateTrolleyInProduction: false,
  updateTrolleyEmptied: false,
};

export const reportTrolleySlice = createSlice({
  name: "reportTrolley",
  initialState,
  reducers: {
    reportTrolleyLoading: (state) => {
      state.loading = true;
    },
    reportTrolleyError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    assemblyLineList: (state, action) => {
      state.loading = false;
      state.assemblyLineList = action.payload;
    },
    getTrolleyList: (state, action) => {
      state.loading = false;
      state.trolleyList = action.payload;
    },
    updateTrolleyInProduction: (state) => {
      state.loading = false;
      state.updateTrolleyInProduction = !state.updateTrolleyInProduction;
    },
    updateTrolleyEmptied: (state) => {
      state.loading = false;
      state.updateTrolleyEmptied = !state.updateTrolleyEmptied;
    },
    updateActiveProdutionOrder: (
      state,
      action: PayloadAction<{ tab: string; productionOrder: string }>
    ) => {
      if (action.payload.tab === "Confirm Filled") {
        state.activeProductionOrderConfirmFilledTab =
          action.payload.productionOrder;
      } else if (action.payload.tab === "Empty") {
        state.activeProductionOrderEmptyTab = action.payload.productionOrder;
      }
    },
  },
});

export const {
  reportTrolleyLoading,
  assemblyLineList,
  reportTrolleyError,
  getTrolleyList,
  updateTrolleyInProduction,
  updateTrolleyEmptied,
  updateActiveProdutionOrder,
} = reportTrolleySlice.actions;

export default reportTrolleySlice.reducer;
