import React from "react";
import { TableRow, TableHead, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";

interface UserTableHeadProps {
  showSrNumbers: boolean;
  showRoles: boolean;
}
const UserTableHead: React.FC<UserTableHeadProps> = ({
  showSrNumbers,
  showRoles,
}) => {
  const { t } = useTranslation();
  
  return (
    <>
      <TableHead>
        <TableRow>
          {showSrNumbers && <TableCell>{t("Serial Number")}</TableCell>}
          <TableCell>{t("Name")}</TableCell>
          <TableCell>{t("Email Id")}</TableCell>
          {showRoles && <TableCell>{t("Role")}</TableCell>}
          <TableCell>{t("Status")}</TableCell>
          <TableCell align="center">{t("Action")}</TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export default UserTableHead;
