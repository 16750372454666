import React, { useEffect, useState, useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import { RootState, useAppDispatch } from "../../store";
import {
  deliveredTrolleysAtAssemblyLine,
  getAllAssemblyLinesAction,
  getReadyKitsGroupByAssemblyLine,
  pikedByMilkRunTrolley,
  undoPikedByMilkRunReadyKitTrolley,
} from "../../api/kitStatus.service";
import { useSelector } from "react-redux";
import KitLogo from "../../assets/image/KitStatus/Group 9002.svg";
import { TrolleysType } from "../../store/kitStatusSlice";
import { useTranslation } from "react-i18next";
import KitCardList from "./KitCardList";
import KitCard from "./KitCard";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import i18n from "i18next";
import { speakText } from "../../utils/speakText";
import { enqueueSnackbar } from "notistack";
import { Loader, PopOver } from "../../components";

const ReadyKit: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enterKey, barcode, handleResetEnterKey } = useBarcodeScanner();
  const currentLanguage = i18n.language;
  const [initialLoading, setInitialLoading] = useState(true);

  const checkTrolleyByClick = (
    barCode: string,
    rework: boolean,
    status: string
  ): void => {
    if (status === "filled" || status === "rework") {
      dispatch(pikedByMilkRunTrolley({ barCode, rework })).then(
        (res) => res.payload && dispatch(getReadyKitsGroupByAssemblyLine())
      );
    } else if (status === "picked") {
      dispatch(undoPikedByMilkRunReadyKitTrolley(barCode)).then(
        (res) => res.payload && dispatch(getReadyKitsGroupByAssemblyLine())
      );
    }
  };

  const { readyKits, allAssemblyLines } = useSelector(
    (state: RootState) => state.kitStatus
  );
  const needReWorkData = readyKits.filter((item) => item.needRework === true);

  const groupedData = readyKits.reduce((map, item) => {
    const assemblyArea = item.assemblyArea;
    if (!map.has(assemblyArea)) {
      map.set(assemblyArea, []);
    }
    map.get(assemblyArea)?.push(item);
    return map;
  }, new Map<string, TrolleysType[]>());

  const groupedDataObject: Record<string, TrolleysType[]> =
    Object.fromEntries(groupedData);

  const groupedDataArray = Object.entries(groupedDataObject)
    .map(([key, val]) => ({
      assemblyArea: key,
      trolleyList: val,
    }))
    .sort((a, b) => a.assemblyArea.localeCompare(b.assemblyArea));

  const scanBarcodeAssemblyUser = useCallback(() => {
    if (enterKey) {
      handleResetEnterKey();
      let notFoundMessage = true;

      groupedDataArray?.map((order) =>
        order?.trolleyList?.map(({ trolleyBarcode, needRework }) => {
          {
            if (trolleyBarcode === barcode) {
              const section = document.getElementById(trolleyBarcode);
              if (section) {
                section.scrollIntoView({ behavior: "smooth", block: "center" });
              }
              dispatch(
                pikedByMilkRunTrolley({
                  barCode: trolleyBarcode,
                  rework: needRework,
                })
              ).then(
                (res) =>
                  res.payload && dispatch(getReadyKitsGroupByAssemblyLine())
              );

              notFoundMessage = false;
            }
          }
        })
      );

      if (notFoundMessage) {
        speakText(
          `${t("Unable to find the trolley")} ${barcode}`,
          currentLanguage
        );
        enqueueSnackbar(`${t("Unable to find the trolley")} ${barcode}`);
      }
    }
  }, [
    enterKey,
    barcode,
    groupedDataArray,
    t,
    currentLanguage,
    dispatch,
    handleResetEnterKey,
  ]);

  useEffect(() => {
    scanBarcodeAssemblyUser();
  }, [scanBarcodeAssemblyUser]);

  useEffect(() => {
    dispatch(getReadyKitsGroupByAssemblyLine()).then(() => {
      setInitialLoading(false);
    });
    dispatch(getAllAssemblyLinesAction());

    const refreshInterval = setInterval(() => {
      dispatch(getReadyKitsGroupByAssemblyLine());
      dispatch(getAllAssemblyLinesAction());
    }, 60 * 1000);

    return () => clearInterval(refreshInterval);
  }, [dispatch]);

  const reworkData = allAssemblyLines.filter(
    (item) => item.workCenterType === "Rework"
  );
  const assemblyLineNames = reworkData
    .map((item) => item.assemblyLineName)
    .join(", ");

  const confirmDelivery = (assemblyArea: string, rework: boolean): void => {
    if (assemblyArea === "") {
      const filteredData = readyKits.filter(
        (item) => item.trolleyStatus === "picked" && item.needRework === rework
      );
      const deliverTrolleys = filteredData.map((item) => item.trolleyBarcode);
      dispatch(deliveredTrolleysAtAssemblyLine(deliverTrolleys)).then((res) => {
        if (res.payload) {
          setOpen(true);
          dispatch(getReadyKitsGroupByAssemblyLine());
        }
      });
    } else {
      const filteredData = readyKits.filter(
        (item) =>
          item.trolleyStatus === "picked" &&
          item.needRework === rework &&
          item.assemblyArea === assemblyArea
      );
      const deliverTrolleys = filteredData.map((item) => item.trolleyBarcode);
      dispatch(deliveredTrolleysAtAssemblyLine(deliverTrolleys)).then((res) => {
        if (res.payload) {
          setOpen(true);
          dispatch(getReadyKitsGroupByAssemblyLine());
        }
      });
    }
  };

  return (
    <>
      <Box mt={"32px"} sx={{ p: 3 }}>
        {initialLoading && <Loader />}
        {!initialLoading && groupedDataArray.length !== 0 && (
          <Typography variant="h5">{t("Delivery Line Name")}</Typography>
        )}
        <Box mt={"24px"}>
          {!initialLoading && groupedDataArray.length === 0 && (
            <Typography variant="h5" textAlign="center">
              {t("There are no trolleys available for delivery.")}
            </Typography>
          )}
          {!initialLoading &&
            groupedDataArray.length !== 0 &&
            groupedDataArray.map(({ assemblyArea, trolleyList }) => {
              const filteredItems = trolleyList.filter(
                (item) => !item.needRework
              );
              const allItemsPicked: boolean = filteredItems.every(
                (item) =>
                  item.trolleyStatus !== "picked" && item.needRework === false
              );
              if (filteredItems.length === 0) {
                return null;
              }
              return (
                <Box key={assemblyArea}>
                  <Box>
                    <Typography>{assemblyArea}</Typography>
                    <Box display={"flex"}>
                      <KitCardList
                        items={filteredItems}
                        assemblyArea={assemblyArea}
                        checkTrolleyByClick={checkTrolleyByClick}
                      />
                      <Box
                        width={"15%"}
                        m={"auto"}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        textAlign={"center"}
                        p={"0px 10px 0px 10px"}
                      >
                        <Box width={"70%"} m={"auto"}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => confirmDelivery(assemblyArea, false)}
                            disabled={allItemsPicked}
                          >
                            {t("Confirm Delivery")}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Box>
        {!initialLoading && readyKits.length !== 0 && (
          <Box mt={"24px"}>
            {readyKits.some((item) => item.needRework === true) && (
              <Typography>{assemblyLineNames}</Typography>
            )}
            <Box display={"flex"}>
              <Box
                width={"85%"}
                mt="12px"
                display={"grid"}
                gap={"17px"}
                gridTemplateColumns={"repeat(10, 1fr)"}
              >
                {needReWorkData
                  .filter((element) => element.needRework === true)
                  .map((element) => (
                    <KitCard
                      key={element.trolleyBarcode}
                      assemblyArea={element.assemblyArea}
                      trolleyBarcode={element.trolleyBarcode}
                      trolleyStatus={element.trolleyStatus}
                      rework={element.needRework}
                      checkTrolleyByClick={checkTrolleyByClick}
                    />
                  ))}
              </Box>
              <Box
                width={"15%"}
                m={"auto"}
                display={"flex"}
                justifyContent={"flex-end"}
                textAlign={"center"}
                p={"0px 10px 0px 10px"}
              >
                <Box width={"70%"} m={"auto"}>
                  {readyKits.some((element) => element.needRework === true) && (
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => confirmDelivery("", true)}
                      disabled={needReWorkData.every(
                        (item) =>
                          item.trolleyStatus !== "picked" &&
                          item.needRework === true
                      )}
                    >
                      {t("Confirm Delivery")}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <PopOver
        title={"Kit delivery confirmed Successfully!"}
        logo={KitLogo}
        closePopOver={() => setOpen(false)}
        open={open}
        position={false}
      />
    </>
  );
};

export default ReadyKit;
