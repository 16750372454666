import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./base.service";
import {
  getAllAssemblyLines,
  getEmptyKits,
  getReadyKits,
  setError,
  setLoading,
} from "../store/kitStatusSlice";

export const getReadyKitsGroupByAssemblyLine = createAsyncThunk(
  "kitStatus/getReadyKits",
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await axiosInstance.get(
        "api/trollies/getAll?statusList=picked&statusList=filled&statusList=rework&isEmpty=False"
      );
      const data = res.data;
      dispatch(getReadyKits(data));
      return true;
    } catch (error) {
      dispatch(setError());
      return false;
    }
  }
);

export const getAllAssemblyLinesAction = createAsyncThunk(
  "kitStatus/getReadyKits",
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await axiosInstance.get("api/AssemblyLines");
      const data = res.data;
      dispatch(getAllAssemblyLines(data));
      return true;
    } catch (error) {
      dispatch(setError());
      return false;
    }
  }
);

export const getEmptyKitsGroupByKitType = createAsyncThunk(
  "kitStatus/getEmptyKits",
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await axiosInstance.get(
        "api/trollies/getAll?statusList=picked&statusList=emptied&isEmpty=True"
      );
      const data = res.data;
      dispatch(getEmptyKits(data));
      return true;
    } catch (error) {
      dispatch(setError());
      return false;
    }
  }
);

export const pikedByMilkRunTrolley = createAsyncThunk(
  "kitStatus/updateTrolley",
  async (
    { barCode, rework }: { barCode: string; rework: boolean },
    { dispatch }
  ) => {
    try {
      await axiosInstance.put(
        `api/trollies/${barCode}/PickedByMilkRun?needRework=${rework}`,
        {
          trolleyStatus: "picked",
        }
      );
      return true;
    } catch (error) {
      dispatch(setError());
      return false;
    }
  }
);

export const undoPikedByMilkRunReadyKitTrolley = createAsyncThunk(
  "kitStatus/undoReadyPickedKit",
  async (barcode: string, { dispatch }) => {
    try {
      await axiosInstance.put(`api/Trollies/${barcode}/UndoPickedByMilkRun`);
      return true;
    } catch (error) {
      dispatch(setError());
      return false;
    }
  }
);

export const undoPikedByMilkRunEmptyKitTrolley = createAsyncThunk(
  "kitStatus/undoEmptiedPickedKit",
  async (barcode: string, { dispatch }) => {
    try {
      await axiosInstance.put(
        `api/Trollies/${barcode}/UndoPickedByMilkRunForEmptyKit`
      );
      return true;
    } catch (error) {
      dispatch(setError());
      return false;
    }
  }
);

export const deliveredTrolleysAtAssemblyLine = createAsyncThunk(
  "kitStatus/deliveredTrolleysAtAssemblyLine",
  async (BarCodes: string[], { dispatch }) => {
    try {
      dispatch(setLoading());
      await axiosInstance.put(
        `/api/Trollies/DeliveredAtAssemblyLine`,
        BarCodes
      );
      return true;
    } catch (error) {
      dispatch(setError());
      return false;
    }
  }
);

export const deliveredTrolleysAtWarehouse = createAsyncThunk(
  "kitStatus/deliveredTrolleysAtWarehouse",
  async (BarCodes: string[], { dispatch }) => {
    try {
      dispatch(setLoading());
      await axiosInstance.put(`/api/Trollies/deliveredAtWarehouse`, BarCodes);
      return true;
    } catch (error) {
      dispatch(setError());
      return false;
    }
  }
);
