import { Box, Typography } from "@mui/material";
import React from "react";

const NotAccessUser: React.FC = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
      sx={{
        backgroundImage:
          "linear-gradient(to bottom right, #EADDD8, #D8E0E8, #D8E3D7, #E0E3DA)",
      }}
    >
      <Box
        width={"500px"}
        borderRadius={1}
        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px;"}
        bgcolor={"#FFF"}
        padding={5}
      >
        {
          <Typography variant="h1" fontWeight={"1000"} color={"#0078A1"} mb={3}>
            Access Denied
          </Typography>
        }
        <Typography
          variant="h5"
          component={"h5"}
          fontWeight={"500"}
          fontSize={"17px"}
        >
          Currently, there are no specific roles assigned to your account. To
          address this, we recommend reaching out to our administrator for
          assistance.
        </Typography>
      </Box>
    </Box>
  );
};

export default NotAccessUser;
