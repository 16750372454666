import React from 'react'
import { TableRow, TableHead, TableCell,Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';

const WorkCenterTypeTableHead:React.FC = () => {
    const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        <TableCell width={"1350"}>
          <Typography ml={"34px"} variant="h5">{t("Work Center Type")}</Typography>
        </TableCell>
        <TableCell width={"210"} align='center'>
        <Typography variant="h5">{t("Action")}</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default WorkCenterTypeTableHead