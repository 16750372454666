import React from "react";
import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
} from "@mui/material";
import { Formik, Form, FormikHelpers } from "formik";
import { InputTag, LabelTag } from "../../../../components";
import { RootState, useAppDispatch } from "../../../../store";
import {
  addWarehouse,
  updateWarehouse,
} from "../../../../api/warehouse.service";
import { useSelector } from "react-redux";
import {
  FormInitialState,
  setActiveRowIndex,
  setAddWarehouseAction,
  setDefaultFormState,
  setUpdateWarehouseId,
  setValidationError,
} from "../../../../store/warehouseSlice";
import { getAllWarehouses } from "../../../../api/kitPickerPicklist.service";
import {
  validationSchemaForArtPlatz,
  validationSchemaForLocation,
} from "./formValidation";
import { useTranslation } from "react-i18next";

type AddWarehouseFormProp = {
  materialAvailableAt: string;
  setMaterialAvailableAt: (value: string) => void;
};

const AddWarehouseForm: React.FC<AddWarehouseFormProp> = ({
  materialAvailableAt,
  setMaterialAvailableAt,
}) => {
  const {
    initialStateForLocation,
    isAddWarehouseAction,
    warehouseIdForUpdate,
    validationError,
  } = useSelector((store: RootState) => store.warehouseDetails);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const dispatchActions = (): void => {
    dispatch(setActiveRowIndex(-1));
    dispatch(setDefaultFormState());
    dispatch(setAddWarehouseAction(true));
    dispatch(setUpdateWarehouseId(0));
  };
  const handleSubmit = (
    values: FormInitialState,
    { resetForm }: FormikHelpers<FormInitialState>
  ): void => {
    const trimmedValues =
      materialAvailableAt === "location"
        ? {
            name: values.name.trim(),
            partPickLocationMin: values.partPickLocationMin?.trim(),
            partPickLocationMax: values.partPickLocationMax?.trim(),
            artPlatz: null,
          }
        : {
            name: values.name.trim(),
            partPickLocationMin: null,
            partPickLocationMax: null,
            artPlatz: values.artPlatz?.trim(),
          };

    if (isAddWarehouseAction) {
      dispatch(addWarehouse(trimmedValues)).then((res) => {
        if (res.payload) {
          resetForm({ values: { ...initialStateForLocation } });
          dispatch(getAllWarehouses());
        }
      });
    } else {
      dispatch(
        updateWarehouse({
          id: warehouseIdForUpdate,
          ...trimmedValues,
        })
      ).then((res) => {
        if (res.payload) {
          dispatchActions();
          dispatch(getAllWarehouses());
        }
      });
    }
  };

  const handleCancel = ({
    resetForm,
  }: FormikHelpers<FormInitialState>): void => {
    resetForm({ values: { ...initialStateForLocation } });
    dispatchActions();
    dispatch(setValidationError({ ErrorCode: "", Message: "" }));
  };

  const handlePartPick = (
    event: React.ChangeEvent<HTMLInputElement>,
    { resetForm }: FormikHelpers<FormInitialState>,
    value: FormInitialState
  ): void => {
    isAddWarehouseAction &&
      resetForm({
        values: {
          ...value,
          artPlatz: "",
          partPickLocationMax: "",
          partPickLocationMin: "",
        },
      });
    setMaterialAvailableAt(event.target.value);
  };

  return (
    <Formik
      initialValues={initialStateForLocation}
      validationSchema={
        materialAvailableAt === "location"
          ? validationSchemaForLocation()
          : validationSchemaForArtPlatz()
      }
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(resetForm) => (
        <Form>
          <Box width="100%" display="flex" gap="82px">
            <Box width={"516px"}>
              <LabelTag label={t("Warehouse Name")} required={true} />
              <InputTag name="name" width={"516px"} />
            </Box>
            <Box width={"516px"} marginTop="30px">
              <FormControl>
                <RadioGroup
                  row
                  onChange={(e) =>
                    handlePartPick(e, resetForm, resetForm.values)
                  }
                  value={materialAvailableAt}
                >
                  <FormControlLabel
                    value="location"
                    control={<Radio />}
                    label={t("Part Pick location (Min/Max)")}
                  />
                  <FormControlLabel
                    value="artPlatz"
                    control={<Radio />}
                    label={t("ArbPlatz")}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box width="100%" display={"flex"} gap={"82px"} marginTop="11px">
            {materialAvailableAt === "location" && (
              <>
                <Box>
                  <LabelTag
                    label={t("Part Pick Location (Min)")}
                    required={true}
                  />
                  <InputTag name="partPickLocationMin" width={"516px"} />
                </Box>
                <Box>
                  <LabelTag
                    label={t("Part Pick location (Max)")}
                    required={true}
                  />
                  <InputTag name="partPickLocationMax" width={"516px"} />
                </Box>
              </>
            )}
            {materialAvailableAt === "artPlatz" && (
              <Box>
                <LabelTag label={t("ArbPlatz")} required={true} />
                <InputTag name="artPlatz" width={"516px"} />
              </Box>
            )}
          </Box>
          <Box
            width="100%"
            marginTop={"21px"}
            display="flex"
            justifyContent={
              validationError.ErrorCode ? "space-between" : "flex-end"
            }
          >
            {validationError.ErrorCode && (
              <Box display="flex" alignItems={"center"}>
                <Typography color="red">
                  {t(validationError.Message)}
                </Typography>
              </Box>
            )}

            <Box display="flex" justifyContent={"space-between"} gap="44px">
              <Button
                variant="outlined"
                onClick={() => handleCancel(resetForm)}
              >
                {t("Cancel")}
              </Button>
              <Button variant="contained" type="submit">
                {isAddWarehouseAction ? t("Add") : t("Save")}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddWarehouseForm;
