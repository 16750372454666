import { enqueueSnackbar } from "notistack";

export const speakText = (text: string, lang = "en-US"): void => {
  const defaultVoiceName = "YourDefaultVoiceName";

  const synth = window.speechSynthesis;
  const utterance = new SpeechSynthesisUtterance(text);

  const desiredVoice = synth
    .getVoices()
    .find((voice) => voice.name === defaultVoiceName);
  if (desiredVoice) {
    utterance.voice = desiredVoice;
  } else {
    console.error("Desired voice not found."); //eslint-disable-line
  }

  utterance.lang = lang;
  utterance.pitch = 1.0;
  utterance.rate = 1.0;

  const speakWithRetry = (attempt: number): void => {
    if (attempt > 3) {
      enqueueSnackbar("Barcode scanner failed");
      console.error("Speech synthesis failed after multiple attempts."); //eslint-disable-line
      return;
    }

    utterance.onstart = () => {
      console.log("Speech synthesis started."); //eslint-disable-line
    };

    utterance.onend = () => {
      console.log("Text has been spoken."); //eslint-disable-line
    };

    utterance.onerror = (error) => {
      enqueueSnackbar("Barcode scanner failed");
      console.error("Error while speaking:", error); //eslint-disable-line
      setTimeout(() => {
        speakWithRetry(attempt + 1);
      }, 1000);
    };

    synth.speak(utterance);
  };

  speakWithRetry(1);
};
