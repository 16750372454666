import { TableCell, TableRow, Box, Typography } from '@mui/material'
import React from 'react'
import { BiSolidEdit } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { WorkCenterInitialType } from '../../../../store/workCenterTypeSlice';

interface WorkCenterTypeTableRowProps{
    id:number;
    centerType:WorkCenterInitialType;
    initialId:number;
    deleteType: (id: number,centerType:WorkCenterInitialType) => void
    updateType:(centerType:WorkCenterInitialType)=>void
}
const WorkCenterTypeTableRow:React.FC<WorkCenterTypeTableRowProps> = (props) => {
    const {id,centerType, initialId,deleteType,updateType}=props
   
    const highlightStyle = {
      fontWeight: 'bold',
      backgroundColor: '#E5FFCC',
    };
  return (
    <TableRow style={{ height: "83px" }}>
      <TableCell sx={initialId === id? highlightStyle : {}} width={"1350px"}>
        <Typography
          sx={initialId === id ? highlightStyle : {}}
          ml={"54px"}
          variant="h1"
          color={"#0D0D0D"}
          fontSize={"18px"}
        >
          {centerType.name}
        </Typography>
      </TableCell>
      <TableCell sx={initialId === id ? highlightStyle : {}} width={"210px"}>
        <Box ml={"14px"} display={"flex"} gap={"30px"} justifyContent={"center"}>
          <BiSolidEdit
            size={"18px"}
            color="#0078a1"
            cursor={"pointer"}
            onClick={() => updateType(centerType)}
          />

          <RiDeleteBin6Line
            size={"18px"}
            color="red"
            cursor={"pointer"}
            onClick={() => deleteType(id,centerType)}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default WorkCenterTypeTableRow