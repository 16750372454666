import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AssemblyAreaType,
  addAssemblyArea,
  getAssemblyAreas,
  setError,
  setLoading,
  setValidationError,
} from "../store/AssemblyAreaSlice";
import axiosInstance from "./base.service";

export const addAssemblyAreaAction = createAsyncThunk(
  "assemblyArea/addAssemblyArea",
  async (assemblyArea: AssemblyAreaType, { dispatch }) => {
    try {
      dispatch(setLoading());
      const response = await axiosInstance.post(
        `api/assemblyLines`,
        assemblyArea
      );
      dispatch(addAssemblyArea(response.data));
      return true;
    } catch (error: any) {
      dispatch(setValidationError(error));
      return false;
    }
  }
);

export const getAssemblyAreaAction = createAsyncThunk(
  "workCenterType/getWorkCenterTypes",
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading());
      const res = await axiosInstance.get("api/AssemblyLines");
      const data = res.data;
      dispatch(getAssemblyAreas(data));
      return data;
    } catch (error) {
      dispatch(setError());
    }
  }
);

export const updateAssemblyAreaAction = createAsyncThunk(
  "workCenterType/updateWorkCenterType",
  async (assemblyAreaData: AssemblyAreaType, { dispatch }) => {
    try {
      await axiosInstance.put(`api/AssemblyLines`, assemblyAreaData);
      return true;
    } catch (error: any) {
      dispatch(setValidationError(error));
      return false;
    }
  }
);

export const deleteAssemblyAreaAction = createAsyncThunk(
  "workCenterType/deleteWorkCenterTypes",
  async (id: number, { dispatch }) => {
    dispatch(setLoading());
    try {
      await axiosInstance.delete(`api/AssemblyLines/${id}`);
      return true;
    } catch (error: any) {
      dispatch(setValidationError(error));
      return false;
    }
  }
);

export const extendLockingPeriod = async (id: string): Promise<boolean> => {
  try {
    await axiosInstance.put(`api/Orders/ExtendLockingPeriod?productionOrder=${id}`);
    return true;
  } catch (error) {
    return false;
  }
};