import { Table, TableBody, TableContainer } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import MaterialTableHead from "./MaterialTableHead";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import MaterialTableRow from "./MaterialTableRow";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import { addMaterialInTrolley } from "../../store/KitPickerPicklist/kitPickerPicklistSlice";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { speakText } from "../../utils/speakText";
import i18n from "i18next";

interface MaterialTablePropsTypes {
  isFetched: boolean;
  resetTimer: () => void;
}

const MaterialTable: React.FC<MaterialTablePropsTypes> = ({
  isFetched,
  resetTimer,
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const { barcode, enterKey, handleResetEnterKey } = useBarcodeScanner();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  const { components, pickedComponents } = useSelector(
    (store: RootState) => store.productionOrder
  );

  const calculateMissingIndex = useCallback(() => {
    const pickedComponentIds = new Set(pickedComponents.map((pc) => pc.id));
    for (let i = 0; i < components.length; i++) {
      if (!pickedComponentIds.has(components[i].id)) {
        return i;
      }
    }
    return -1;
  }, [components, pickedComponents]);

  const scanBarcode = useCallback(() => {
    if (enterKey && isFetched) {
      handleResetEnterKey();

      let notFoundMessage = true;

      components.map((component, index) => {
        if (barcode === component.material && index === selectedRowIndex) {
          dispatch(addMaterialInTrolley({ ...component }));
          const section = document.getElementById(
            component.id + component.material
          );
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "center" });
          }
          notFoundMessage = false;
        }
      });

      if (notFoundMessage) {
        speakText(
          t(
            `The scanned material is not matching with the material to be picked up.`
          ),
          currentLanguage
        );
        enqueueSnackbar(
          `${t(
            "The scanned material is not matching with the material to be picked up."
          )} [${t("You scanned")} : ${barcode}]`
        );
      }
    }
  }, [
    barcode,
    components,
    currentLanguage,
    enterKey,
    selectedRowIndex,
    isFetched,
    t,
    handleResetEnterKey,
    dispatch,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    scanBarcode();
  }, [scanBarcode]);

  useEffect(() => {
    const missingIndex = calculateMissingIndex();
    setSelectedRowIndex(missingIndex);
  }, [calculateMissingIndex]);

  return (
    <>
      <TableContainer
        sx={{ maxHeight: "calc(100vh - 450px)" }}
        onScroll={() => resetTimer()}
      >
        <Table stickyHeader>
          <MaterialTableHead />
          <TableBody>
            {components.map((component, index: number) => {
              return (
                <MaterialTableRow
                  key={component.id}
                  component={component}
                  selectedRowIndex={selectedRowIndex}
                  index={index}
                  isFetched={isFetched}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MaterialTable;
