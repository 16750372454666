import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./base.service";
import {
  assemblyLineList,
  reportTrolleyLoading,
  reportTrolleyError,
  getTrolleyList,
  updateTrolleyInProduction,
  updateTrolleyEmptied,
} from "../store/reportTrolley";
import { enqueueSnackbar } from "notistack";
import { t } from "i18next";

export const fetchAssemblyLineList = createAsyncThunk(
  "assemblyLineName",
  async (_, { dispatch }) => {
    dispatch(reportTrolleyLoading());
    try {
      const res = await axiosInstance.get("/api/AssemblyLines");
      res && dispatch(assemblyLineList(res.data));
      return true;
    } catch (error) {
      dispatch(reportTrolleyError);
      return error;
    }
  }
);

interface fetchReportTrolleyListTypes {
  assemblyId: number;
  statusList: string[];
}

export const fetchReportTrolleyList = createAsyncThunk(
  "productionOrderList/assemblyLineName/stausList",
  async (props: fetchReportTrolleyListTypes, { dispatch }) => {
    const { assemblyId, statusList } = props;
    dispatch(reportTrolleyLoading());

    const baseUrl = `/api/Trollies/GetAll?assemblyId=${assemblyId}`;
    const queryString = statusList
      .map((value) => `statusList=${encodeURIComponent(value)}`)
      .join("&");

    const apiUrl = `${baseUrl}&${queryString}`;

    try {
      const res = await axiosInstance.get(apiUrl);
      res && dispatch(getTrolleyList(res.data));
      return true;
    } catch (error) {
      dispatch(reportTrolleyError);
      return false;
    }
  }
);

export const updateReportTrolleyInProduction = createAsyncThunk(
  "trollies/inProduction/trolleyBarcode",
  async (trolleyBarcode: string, { dispatch }) => {
    dispatch(reportTrolleyLoading());
    try {
      await axiosInstance.put(
        `/api/Trollies/InProduction?trolleyBarcode=${trolleyBarcode}`
      );
      dispatch(updateTrolleyInProduction());
      return true;
    } catch (error) {
      dispatch(reportTrolleyError);
      return false;
    }
  }
);

export const undoUpdateReportTrolleyInProduction = createAsyncThunk(
  "trollies/undoReadyPickedKit",
  async (barcode: string, { dispatch }) => {
    try {
      await axiosInstance.put(`api/Trollies/${barcode}/UndoInProduction`);
      dispatch(updateTrolleyInProduction());
      return true;
    } catch (error) {
      dispatch(reportTrolleyError);
      return false;
    }
  }
);

interface updateTrolleyEmptiedTypes {
  trolleyBarcode: string;
  productionOrder: string;
}

export const updateReportTrolleyEmptied = createAsyncThunk(
  "trollies/emptied/trolleyBarcode",
  async (props: updateTrolleyEmptiedTypes, { dispatch }) => {
    const { trolleyBarcode, productionOrder } = props;
    dispatch(reportTrolleyLoading());
    try {
      await axiosInstance.put(
        `/api/Trollies/Emptied?trolleyBarcode=${trolleyBarcode}&productionOrder=${productionOrder}`
      );
      dispatch(updateTrolleyEmptied());
      return true;
    } catch (error) {
      dispatch(reportTrolleyError);
      return false;
    }
  }
);

export const undoUpdateReportTrolleyEmptied = createAsyncThunk(
  "trollies/undoReadyPickedKit",
  async (barcode: string, { dispatch }) => {
    try {
      await axiosInstance.put(`api/Trollies/${barcode}/UndoEmptied`);
      dispatch(updateTrolleyEmptied());
      return true;
    } catch (error) {
      dispatch(reportTrolleyError);
      return false;
    }
  }
);

interface TrolleyReworkTypes {
  trolleyBarcode: string;
  productionOrderId: string;
}

export const sendTrolleyToRework = createAsyncThunk(
  "trollies/sendToRework/trolleyBarcode",
  async (props: TrolleyReworkTypes, { dispatch }) => {
    const { trolleyBarcode, productionOrderId } = props;
    dispatch(reportTrolleyLoading());
    try {
      await axiosInstance.put(
        `api/Trollies/SendToRework?trolleyBarcode=${trolleyBarcode}&productionOrderId=${productionOrderId}` //eslint-disable-line
      );
      dispatch(updateTrolleyInProduction());
      return true;
    } catch (error: any) {
      enqueueSnackbar(t(error.Message));
      dispatch(reportTrolleyError);
      return false;
    }
  }
);
