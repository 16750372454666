import React, { useState } from "react";
import {
  Navbar,
  NavigationTabs,
  PageTitle,
  PopOver,
  Subtitle,
} from "../../../components";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import UserManagementForm from "../../../components/User/UserManagementForm";
import { useNavigate } from "react-router";
import { addUser, updateUser } from "../../../api/user.service";
import userAddedLogo from "../../../assets/image/User-added.svg";
import { Box } from "@mui/material";

export type UserFormInitialStateAdmin = {
  name: string;
  email: string;
  userRole?: string;
  language?: string;
};

const UserFormAdmin: React.FC = () => {
  const { formState, userValidations } = useSelector(
    (store: RootState) => store.userList
  );
  const [isPopOverOpen, setPopOverOpen] = useState(false);
  const initialState: UserFormInitialStateAdmin = {
    name: formState.name,
    email: formState.email,
    userRole: formState.userRole,
    language: formState.language,
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required.")
      .min(2, "Name must be at least 2 characters.")
      .max(50, "Name must not exceed 50 characters.")
      .matches(
        /^[a-zA-Z\s\-_]+$/,
        "Name can only contain alphabets and special characters - and _"
      )
      .test(
        "no-leading-trailing-spaces",
        "Name can only contain alphabets and special characters - and _",
        (value) => {
          return value.trim() !== "";
        }
      ),
    email: Yup.string()
      .required("Email is required.")
      .email("Invalid email format")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      ),
    userRole: Yup.string().required("Role is required."),
    // language: Yup.string().required(t("Language is required.")),
  });

  const handleSubmit = (value: UserFormInitialStateAdmin): void => {
    const userDetails = {
      name: value.name.trim(),
      email: value.email.trim(),
      userRole: value.userRole?.trim(),
      language: "en-US",
    };

    (formState.createAction
      ? dispatch(addUser({ ...userDetails }))
      : dispatch(
          updateUser({
            id: formState?.id,
            isActive: formState?.isActive,
            ...userDetails,
          })
        )
    ).then((res) => res?.payload && setPopOverOpen(true));
  };

  const handleClosePopOver = (): void => {
    setPopOverOpen(false);
    navigate("/assign-role");
  };

  const handleCancel = (): void => {
    navigate("/assign-role");
  };
  return (
    <>
      <Navbar />
      <NavigationTabs />
      <PageTitle
        title={`${formState.createAction ? "Add" : "Update"} User Role`}
        showRedirectBackIcon={true}
        redirectBackUrl="/assign-role"
      />
      <Box width={"95%"} margin={"auto"} marginTop={"28px"}>
        <Subtitle value={t("User Role Details")} />
        <UserManagementForm
          validationSchema={validationSchema}
          initialState={initialState}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          showSelectField={true}
          userValidations={userValidations}
        />
      </Box>
      <PopOver
        title={`User ${
          formState.createAction ? "Added" : "Updated"
        } Successfully`}
        logo={userAddedLogo}
        closePopOver={handleClosePopOver}
        open={isPopOverOpen}
        position={true}
      />
    </>
  );
};

export default UserFormAdmin;
