import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loader: React.FC = () => {
  return (
    <Box width={"100%"} display={"flex"} justifyContent={"center"} mt="30px">
      <CircularProgress />
    </Box>
  );
};

export default Loader;
