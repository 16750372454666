import { Box, TextField } from "@mui/material";
import React from "react";
import { LabelTag } from "../../components";

type ReadOnlyInputProps = {
  lable: string;
  value: string;
};

const ReadOnlyInput: React.FC<ReadOnlyInputProps> = ({ lable, value }) => {
  return (
    <>
      <Box>
        <LabelTag label={lable} />
        <TextField
          fullWidth
          value={value}
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
    </>
  );
};

export default ReadOnlyInput;
