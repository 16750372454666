import { useState } from "react";

interface ConfirmationModalState {
  isModalOpen: boolean;
  selectedUser: number | null;
}

interface ConfirmationModalActions {
  openModal: (selectedUser: number | null) => void;
  closeModal: () => void;
}

type UseConfirmationModal = ConfirmationModalState & ConfirmationModalActions;

const useConfirmDelete = (
  initialState: ConfirmationModalState = {
    isModalOpen: false,
    selectedUser: null,
  }
): UseConfirmationModal => {
  const [state, setState] = useState<ConfirmationModalState>({
    ...initialState,
  });

  const openModal = (selectedUser: number | null): void =>
    setState({ isModalOpen: true, selectedUser });
  const closeModal = (): void =>
    setState({ isModalOpen: false, selectedUser: null });

  return { ...state, openModal, closeModal };
};

export default useConfirmDelete;
