import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
interface SubtitleProps {
  value: string;
}
const Subtitle: React.FC<SubtitleProps> = (props) => {
  const { value } = props;
  const { t } = useTranslation();
  return (
    <Box width={"100%"} mb={"20px"}>
      <Typography variant="h4">{t(value)}</Typography>
    </Box>
  );
};

export default Subtitle;
