import React from "react";
import { Box, Typography } from "@mui/material";

interface PlaceholderMessageType {
  message: string;
}
const PlaceholderMessage: React.FC<PlaceholderMessageType> = ({ message }) => {
  return (
    <>
      <Box
        width="93%"
        margin={"auto"}
        marginTop={"57px"}
        display="flex"
        justifyContent={"center"}
      >
        <Typography variant="h5">{message}</Typography>
      </Box>
    </>
  );
};

export default PlaceholderMessage;
