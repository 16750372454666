import Box from "@mui/material/Box";
import React from "react";
import { Typography } from "@mui/material";
import UserDetails from "./UserDetails";
import { useTranslation } from "react-i18next";
import LocaleSwitcher from "./LocalSwitcher";
import HeaderLogo from "../../assets/image/AtlasCopcoLogo.svg";

const Navbar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      height="87px"
      bgcolor="#0078A1"
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      position={"sticky"}
      top={"0px"}
      zIndex={100}
      pl={5}
      pr={"20px"}
    >
      <Box display={"flex"} gap={"60px"} alignItems={"center"}>
        <img src={HeaderLogo} alt="" width={"100px"} height={"50px"} />
        <Typography color={"white"} variant="h1">
          {t("Kitting Application")}
        </Typography>
      </Box>
      <Box gap={5} display={"flex"} alignItems={"center"}>
        <LocaleSwitcher />
        <UserDetails />
      </Box>
    </Box>
  );
};

export default Navbar;
