import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toTitleCase } from "../../../../utils/toTitleCase";

interface OptionListTypes {
  id: number;
  optionName: string;
}

interface SelectTagPropsType {
  optionList: OptionListTypes[];
  onSetValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
}

const AssemblyAreaFilterTag: React.FC<SelectTagPropsType> = ({
  optionList,
  onSetValue,
  value,
}) => {
  const { t } = useTranslation();

  const handleSelectChange = (event: SelectChangeEvent): void => {
    const selectValue = event.target.value as string;
    onSetValue(selectValue);
  };

  return (
    <Select
      fullWidth
      sx={{ height: "40px", mt: "7px", bgcolor: "white" }}
      onChange={handleSelectChange}
      value={value}
    >
      <MenuItem value={"all"} sx={{ fontWeight: 400 }}>
        {t("All")}
      </MenuItem>
      {optionList?.map((option) => (
        <MenuItem key={option.id} value={option.id} sx={{ fontWeight: 400 }}>
          {t(toTitleCase(option.optionName))}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AssemblyAreaFilterTag;
