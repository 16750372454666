import { Box, Button } from "@mui/material";
import React from "react";
import MaterialTable from "./MaterialTable";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import {
  getProductionOrder,
  sendToMilkrun,
} from "../../api/kitPickerPicklist.service";
import { MdNavigateNext } from "react-icons/md";
import { useTranslation } from "react-i18next";
import PlaceholderMessage from "./PlaceholderMessage";

interface TableWithControlsProps {
  isFetched: boolean;
  setIsFetched: (value: boolean) => void;
  resetTimer: () => void;
  setIsActive: (value: boolean) => void;
  handleLeftCountError: (value: string) => void;
}

const TableWithControls: React.FC<TableWithControlsProps> = ({
  isFetched,
  setIsFetched,
  resetTimer,
  setIsActive,
  handleLeftCountError
}) => {
  const {
    trolleyBarcode,
    order,
    pickedComponents,
    selectedWarehouse,
    isTrolleyAvailable,
    components,
    isAllOrderCompleted,
  } = useSelector((store: RootState) => store.productionOrder);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isDisabled = components.length !== pickedComponents.length;
  const confirmOrder = (): void => {
    dispatch(
      sendToMilkrun({
        trolleyBarcode,
        assemblyLineId: order.assemblyLineId,
        pickedComponents,
      })
    ).then((res: any) => {
      if(res.payload?.ErrorCode==="130011"){
        handleLeftCountError(res.payload.Message);
      }else if (res.payload) {
        dispatch(getProductionOrder({ warehouseId: selectedWarehouse.id }));
        setIsActive(false);
        resetTimer();
        setIsFetched(false);
      }
    });
  };
  return (
    <>
      {isTrolleyAvailable &&
        isFetched &&
        components.length !== 0 &&
        !isAllOrderCompleted && (
          <>
            <Box width="96%" margin={"auto"} marginTop={"30px"}>
              <MaterialTable isFetched={isFetched} resetTimer={resetTimer} />
            </Box>
            <Box
              width="96%"
              display="flex"
              margin={"auto"}
              justifyContent="flex-end"
              gap="44px"
              marginTop={"25px"}
            >
              <Button
                variant="contained"
                disabled={true}
                sx={{
                  backgroundColor: "#FFCD00",
                  "&:hover": { backgroundColor: "#FFCD00" },
                }}
              >
                {t("Andon")}
              </Button>
              <Button
                variant="contained"
                disabled={isDisabled}
                onClick={confirmOrder}
                endIcon={<MdNavigateNext color={"#fff"} />}
              >
                {t("Confirm")}
              </Button>
            </Box>
          </>
        )}
      {isTrolleyAvailable &&
        isFetched &&
        components.length === 0 &&
        !isAllOrderCompleted && (
          <PlaceholderMessage message={t("File not read properly")} />
        )}
    </>
  );
};

export default TableWithControls;
