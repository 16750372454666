import React, { useEffect, useCallback, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  fetchReportTrolleyList,
  undoUpdateReportTrolleyEmptied,
  updateReportTrolleyEmptied,
} from "../../api/reportTrolley.service";
import {
  TrolleyType,
  updateActiveProdutionOrder,
} from "../../store/reportTrolley";
import useBarcodeScanner from "../../hooks/useBarcodeScanner";
import { CustomizedSnackbar, Loader, PopOver } from "../../components";
import Kitlogo from "../../assets/image/KitStatus/Group 9002.svg";
import EmptyTrolleyCard from "./EmptyTrolleyCard";
import i18n from "i18next";
import { speakText } from "../../utils/speakText";
import { enqueueSnackbar } from "notistack";
import { OptionTypes } from "../../components/SelectTagPopOver";
import { BsCheckCircleFill } from "react-icons/bs";

interface TabPanelProps {
  assemblyLine: OptionTypes;
}

const ReportEmptyTrolley: React.FC<TabPanelProps> = ({ assemblyLine }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const { trolleyList, updateTrolleyEmptied, activeProductionOrderEmptyTab } =
    useAppSelector((store) => store.reportTrolley);
  const { enterKey, barcode, handleResetEnterKey } = useBarcodeScanner();
  const [scanErrorMessage, setScanErrorMessage] = useState("");
  const [openPopover, setOpenPopover] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const groupedData = trolleyList.reduce((map, item) => {
    const assemblyArea = item.productionOrder;
    if (!map.has(assemblyArea)) {
      map.set(assemblyArea, []);
    }
    map.get(assemblyArea)?.push(item);
    return map;
  }, new Map<string, TrolleyType[]>());

  const groupedDataObject: Record<string, TrolleyType[]> =
    Object.fromEntries(groupedData);

  const groupedDataArray = Object.entries(groupedDataObject).map(
    ([key, value]) => ({
      productionOrder: key,
      trolleyList: value,
    })
  );

  const handleUpdateTrolleyStatus = (
    trolleyBarcode: string,
    status: string,
    productionOrder: string
  ): void => {
    if (productionOrder === activeProductionOrderEmptyTab) {
      if (status === "production") {
        dispatch(
          updateReportTrolleyEmptied({ trolleyBarcode, productionOrder })
        ).then((res) => {
          if (res.payload) {
            setOpenPopover(true);
          }
        });
      } else if (status === "emptied") {
        dispatch(undoUpdateReportTrolleyEmptied(trolleyBarcode));
      }
    } else {
      enqueueSnackbar(t("Please scan the production order first"));
    }
  };

  const handleActiveOrder = (productionOrder: string): void => {
    dispatch(
      updateActiveProdutionOrder({
        tab: "Empty",
        productionOrder,
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchReportTrolleyList({
        assemblyId: Number(assemblyLine.id),
        statusList: ["production", "emptied"],
      })
    ).then(() => {
      setInitialLoading(false);
    });

    const refreshInterval = setInterval(() => {
      dispatch(
        fetchReportTrolleyList({
          assemblyId: Number(assemblyLine.id),
          statusList: ["production", "emptied"],
        })
      );
    }, 1 * 60 * 1000);
    return () => clearInterval(refreshInterval);
  }, [dispatch, assemblyLine, updateTrolleyEmptied]);

  const scanBarcodeAssemblyUser = useCallback(() => {
    if (enterKey) {
      handleResetEnterKey();
      let notFoundMessage = "Production order & trolley not found";

      trolleyList?.map((trolley) => {
        {
          if (trolley.productionOrder === barcode) {
            const section = document.getElementById(trolley.productionOrder);
            if (section) {
              section.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
            dispatch(
              updateActiveProdutionOrder({
                tab: "Empty",
                productionOrder: trolley.productionOrder,
              })
            );
            notFoundMessage = "Production order found";
          } else {
            if (trolley.productionOrder === activeProductionOrderEmptyTab) {
              if (trolley.trolleyBarcode === barcode) {
                const section = document.getElementById(trolley.trolleyBarcode);
                if (section) {
                  section.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }
                if (trolley.trolleyStatus === "production") {
                  dispatch(
                    updateReportTrolleyEmptied({
                      trolleyBarcode: trolley.trolleyBarcode,
                      productionOrder: trolley.productionOrder,
                    })
                  ).then((res) => {
                    if (res.payload) {
                      setOpenPopover(true);
                    }
                  });
                }

                notFoundMessage = "Trolley found";
              }
            } else {
              if (trolley.trolleyBarcode === barcode) {
                notFoundMessage = "Production order not active";
                const section = document.getElementById(trolley.trolleyBarcode);
                if (section) {
                  section.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }
              }
            }
          }
        }
      });

      if (notFoundMessage === "Production order not active") {
        speakText(t("Please scan the production order first"));
        enqueueSnackbar(t("Please scan the production order first"));
      } else if (notFoundMessage === "Production order & trolley not found") {
        speakText(`${t("Unable to find trolley")} ${barcode}`, currentLanguage);
        enqueueSnackbar(`${t("Unable to find trolley")} ${barcode}`);
      }
    }
  }, [
    enterKey,
    activeProductionOrderEmptyTab,
    barcode,
    trolleyList,
    currentLanguage,
    handleResetEnterKey,
    dispatch,
    t,
  ]);

  useEffect(() => {
    scanBarcodeAssemblyUser();
  }, [scanBarcodeAssemblyUser]);

  return (
    <>
      <Box p="23px 53px" className="scroll-bar" height="calc(100vh - 235px)">
        {initialLoading && <Loader />}
        {!initialLoading && groupedDataArray?.length === 0 && (
          <Box mt={10}>
            <Typography variant="h5" textAlign="center">
              {t("There are no trolleys available on Assembly line")}
            </Typography>
          </Box>
        )}
        <Stack gap="26px">
          {!initialLoading &&
            groupedDataArray?.map((order) => (
              <Stack
                key={order.productionOrder}
                gap={1}
                id={order.productionOrder}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  minHeight={"30px"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleActiveOrder(order.productionOrder)}
                >
                  <Typography variant="h4" sx={{ cursor: "pointer" }}>
                    {t("Production Order")} - {order.productionOrder}
                  </Typography>
                  {activeProductionOrderEmptyTab === order.productionOrder && (
                    <BsCheckCircleFill color="#43B02A" size="30px" />
                  )}
                </Box>
                <Stack direction="row" justifyContent="flex-start">
                  <Stack
                    border="1px solid #5A5D60"
                    bgcolor={
                      activeProductionOrderEmptyTab === order.productionOrder
                        ? "#B4D516"
                        : ""
                    }
                    borderRadius="3px"
                    padding="13px 9px"
                    direction="row"
                    gap="17px"
                    flexWrap={"wrap"}
                  >
                    {order?.trolleyList?.map((trolley) => (
                      <EmptyTrolleyCard
                        trolleyBarcode={trolley.trolleyBarcode}
                        status={trolley.trolleyStatus}
                        key={trolley.trolleyBarcode}
                        productionOrder={order.productionOrder}
                        onUpdateTrolley={handleUpdateTrolleyStatus}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            ))}
        </Stack>
      </Box>
      <CustomizedSnackbar
        errorMessage={scanErrorMessage}
        setErrorMessage={setScanErrorMessage}
        duration={15000}
      />
      <PopOver
        title={"Trolley is successfully reported as empty!"}
        logo={Kitlogo}
        closePopOver={() => setOpenPopover(false)}
        open={openPopover}
      />
    </>
  );
};

export default ReportEmptyTrolley;
