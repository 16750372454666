import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiSolidEdit } from "react-icons/bi";
import { TableRow, TableCell, Box, TableBody } from "@mui/material";
import { AssemblyAreasType } from "../../../../store/AssemblyAreaSlice";
import TrolleyQuantityColumns from "./TrolleyQuantityColumns";
interface AssemblyAreaTableRowProps {
  assemblyAreas: AssemblyAreasType[];
  memoizedWarehousesLength: number;
  id: number;
  openConformDelete: (id: number | null,AssemblyLineName:string) => void;
  updateAssemblyArea: (id: number | null, item: AssemblyAreasType) => void;
}

const AssemblyAreaTableRow: React.FC<AssemblyAreaTableRowProps> = ({
  assemblyAreas,
  memoizedWarehousesLength,
  id,
  openConformDelete,
  updateAssemblyArea,
}) => {
  const highlightStyle = {
    fontWeight: "bold",
    backgroundColor: "#E5FFCC",
  };
  return (
    <TableBody>
      {assemblyAreas.map((item) => {
        const isHighlighted = item.assemblyLineId === id;
        return (
          <TableRow key={item.assemblyLineId} sx={{ height: "50px" }}>
            <TableCell sx={isHighlighted ? highlightStyle : {}}>
              {item.assemblyLineName}
            </TableCell>
            <TableCell sx={isHighlighted ? highlightStyle : {}}>
              {item.workCenterType}
            </TableCell>

            <TrolleyQuantityColumns
              item={item}
              checkId={item.assemblyLineId === id}
              memoizedWarehousesLength={memoizedWarehousesLength}
            />
            <TableCell sx={isHighlighted ? highlightStyle : {}}>
              {item.keywords}
            </TableCell>
            
            <TableCell sx={isHighlighted ? highlightStyle : {}}>
              <Box display={"flex"} justifyContent={"center"} gap={"30px"}>
                <BiSolidEdit
                  size={"18px"}
                  color="#0078a1"
                  cursor={"pointer"}
                  onClick={() => updateAssemblyArea(item.assemblyLineId, item)}
                />
                <RiDeleteBin6Line
                  size={"18px"}
                  color="red"
                  cursor={"pointer"}
                  onClick={() => openConformDelete(item.assemblyLineId,item.assemblyLineName)}
                />
              </Box>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default AssemblyAreaTableRow;
