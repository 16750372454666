import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  addMaterialInTrolley,
  removeFromTrolley,
} from "../../store/KitPickerPicklist/kitPickerPicklistSlice";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { Component } from "../../store/KitPickerPicklist/kitPickerPicklistModel";
import { useAppDispatch, useAppSelector } from "../../store";

export type MaterialTableRowProp = {
  component: Component;
  selectedRowIndex: number;
  index: number;
  isFetched: boolean;
};

const MaterialTableRow: React.FC<MaterialTableRowProp> = ({
  component,
  selectedRowIndex,
  index,
}) => {
  const { pickedComponents } = useAppSelector((store) => store.productionOrder);

  const [isChecked, setChecked] = useState(false);
  const dispatch = useAppDispatch();
  const checkboxRef = useRef<HTMLInputElement | null>(null);

  const isSelectedRow = index === selectedRowIndex;
  const isCheckedBackgroundColor = isChecked ? "#E5FFCC" : "#fff";
  const selectedBackgroundColor = isSelectedRow
    ? "#00008B"
    : isCheckedBackgroundColor;
  const backgroundEffect = {
    backgroundColor: selectedBackgroundColor,
    color: isSelectedRow ? "#fff" : "#000",
    fontSize: isSelectedRow ? "36px" : "18px",
  };

  const screenWidth = window.screen.availWidth;
  const selectMaterial = (): void => {
    if (isChecked) {
      dispatch(removeFromTrolley(component.id));
      setChecked(false);
    } else {
      dispatch(addMaterialInTrolley({ ...component }));
      setChecked(true);
    }

    if (!isChecked && checkboxRef.current) {
      checkboxRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const changeCheckBoxStatus = useCallback(() => {
    const materialStatus = pickedComponents.some(
      (material) =>
        material.material === component.material && component.id === material.id
    );

    if (materialStatus !== isChecked) {
      setChecked(materialStatus);
    }
  }, [component, pickedComponents, isChecked]);

  useEffect(() => {
    changeCheckBoxStatus();
  }, [changeCheckBoxStatus]);

  return (
    <>
      <TableRow id={component.id + component.material}>
        <TableCell
          sx={{
            ...backgroundEffect,
            minWidth: screenWidth === 1920 ? "230px" : "200px",
          }}
        >
          {component.material}
        </TableCell>
        <TableCell
          sx={{
            ...backgroundEffect,
            minWidth: screenWidth === 1920 ? "830px" : "510px",
          }}
        >
          {component.materialShortText}
        </TableCell>
        <TableCell sx={backgroundEffect}>
          {component.requirementQuantity}
        </TableCell>
        <TableCell sx={backgroundEffect}>{component.unit}</TableCell>
        <TableCell sx={backgroundEffect}>{component.sortLimit}</TableCell>
        <TableCell sx={backgroundEffect}>{component.distribution}</TableCell>
        <TableCell ref={checkboxRef} sx={{ ...backgroundEffect }}>
          <Checkbox
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 28,
                color: isSelectedRow ? "#fff" : "#0078a1",
              },
            }}
            checked={isChecked}
            onClick={selectMaterial}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default MaterialTableRow;
