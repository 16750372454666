import { createSlice } from "@reduxjs/toolkit";

interface TrolleyListType {
  trolleyBarcode: string;
  trolleyStatus: string;
  productionOrder: string;
  orderDate: string;
  orderTime: string;
  assemblyArea: string;
  warehouse: string;
  updatedOn: string;
}

interface InitialStateType {
  loading: boolean;
  error: boolean;
  trolleyList: TrolleyListType[];
}

const initialState: InitialStateType = {
  loading: false,
  error: false,
  trolleyList: [],
};

const trolleyStatusSlice = createSlice({
  name: "trolleyStatus",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getError: (state) => {
      state.loading = false;
      state.error = true;
    },
    successTrolleyList: (state, action) => {
      state.loading = false;
      state.trolleyList = action.payload;
    },
  },
});

export const { getError, getLoading, successTrolleyList } =
  trolleyStatusSlice.actions;
export default trolleyStatusSlice.reducer;
