import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { store } from "../store";
import { msalInstance } from "../index";
import { AuthenticationTokenScope } from "../config/config";
import { setUserAccessToken } from "../store/userSlice";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 50000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const accessToken = store.getState().user.accessToken;
      const expirationTime = store.getState().user.accessTokenExpiration;

      if (accessToken) {
        const currentTime = new Date().getTime();

        if (expirationTime && expirationTime < currentTime) {
          const tokenRequest = {
            scopes: AuthenticationTokenScope,
          };
          const response = await msalInstance.acquireTokenSilent(tokenRequest);

          if (response.expiresOn) {
            store.dispatch(
              setUserAccessToken({
                accessToken: response?.accessToken,
                accessTokenExpiration: new Date(response.expiresOn).getTime(),
              })
            );
          }

          config.headers.Authorization = `Bearer ${response.accessToken}`;
        } else {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
    } catch (error) {
      enqueueSnackbar("Error retrieving access token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 422 &&
      error.response.data.ErrorCode
    ) {
      return Promise.reject(error.response.data);
    }

    enqueueSnackbar(error.message || "An error occurred.");
    return Promise.reject({ ErrorCode: "", Message: "" });
  }
);

export default axiosInstance;
