import { Box, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "../../assets/image/chevron-up.svg";
import { useAppDispatch, useAppSelector } from "../../store";
import { userError, userLoading, setUser } from "../../store/userSlice";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import UserLogo from "../../assets/image/UserImage.png";
import {
  GraphApi,
  SignOutUrl,
  UserInformationTokenScope,
} from "../../config/config";

const UserDetails: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userImage, setUserImage] = useState("");
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const { instance, accounts } = useMsal();
  const { userDetails, accessToken } = useAppSelector((store) => store.user);

  const logout = async (): Promise<void> => {
    dispatch(userLoading());
    try {
      await instance.logout({
        postLogoutRedirectUri: SignOutUrl,
      });
    } catch (error) {
      dispatch(userError(error));
    }

    dispatch(setUser(false));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option: string): void => {
    setAnchorEl(null);
    if (option === "Logout") logout();
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        if (accounts.length > 0) {
          const request = {
            scopes: UserInformationTokenScope,
            accounts: accounts[0],
          };
          const response = await instance.acquireTokenSilent(request);
          const imageResponse = await axios.get(
            `${GraphApi}/v1.0/me/photo/$value`,
            {
              headers: {
                Authorization: `Bearer ${response.accessToken}`,
              },
              responseType: "blob",
            }
          );

          if (imageResponse.status === 200) {
            const blobUrl = URL.createObjectURL(imageResponse.data);
            setUserImage(blobUrl);
          }
        }
      } catch (error) {} //eslint-disable-line
    };

    fetchData();
  }, [accounts, instance, accessToken]);

  return (
    <>
      <Box
        display={"flex"}
        gap={2}
        bgcolor={"#2089AD"}
        p={"2px 4px"}
        alignItems={"center"}
        borderRadius={"4px"}
        minWidth={"279px"}
        height={"61px"}
      >
        <Box width="53px" height="53px" borderRadius="4px">
          <img
            src={userImage || UserLogo}
            alt=""
            width={"100%"}
            height={"100%"}
          />
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={"2px"} mt={1}>
          <Typography variant="overline">{accounts?.[0]?.name}</Typography>
          <Typography variant="button">{userDetails?.userRole}</Typography>
        </Box>
        <Box marginLeft={"auto"} pr={"18px"}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <img
              src={KeyboardArrowDownIcon}
              alt=""
              width={"16px"}
              height={"16px"}
            />
          </IconButton>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => handleClose("Logout")}
          sx={{ width: "200px", height: "30px" }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDetails;
