import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./base.service";
import { setLoading, setValidationError } from "../store/warehouseSlice";

export const addWarehouse = createAsyncThunk(
  "warehouse/addWarehouse",
  async (
    data: {
      name: string;
      partPickLocationMin?: string | null;
      partPickLocationMax?: string | null;
      artPlatz?: string | null;
    },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    try {
      const res = await axiosInstance.post("/api/warehouses", data);
      if (res.status === 200) {
        dispatch(setLoading(false));
        dispatch(setValidationError({ ErrorCode: "", Message: "" }));
        return true;
      }
      return false;
    } catch (error: any) {
      dispatch(setValidationError(error));
      return false;
    }
  }
);

export const deleteWarehouse = createAsyncThunk(
  "warehouse/addWarehouse",
  async ({ id }: { id: number }, { dispatch }) => {
    try {
      const res = await axiosInstance.delete(`/api/warehouses/${id}`);
      if (res.status === 200) {
        dispatch(setValidationError({ ErrorCode: "", Message: "" }));
        return true;
      }
      return false;
    } catch (error: any) {
      dispatch(setValidationError(error));
      return false;
    }
  }
);

export const updateWarehouse = createAsyncThunk(
  "warehouse/updateWarehouse",
  async (
    data: {
      id: number;
      name: string;
      partPickLocationMin?: string | null;
      partPickLocationMax?: string | null;
      artPlatz?: string | null;
    },
    { dispatch }
  ) => {
        dispatch(setLoading(true));
    try {
      const res = await axiosInstance.put(
        `/api/warehouses/${data.id}`,
        data
      );
      if (res.status === 200) {
        dispatch(setLoading(false));
        dispatch(setValidationError({ ErrorCode: "", Message: "" }));
        return true;
      }
      return false;
    } catch (error: any) {
      dispatch(setValidationError(error));
      return false;
    }
  }
);
