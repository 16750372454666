import React from "react";
import { TextField, Box, InputAdornment } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { BsCheckCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

export type EmailInputProp = {
  name: string;
  width?: string;
  type: string;
};

const EmailInput: React.FC<EmailInputProp> = ({ name, width, type }) => {
  const { errors, setFieldValue, touched } = useFormikContext();
  const [field, meta] = useField(name);
  const { value } = meta;
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(name, e.target.value);
  };

  const isError =
    touched[name as keyof typeof touched] &&
    !!errors[name as keyof typeof errors];

  return (
    <Box width={width}>
      <TextField
        {...field}
        value={value}
        type={type}
        onChange={handleChange}
        variant="outlined"
        size="small"
        fullWidth
        error={isError}
        helperText={isError ? t(errors[name as keyof typeof errors]) : ""}
        InputProps={{
          endAdornment:
            !isError && regex.test(value) ? (
              <InputAdornment position="end">
                <BsCheckCircleFill color="#43B02A" size="27px" />
              </InputAdornment>
            ) : undefined,
        }}
      />
    </Box>
  );
};

export default EmailInput;
