import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: "Segoe UI",

    h1: {
      fontSize: "30px",
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "normal",
      color: "#FFF",
    },
    h2: {
      fontSize: "28px",
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "20px",
      color: "#0078A1",
    },
    h3: {
      fontSize: "28px",
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "32px",
      color: "#0078A1",
    },
    h4: {
      fontSize: "20px",
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "20px",
      color: "#000",
    },
    h5: {
      fontSize: "20px",
      fontWeight: 700,
      fontStyle: "normal",
      lineHeight: "normal",
      color: "#0078A1",
    },
    h6: {
      fontSize: "18px",
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: "20px",
      cursor: "pointer",
    },
    button: {
      fontSize: "16px",
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "normal",
      color: "#FFF",
    },
    caption: {
      fontSize: "16px",
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "normal",
      color: "#0D0D0D",
    },
    overline: {
      fontSize: "14px",
      fontWeight: 700,
      fontStyle: "normal",
      lineHeight: "normal",
      color: "#FFF",
    },
  },
  palette: {
    primary: {
      main: "#0078A1",
    },
    secondary: {
      main: "#fff",
    },
    info: {
      main: "#0D0D0D",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "#0078A1",
              color: "#ffffff",
              textTransform: "none",
              minWidth: "141px",
              height: "48px",
              boxShadow: "none",
              fontFamily: "Segoe UI",
              fontSize: "18px",
              fontWeight: "400px",
              padding: 0,

              "&:hover": {
                backgroundColor: "#0078A1",
                borderColor: "#0078A1",
                boxShadow: "none",
              },
            }),
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "primary" && {
              backgroundColor: "#ffffff",
              color: "#0078A1",
              borderColor: "#0078A1",
              textTransform: "none",
              minWidth: "141px",
              height: "48px",
              fontSize: "18px",
              fontWeight: "400px",
              fontFamily: "Segoe UI",
              padding: 0,

              "&:hover": {
                backgroundColor: "#ffffff",
                borderColor: "#0078A1",
              },
            }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid rgba(187, 189, 192, 1)",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #0078A1",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#0078A1",
            },
          },
          "& .MuiOutlinedInput-input": {
            padding: "15px 0px 14px 23px",
          },
          "& .MuiInputBase-input": {
            fontFamily: "Segoe UI",
            fontSize: "16px",
            lineHeight: "26px",
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowY: "auto",

          "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#0078A1",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: "#555",
            },
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "1px solid #BBBDC0",
        },
        head: {
          backgroundColor: "#eff9fd",
          color: "#0078a1",
          fontWeight: 700,
          fontSize: "20px",
          paddingLeft: "33px",
          paddingRight: "33px",
          height: "97px",
        },
        body: {
          fontSize: "18px",
          fontWeight: "400",
          fontFamily: "Segoe UI",
          backgroundColor: "white",
          paddingLeft: "33px",
          height: "83px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& .MuiSelect-select": {
            fontSize: "16px",
            fontFamily: "Segoe UI",
            fontWeight: 400,
            lineHeight: "26px",
            padding: "13px 20px",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Segoe UI",
          fontSize: "20px",
          fontWeight: 600,
          height: "60px",
          textTransform: "none",
          padding: "20px 27px",
          maxWidth: "500px",

          "&.Mui-selected": {
            color: "#0078A1",
            TextDecoder: "none",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          fontWeight: 600,
          color: "#0D0D0D",
          padding: "12px 23px",

          "&:hover": {
            backgroundColor: "#EFF9FD",
          },
        },
      },
    },
  },
});
