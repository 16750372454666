import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { FcInfo } from "react-icons/fc";
import { useTranslation } from "react-i18next";
interface WarningWindowProps {
  message: string;
  buttonName: string;
  onReload: () => void;
}
const WarningWindow: React.FC<WarningWindowProps> = ({
  message,
  onReload,
  buttonName,
}) => {
  const { t } = useTranslation();
  const messageLength = message.length;

  return (
    <Box
      width={messageLength < 100 ? "43%" : 1000 }
      margin={"auto"}
      marginTop={"57px"}
      padding="30px 30px"
      borderRadius={"5px"}
      boxShadow="rgba(0, 0, 0, 0.2) 0px 6px 24px 0px, rgba(0, 0, 0, 0.15) 0px 0px 0px 1px"
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={3}
      >
        <FcInfo size={messageLength < 100  ? "100px" : "300px"} />
        <Typography variant="h5" fontSize={messageLength < 100 ? "36px" : "30px"}>
          {t(message)}
        </Typography>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent={"flex-end"}
        marginTop="20px"
      >
        <Button variant="contained" onClick={() => onReload()}>
          {t(buttonName)}
        </Button>
      </Box>
    </Box>
  );
};

export default WarningWindow;
