import { TableCell, TableHead, TableRow, Typography, Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store";
import AssemblyAreaFilterTag from "./AssemblyAreaFilterTag";
import { SelectTag, SearchInputTag } from "../../../../components";
import {
  firstColwidth,
  fourthColWidth,
  secondColWidth,
  thirdColwidth,
} from "./index";

interface OrderPoolTableHeadPopsType {
  filterAssemblyArea: string;
  filterStatus: string;
  setFilterAssemblyArea: React.Dispatch<React.SetStateAction<string>>;
  setFilterStatus: React.Dispatch<React.SetStateAction<string>>;
  onOrderSearch: (searchValue: string) => void;
}

const OrderPoolTableHead: React.FC<OrderPoolTableHeadPopsType> = (props) => {
  const {
    filterAssemblyArea,
    filterStatus,
    setFilterStatus,
    setFilterAssemblyArea,
    onOrderSearch,
  } = props;
  const { assemblyLineList } = useAppSelector((store) => store.reportTrolley);
  const { t } = useTranslation();

  const assemblyAreaSortOptions = assemblyLineList.map((line) => ({
    id: line.assemblyLineId,
    optionName: line.assemblyLineName,
  }));

  return (
    <TableHead>
      <TableRow>
        <TableCell width={firstColwidth}>
          <Typography variant="h5">{t("Assembly Area")}</Typography>
          <AssemblyAreaFilterTag
            optionList={assemblyAreaSortOptions}
            value={String(filterAssemblyArea)}
            onSetValue={setFilterAssemblyArea}
          />
        </TableCell>
        <TableCell width={secondColWidth}>
          <Typography variant="h5">{t("Production Order")}</Typography>
          <Box bgcolor={"white"} mt={"7px"}>
            <SearchInputTag onSearch={onOrderSearch} />
          </Box>
        </TableCell>
        <TableCell width={thirdColwidth}>{t("Date & Time")}</TableCell>
        <TableCell width={fourthColWidth}>
          <Typography variant="h5">{t("Status")}</Typography>
          <SelectTag
            optionList={["all", "pending", "WIP", "completed"]}
            value={filterStatus}
            onSetValue={setFilterStatus}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default OrderPoolTableHead;
