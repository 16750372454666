import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ChangeByNavigation {
  id: number;
  name: string;
  email: string;
  userRole?: null;
  isActive?: false;
  createdBy?: number;
  createdOn?: string;
  updatedBy?: number;
  updatedOn?: string;
  inputDirectories?: [];
}
export interface FilePathDetails {
  id: number;
  directoryPath: string;
  changeBy?: string;
  changeDate: string;
  changeByNavigation: ChangeByNavigation;
}

interface InitialState {
  loading: boolean;
  error: boolean;
  historyData: FilePathDetails[];
}

const initialState: InitialState = {
  loading: false,
  error: false,
  historyData: [],
};

const inputDirectorySlice = createSlice({
  name: "inputDirectory",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setHistoryData: (state, action: PayloadAction<FilePathDetails[]>) => {
      state.historyData = action.payload;
    },
  },
});

export const { setLoading, setError, setHistoryData } =
  inputDirectorySlice.actions;

export default inputDirectorySlice.reducer;
