import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ValidationError } from "./warehouseSlice";

export interface WorkCenterInitialType {  
  id: number;
  name: string;
  assemblyLines: [];
}
export interface Type {
  name: string;
}
export interface UpdateOrDelete{
  id:number,
  message:string
}
export interface InitialStateTypes { 
  loading: boolean;
  error: boolean;
  initialValue:Type;
  centerTypes:WorkCenterInitialType[];
  validationError: ValidationError;
  workCenterTypeIdForUpdate:UpdateOrDelete
}

const initialState:InitialStateTypes = {
  loading: false,
  error: false,
 initialValue:{name:""},
 centerTypes:[],
 validationError: {
  ErrorCode: "",
  Message: "",
},
workCenterTypeIdForUpdate:{id:0,message:""}
};

export const workCenterTypeSlice = createSlice({
  name: "workCenterType",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setDefaultFormState: (state,action:PayloadAction<Type>) => {
      state.initialValue = action.payload
    },
    addWorkCenterType: (state, action: PayloadAction<Type>) => {
        state.error=false;
        state.loading=false;
        state.initialValue=action.payload
      },
    getWorkCenterTypes:(state, action: PayloadAction<WorkCenterInitialType[]>) => {
      state.loading=false;
      state.centerTypes=action.payload
    },
    setUpdateWarehouseId: (state, action: PayloadAction<UpdateOrDelete>) => {
      state.workCenterTypeIdForUpdate = action.payload;
    },
    setValidationError: (state, action: PayloadAction<ValidationError>) => {
      state.validationError = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setDefaultFormState,
  addWorkCenterType,
  getWorkCenterTypes,
  setValidationError,
  setUpdateWarehouseId
} = workCenterTypeSlice.actions;



export default workCenterTypeSlice.reducer;
