import { Box, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
interface WorkCenterTypeControlsPropType{
  initialId:number;
}
const WorkCenterTypeControl:React.FC<WorkCenterTypeControlsPropType> = ({initialId}) => {
    const { t } = useTranslation();
  return (
    <Box
      width={"50%"}
      display={"flex"}
      justifyContent={"flex-end"}
      alignItems={"end"}
    >
      <Box  >
        <Button variant="contained" type={"submit"}>{initialId<=0?t("Add"):t("Save")}</Button>
      </Box>
    </Box>
  )
}

export default WorkCenterTypeControl