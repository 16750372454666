import { Box, Pagination, Table } from "@mui/material";
import React, { useEffect, useState } from "react";
import OrderPoolTableHead from "./OrderPoolTableHead";
import OrderPoolTableBody from "./OrderPoolTableBody";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { orderPoolGetRequest } from "../../../../api/orderPool.services";
import { fetchAssemblyLineList } from "../../../../api/reportTrolley.service";
import { getAllWarehouses } from "../../../../api/kitPickerPicklist.service";
import { Loader } from "../../../../components";
import { paginate } from "../../../../utils/pagination";
import { OrderListType } from "../../../../store/orderPoolSlice";

export const firstColwidth = "20%";
export const secondColWidth = "30%";
export const thirdColwidth = "30%";
export const fourthColWidth = "20%";

const OrderPool: React.FC = () => {
  const dispatch = useAppDispatch();
  const [filterAssemblyArea, setFilterAssemblyArea] = useState("all");
  const [searchOrder, setSearchOrder] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [page, setPage] = useState(1);
  const [initialLoading, setInitialLoading] = useState(true);
  const { orderList } = useAppSelector((store) => store.orderPool);
  const [orderData, setOrderData] = useState<OrderListType[]>([]);
  const orderPerPage = 100;
  const totalPages = Math.ceil(orderList.length / orderPerPage);

  const handleOrderSearch = (searchValue: string): void => {
    setSearchOrder(searchValue);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  useEffect(() => {
    const paginateData = paginate(orderList, page, orderPerPage);
    setOrderData(paginateData);
  }, [page, orderList]);

  useEffect(() => {
    dispatch(fetchAssemblyLineList());
    dispatch(getAllWarehouses());
  }, [dispatch]);

  useEffect(() => {
    setPage(1);
    dispatch(
      orderPoolGetRequest({
        filterAssemblyArea:
          filterAssemblyArea === "all" ? "" : filterAssemblyArea,
        searchOrder,
        filterStatus: filterStatus === "all" ? "" : filterStatus,
      })
    ).then(() => {
      setInitialLoading(false);
    });
  }, [dispatch, filterAssemblyArea, searchOrder, filterStatus]);

  return (
    <>
      {initialLoading && <Loader />}
      {!initialLoading && (
        <Box m={5}>
          <Table>
            <OrderPoolTableHead
              filterAssemblyArea={filterAssemblyArea}
              filterStatus={filterStatus}
              setFilterAssemblyArea={setFilterAssemblyArea}
              setFilterStatus={setFilterStatus}
              onOrderSearch={handleOrderSearch}
            />
            <OrderPoolTableBody orderList={orderData} />
          </Table>
          {totalPages > 1 && (
            <Box
              mt={2}
              display={"flex"}
              justifyContent={"center"}
              position={"sticky"}
              bottom={0}
              bgcolor={"white"}
              p={1}
            >
              <Pagination
                count={totalPages}
                color="primary"
                page={page}
                onChange={handlePageChange}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default OrderPool;
