import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  success,
  greenBackground,
  checkSign,
  closeLogo,
} from "../assets/image/PopOver";

interface PopOverProps {
  open: boolean;
  title: string;
  logo: string;
  position?: boolean;
  enableSecondButton?: boolean;
  onSecondButtonAction?: () => void;
  closePopOver: () => void;
}
const PopOver: React.FC<PopOverProps> = (props) => {
  const {
    closePopOver,
    open,
    title,
    logo,
    position,
    enableSecondButton,
    onSecondButtonAction,
  } = props;
  const { t } = useTranslation();
  return (
    <Modal open={open}>
      <Box
        width={"414px"}
        height={"355px"}
        borderRadius={"15px"}
        bgcolor={"#FFF"}
        boxShadow={"0px 4px 22px 0px rgba(0, 0, 0, 0.15)"}
        position={"absolute"}
        top={"50%"}
        left={"50%"}
        padding={"40px"}
        className="custom-transform-element"
      >
        <Box
          position={"absolute"}
          right={"20px"}
          top={"20px"}
          onClick={closePopOver}
          className="custom-cursor-element"
        >
          <img src={closeLogo} alt="closeLogo" width={"32px"} height={"32px"} />
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={"20px"}
        >
          <Box borderRadius={"15px"} height={"119px"} width={"118px"}>
            <img src={success} alt="success" />

            {position ? (
              <Box
                width={"55.937px"}
                height={"56.493px"}
                ml={"27px"}
                mt={"-90px"}
              >
                <img src={logo} alt="logo" />
              </Box>
            ) : (
              <Box
                width={"55.937px"}
                height={"56.493px"}
                ml={"31px"}
                mt={"-90px"}
              >
                <img src={logo} alt="logo" />
              </Box>
            )}

            {position ? (
              <Box
                width={"32.051"}
                height={"32.833"}
                flexShrink={0}
                ml={"80px"}
                mt={"-90px"}
              >
                <img src={greenBackground} alt="greenBackground" />

                <Box ml={"7px"} mt={"-27px"}>
                  <img src={checkSign} alt="CheckSign" />
                </Box>
              </Box>
            ) : (
              <Box
                width={"32.051"}
                height={"32.833"}
                flexShrink={0}
                ml={"40px"}
                mt={"-65px"}
              >
                <img src={greenBackground} alt="greenBackground" />

                <Box ml={"7px"} mt={"-27px"}>
                  <img src={checkSign} alt="CheckSign" />
                </Box>
              </Box>
            )}
          </Box>
          <Typography fontFamily={"Segoe UI"} textAlign={"center"} variant="h3">
            {t(title)}
          </Typography>
          {enableSecondButton ? (
            <Box display={"flex"} gap={"27px"} mt={"auto"}>
              <Button size="small" variant="outlined" onClick={closePopOver}>
                {t("No")}
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={onSecondButtonAction}
              >
                {t("Yes")}
              </Button>
            </Box>
          ) : (
            <Box>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={closePopOver}
              >
                {t("Okay")}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default PopOver;
