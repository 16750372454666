import { Typography, Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const LabelTag: React.FC<{ label: string; required?: boolean }> = ({
  label,
  required,
}) => {
  const { t } = useTranslation();
  return (
    <Box display={"flex"} height={"20px"} alignItems={"flex-start"} mb={"9px"}>
      <Typography variant="caption">{t(label)}</Typography>
      {required && (
        <Typography variant="caption" color={"red"}>
          *
        </Typography>
      )}
    </Box>
  );
};

export default LabelTag;
