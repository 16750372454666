export const GraphApi = "https://graph.microsoft.com";
export const Instance = "https://login.microsoftonline.com/";
export const UserInformationTokenScope = [
  "User.Read",
  "profile",
  "openid",
  "email",
];
export const AuthenticationTokenScope = [
  `api://${process.env.REACT_APP_MICROSOFT_AZURE_CLIENT_ID}/Files.Read`,
];
export const SignOutUrl = "/";
export const initialSeconds = 900;
export const delay = 60;
