import React from "react";
import PageTitle from "../../components/PageTitle";
import { Box, Tab, Tabs } from "@mui/material";
import ReadyKit from "./ReadyKit";
import EmptyKit from "./EmptyKit";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../components";

const KitStatus: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <PageTitle title={t("Milk Run - Kit Status")} />
      <Box
        borderBottom={1}
        borderColor="#BBBDC0"
        boxShadow="7px 4px 9px 3px rgba(0, 0, 0, 0.03)"
      >
        <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
          <Tab label={t("Ready Kit Status")} disableRipple />
          <Tab label={t("Empty Kit Status")} disableRipple />
        </Tabs>
      </Box>

      {[ReadyKit, EmptyKit].map((Component, index) => (
        <Box key={index}>{value === index && <Component />}</Box>
      ))}
    </>
  );
};
export default KitStatus;
