import React from "react";
import { Box, Typography, TableRow, TableCell, TableBody } from "@mui/material";
import { useAppSelector } from "../../../../store";
import DateTimeDisplayer from "../../../../components/DateTimeDisplayer";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../../components";
import { OrderListType } from "../../../../store/orderPoolSlice";
import moment from "moment";
import {
  firstColwidth,
  fourthColWidth,
  secondColWidth,
  thirdColwidth,
} from "./index";

interface OrderPoolTableBodyProps {
  orderList: OrderListType[];
}

const OrderPoolTableBody: React.FC<OrderPoolTableBodyProps> = ({
  orderList,
}) => {
  const { loading } = useAppSelector((store) => store.orderPool);
  const { t } = useTranslation();

  return (
    <>
      <TableBody>
        {orderList?.map((order) => (
          <TableRow key={order?.id}>
            <TableCell width={firstColwidth} sx={{ padding: 0 }}>
              <Box
                borderLeft={`8px solid ${
                  order?.status === "Completed"
                    ? "#43B02A"
                    : order?.status === "Pending"
                    ? "#0066B3"
                    : "#CFA700"
                }`}
                p={"30px"}
                height={"100%"}
              >
                {order?.assemblyLine?.assemblyLineName}
              </Box>
            </TableCell>
            <TableCell width={secondColWidth}>
              {order?.productionOrder}
            </TableCell>
            <TableCell width={thirdColwidth}>
              <DateTimeDisplayer
                date={moment(order.cornerStart).format("DD/MM/YYYY")}
                time={moment(order?.startUzt, "HH:mm:ss").format("hh:mm A")}
              />
            </TableCell>
            <TableCell width={fourthColWidth}>
              <Box
                bgcolor={
                  order?.status === "Completed"
                    ? "#ECF8EB"
                    : order?.status === "Pending"
                    ? "#E6F0F8"
                    : "#FEFBE6"
                }
                borderRadius={"3px"}
                width={"118px"}
                height={"44px"}
                textAlign={"center"}
                margin={"auto"}
              >
                <Typography
                  variant="button"
                  fontWeight={"600"}
                  lineHeight={"42px"}
                  color={
                    order?.status === "Completed"
                      ? "#43B02A"
                      : order?.status === "Pending"
                      ? "#0066B3"
                      : "#CFA700"
                  }
                  textTransform={"none"}
                >
                  {t(order?.status)}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {orderList?.length === 0 && (
        <TableBody>
          <TableRow>
            <TableCell colSpan={5}>
              <Box
                height={"calc(100vh - 500px)"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {loading && <Loader />}
                {orderList.length === 0 && !loading && (
                  <Typography variant="h5">
                    {t("No matching results found")}
                  </Typography>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </>
  );
};

export default OrderPoolTableBody;
