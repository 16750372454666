import React from "react";
import { TableHead, TableRow, TableCell, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectTag } from "../../../../components";
import SearchInputTag from "../../../../components/SearchInputTag";
import {
  firstColwidth,
  secondColWidth,
  thirdColwidth,
  fourthColWidth,
  fifthColWidth,
} from "./index";

interface TrolleyStatusTableHead {
  filterStatus: string;
  setFilterStatus: React.Dispatch<React.SetStateAction<string>>;
  onOrderSearch: (searchValue: string) => void;
}

const TrolleyStatusTableHead: React.FC<TrolleyStatusTableHead> = (props) => {
  const { filterStatus, setFilterStatus, onOrderSearch } = props;
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell width={firstColwidth}>{t("Trolley ID")}</TableCell>
        <TableCell width={secondColWidth}>{t("Date & Time")}</TableCell>
        <TableCell width={thirdColwidth}>{t("Assembly area")}</TableCell>
        <TableCell width={fourthColWidth}>
          <Typography variant="h5">{t("Production Order")}</Typography>
          <Box bgcolor={"white"} mt={"7px"}>
            <SearchInputTag onSearch={onOrderSearch} />
          </Box>
        </TableCell>
        <TableCell width={fifthColWidth}>
          <Typography variant="h5">{t("Status")}</Typography>
          <SelectTag
            optionList={[
              "all",
              "filled",
              "assembly",
              "production",
              "emptied",
              "picked",
              "rework",
            ]}
            value={filterStatus}
            onSetValue={setFilterStatus}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TrolleyStatusTableHead;
