import React from "react";
import { TableRow, TableHead, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";

const WarehouseTableHead: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <TableHead>
        <TableRow style={{ position: "sticky", top: "0px", zIndex: 10 }}>
          <TableCell>{t("Warehouse Name")}</TableCell>
          <TableCell>{t("Part Pick Location (Min)")}</TableCell>
          <TableCell>{t("Part Pick location (Max)")}</TableCell>
          <TableCell>{t("ArbPlatz")}</TableCell>
          <TableCell align="center">{t("Action")}</TableCell>
        </TableRow>
      </TableHead>
    </>
  );
};

export default WarehouseTableHead;
