import React, { useEffect, useState } from "react";
import { Navbar, NavigationTabs, PageTitle } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setStateForCreateUser,
  setUserValidations,
} from "../../../store/userListSlice";
import { getUserList } from "../../../api/user.service";
import UserTable from "../../../components/User/UserTable";
import { Box } from "@mui/material";

const AssignRole: React.FC = () => {
  const { userList, userValidations } = useAppSelector(
    (store) => store.userList
  );
  const dispatch = useAppDispatch();
  const createUserAction = (): void => {
    dispatch(setUserValidations({ ErrorCode: "", Message: "" }));
    dispatch(setStateForCreateUser());
  };
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    dispatch(setUserValidations({ ErrorCode: "", Message: "" }));
    dispatch(getUserList()).then(() => {
      setInitialLoading(false);
    });
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <NavigationTabs />
      <PageTitle
        title="Role List"
        showNavigationButton={true}
        navigationUrl="/create-user"
        navigationButtonTitle="Add User Role"
        action={createUserAction}
      />
      <Box width={"95%"} margin={"auto"} marginTop={"36px"}>
        <UserTable
          userList={userList}
          initialLoading={initialLoading}
          showSrNumbers={true}
          showRoles={true}
          userValidations={userValidations}
        />
      </Box>
    </>
  );
};

export default AssignRole;
