import React, { useState, useEffect } from "react";
import { Navbar, PageTitle, SelectTagPopOver } from "../../components";
import ProductionOrderForm from "./ProductionOrderForm";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  getAllWarehouses,
  getProductionOrder,
} from "../../api/kitPickerPicklist.service";
import { useTranslation } from "react-i18next";
import {
  selectWareHouse,
  setNeedRefresh,
} from "../../store/KitPickerPicklist/kitPickerPicklistSlice";
import WarningWindow from "../../components/WarningWindow";
import TableWithControls from "./TableWithControls";
import { extendLockingPeriod } from "../../api/assemblyArea.service";
import { delay, initialSeconds } from "../../config/config";

const KitPickerPicklist: React.FC = () => {
  const {
    order,
    warehouses,
    selectedWarehouse,
    isAllOrderCompleted,
    needRefresh,
    orderErrorMessage,
  } = useSelector((store: RootState) => store.productionOrder);
  const [isFetched, setIsFetched] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [warehouseName, setWarehouseName] = useState("");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(initialSeconds);
  const [nextReqSec, setNextReqSec] = useState(delay);
  const [isActive, setIsActive] = useState(false);
  const [isProcessable, setIsProcessable] = useState(true);
  const [isLeftCountErrorMessage, setIsLeftCountErrorMessage] = useState('');

  const options = warehouses.map((warehouse) => ({
    id: warehouse.id,
    optionName: warehouse.name,
  }));

  const handleSelectChange = (event: SelectChangeEvent): void => {
    const optionId = event.target.value;
    const selectedOption = options.filter(
      (option) => option.id === Number(optionId)
    );
    dispatch(selectWareHouse(selectedOption[0]));
  };

  const handleProceedClick = (): void => {
    setWarehouseName(selectedWarehouse.optionName);
    dispatch(getProductionOrder({ warehouseId: selectedWarehouse.id })).then(
      (res) => {
        res.payload && setShowOrder(true);
      }
    );
    setOpenModal(false);
  };

  const handlePrevScreen = (): void => {
    dispatch(getAllWarehouses());
    setIsFetched(false);
    setShowOrder(false);
    setIsProcessable(true);
    setWarehouseName("");
    setOpenModal(true);
    setIsLeftCountErrorMessage('');
  };

  const handleLeftCountError = (message: string): void => {
    setIsLeftCountErrorMessage(message);
    setIsFetched(false);
  }

  const resetTimer = (): void => {
    if (nextReqSec <= 0) {
      extendLockingPeriod(order.productionOrder);
      setNextReqSec(delay);
    }
    setSeconds(initialSeconds);
  };

  useEffect(() => {
    dispatch(setNeedRefresh(false));    
    dispatch(getAllWarehouses());
  }, [dispatch]);

  useEffect(() => {
    const reqTimer = setTimeout(() => {
      setNextReqSec(nextReqSec - 1);
    }, 1000);
    return () => {
      clearTimeout(reqTimer);
    };
  }, [nextReqSec]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const handleMouseMove = (): void => {
      resetTimer();
    };
    const handleClick = (): void => {
      resetTimer();
    };

    if (isActive && seconds > 0) {
      timer = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("click", handleClick);
    } else if (isActive && seconds === 0) {
      handlePrevScreen();
    }

    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("click", handleClick);
    };
  }, [isActive, seconds, initialSeconds]);

  const startTimer = (): void => {
    setIsActive(true);
  };

  const formatTime = (timeInSeconds: number): string => {
    const min: number = Math.floor(timeInSeconds / 60);
    const sec: number = timeInSeconds % 60;
    return `${String(min).padStart(2, "0")}:${String(sec).padStart(2, "0")}`;
  };
  
  return (
    <>
      <Navbar />
      <SelectTagPopOver
        open={openModal}
        currentValue={selectedWarehouse.id}
        heading={t("Select Warehouse")}
        inputLable={t("Warehouse Name")}
        onSelectChange={handleSelectChange}
        onProceedButton={handleProceedClick}
        optionList={options}
      />
      <PageTitle
        title={`${t("Kit Picker Picklist")} ${
          warehouseName && "- " + warehouseName
        }`}
      />
      {showOrder && (
        <>
          <ProductionOrderForm
            isFetched={isFetched}
            setIsFetched={setIsFetched}
            inactiveTime={formatTime(seconds)}
            startTimer={startTimer}
            setIsProcessable={setIsProcessable}
          />
          {isAllOrderCompleted && orderErrorMessage === null && (
            <WarningWindow
              message={t("All orders are complete for today")}
              buttonName="Back"
              onReload={handlePrevScreen}
            />
          )}
          {isAllOrderCompleted && orderErrorMessage !== null && (
            <WarningWindow
              message={t("Trolley quantity not defined for this Warehouse.")}
              buttonName="Back"
              onReload={handlePrevScreen}
            />
          )}
          {needRefresh && !isAllOrderCompleted && (
            <WarningWindow
              message={t(
                "No trolley available to fulfill this order, please try again after some time"
              )}
              buttonName="Back"
              onReload={handlePrevScreen}
            />
          )}
          {!isProcessable && (
            <WarningWindow
              message={t(
                "This order has already been picked by another kitpicker."
              )}
              buttonName="Back"
              onReload={handlePrevScreen}
            />
          )}
          {isLeftCountErrorMessage && (
            <WarningWindow
              message={t(isLeftCountErrorMessage)}
              buttonName="Back"
              onReload={handlePrevScreen}
            />
          )}
        </>
      )}
      <TableWithControls
        isFetched={isFetched}
        setIsFetched={setIsFetched}
        resetTimer={resetTimer}
        setIsActive={setIsActive}
        handleLeftCountError={handleLeftCountError}
      />
    </>
  );
};

export default KitPickerPicklist;
